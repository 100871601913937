import React from "react"
import { sendBlockTrace } from '@/src/utils/sendBlockTrace';
import { BlockTypeValue, InterruptTypeValue, MainTypeValue, SubTypeValue } from '@/src/utils/types';
import PopDialog from '../../common/PopDialog'
import { UED } from "../../popups/styleConst"
import FLIGHT_TRAIN_MESSAGE from '../../../../constants/list/enums/flightTrainMessage'
/**
 * 空铁组合弹窗
 *
 */

const FlightTrainOrderPopup = ({ ...props }) => {
    const { isShow, back, continueOrder } = props
    if (isShow) {
        sendBlockTrace({
            blockType: BlockTypeValue.Pop,
            interruptType: InterruptTypeValue.Block,
            mainType:  MainTypeValue.Search,
            subType: SubTypeValue.TrainAndFlight,
        });
    }
    return (
        !isShow ? null : (
            <PopDialog contents={
                <div className="basic-alert basic-alert-with-icon alert-train-travel" style={UED.popupMargin} >
                    <i className="ico-warning-max" />
                    <div className="alert-title">{FLIGHT_TRAIN_MESSAGE.ALERT_TITLE}</div>
                    <div className="alert-body">
                        {FLIGHT_TRAIN_MESSAGE.ALERT_CONTENT.map((item, index) => <div className="alert-li" key={index}>{index + 1}.{item}</div>)}
                        <div className="train-tips">
                            <strong>{FLIGHT_TRAIN_MESSAGE.TRAIN_TIP_TITLE}</strong>
                            <p>{FLIGHT_TRAIN_MESSAGE.TRAIN_TIP_CONTENT}</p>
                        </div>
                    </div>
                    <div className="alert-footer">
                        <div className="btn-group">
                            <a href="javascript:;" className="btn" style={UED.back} onClick={back}>重新选择航班</a>
                            <a href="javascript:;" className="btn" style={UED.order} onClick={continueOrder}>我已阅读并理解预订需知</a>
                        </div>
                    </div>
                </div>}
            />
        )
    )
}

export default FlightTrainOrderPopup
