import React, { useMemo } from 'react';
import classnames from 'classnames';

import ErrorBoundary from '@/src/components/Base/ErrorBoundary';
import NoticeContainer from '@/src/containers/list/top/notice/NoticeContainer';
import CalenderLowPrice from '@/src/containers/list/top/calendarLowprice/calendarLowprice';
import { getIEVersion } from '@/src/sources/common/getIEVersion';

import SearchBoxContainer from '../../../containers/list/top/searchBoxContainer/async';

import ProgressV2 from './Progress';

const Top = ({
	offline,
	searchFlightsIsFinished,
	searchProgress,
	fixLowPriceCalendar,
	fixSortBar,
	flightWay,
	routerKey,
	globalSwitch,
	useLowZIndex,
	splitRound,
}) => {
	const ieVersion = useMemo(() => getIEVersion(), []);
	// 此处不限是否往返组合，即非往返组合也是把低价日历显示在搜索框区域
	const rawRoundTabModeSwitch = flightWay === 'RT' && globalSwitch.get('roundTabModeSwitch');

	const calendarEl = (
		<ErrorBoundary>
			<CalenderLowPrice filterV2={true} splitRound={splitRound} />
		</ErrorBoundary>
	);

	return (
		<React.Fragment>
			<div
				className={classnames('header-wrapper header-wrapper-v2', {
					'header-wrapper-multi-v2': flightWay === 'MT',
					'zero-index': useLowZIndex,
				})}
			>
				<ErrorBoundary>
					<NoticeContainer />
				</ErrorBoundary>
				{offline ? null : (
					<ErrorBoundary>
						<SearchBoxContainer />
					</ErrorBoundary>
				)}
				{rawRoundTabModeSwitch ? calendarEl : null}
				<ErrorBoundary>
					{!ieVersion || ieVersion >= 10 ? (
						<div className="progress-container">
							{/* 用两条进度条来控制，长度不一致的情况 */}
							<ProgressV2
								routerKey={routerKey}
								finished={searchFlightsIsFinished}
								percent={searchProgress}
								fixLowPriceCalendar={fixLowPriceCalendar}
								fixSortBar={fixSortBar}
								type="normal"
								className="thin"
							/>
							<ProgressV2
								routerKey={routerKey}
								finished={searchFlightsIsFinished}
								percent={searchProgress}
								fixLowPriceCalendar={fixLowPriceCalendar}
								fixSortBar={fixSortBar}
								type="fix"
								className="thin"
							/>
						</div>
					) : null}
				</ErrorBoundary>
			</div>
			{!rawRoundTabModeSwitch ? calendarEl : null}
		</React.Fragment>
	);
};

export default Top;
