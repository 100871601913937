import React from 'react'
import classnames from 'classnames'
import PopDialog from '../common/PopDialog'
import { UED } from './styleConst'
import { sendBlockTrace } from '@/src/utils/sendBlockTrace';
import { BlockTypeValue, InterruptTypeValue, MainTypeValue, SubTypeValue } from '@/src/utils/types';
import POPUP_MSG from './popupMsg'
import { getIfNeedExTipOfTransitPU } from '../../../sources/common/getIfNeedExTipOfTransitPU'

export class TransitPUConfirmPopup extends React.Component {
    constructor(props) {
        super(props)

        this.onCancel = this.onCancel.bind(this)
        this.onConfirm = this.onConfirm.bind(this)
        this.onHide = this.onHide.bind(this)
        this.getDetailStyle = this.getDetailStyle.bind(this)

        this.state = {
            activeIndex: 0
        }
    }

    setActiveIndex(index) {
        this.setState({
            activeIndex: index
        })
    }

    onHide() {
        this.props.onHide()
    }

    onCancel() {
        this.onHide()
    }

    onConfirm() {
        this.onHide()
        this.props.onConfirm.apply(null, this.props.pendingBookParameter)
    }

    getDetailStyle(index) {
        return this.state.activeIndex === index ? { display: 'block' } : { display: 'none' }
    }

    render() {
        const distinctCityNameList = [],
            cityList = this.props.cityList || [],
            needExtTransitTip = cityList.some(city => getIfNeedExTipOfTransitPU(city))

        cityList.forEach(city => {
            const cityName = city.get('cityName')
            if (distinctCityNameList.includes(cityName) === false) {
                distinctCityNameList.push(cityName)
            }
        })

        if (this.props.show) {
            sendBlockTrace({
                blockType: BlockTypeValue.Pop,
                interruptType: InterruptTypeValue.Block,
                mainType:  MainTypeValue.Search,
                subType: SubTypeValue.SelfTransfer,
            });
        }
        return this.props.show ? (
            <PopDialog contents={(
                <div className='basic-alert alert-ticketgroup' style={UED.popupMargin}>
                    <div className='alert-header'>
                        <div className='title' dangerouslySetInnerHTML={{ __html: POPUP_MSG.TRANSITPU_CONFIRM.MAIN_TITLE }}></div>
                        <div className='sub-title highlight' dangerouslySetInnerHTML={{ __html: POPUP_MSG.TRANSITPU_CONFIRM.SUB_TITLE }}></div>
                    </div>
                    <div className='alert-body'>
                        <div className='attentions'>
                            <div className='attention-captions'>
                                <div className={classnames({ caption: true, active: this.state.activeIndex === 0 })} onClick={this.setActiveIndex.bind(this, 0)}>
                                    <i className='ico-revisa'></i>
                                    {POPUP_MSG.TRANSITPU_CONFIRM.VISA_CAPTION}
                                </div>
                                <div className={classnames({ caption: true, active: this.state.activeIndex === 1 })} onClick={this.setActiveIndex.bind(this, 1)}>
                                    <i className='ico-rebook'></i>
                                    {POPUP_MSG.TRANSITPU_CONFIRM.BOARDING_CAPTION}
                                </div>
                                <div className={classnames({ caption: true, active: this.state.activeIndex === 2 })} onClick={this.setActiveIndex.bind(this, 2)}>
                                    <i className='ico-reluggage'></i>
                                    {POPUP_MSG.TRANSITPU_CONFIRM.LUGGAGE_CAPTION}
                                </div>
                            </div>
                            <div className='attention-cont' style={this.getDetailStyle(0)} >
                                <div className='tit' dangerouslySetInnerHTML={{ __html: POPUP_MSG.TRANSITPU_CONFIRM.VISA_HEADER }}></div>
                                <div className='desc' dangerouslySetInnerHTML={{ __html: POPUP_MSG.TRANSITPU_CONFIRM.VISA_CONTENT.replace(/\[CITY_LIST\]/, distinctCityNameList.join('、')) + (needExtTransitTip ? POP_UP_MSG.TRANSITPU_CONFIRM.TW_VISA_TIP : '') }} ></div>
                            </div>
                            <div className='attention-cont' style={this.getDetailStyle(1)} >
                                <div className='tit' dangerouslySetInnerHTML={{ __html: POPUP_MSG.TRANSITPU_CONFIRM.BOARDING_HEADER }}></div>
                                <div className='desc' dangerouslySetInnerHTML={{ __html: POPUP_MSG.TRANSITPU_CONFIRM.BORADING_CONTENT.replace(/\[CITY_LIST\]/, distinctCityNameList.join('、')) }}></div>
                            </div>
                            <div className='attention-cont' style={this.getDetailStyle(2)} >
                                <div className='tit' dangerouslySetInnerHTML={{ __html: POPUP_MSG.TRANSITPU_CONFIRM.LUGGAGE_HEADER }}></div>
                                <div className='desc' dangerouslySetInnerHTML={{ __html: POPUP_MSG.TRANSITPU_CONFIRM.LUGGAGE_CONTENT.replace(/\[CITY_LIST\]/, distinctCityNameList.join('、')) }}></div>
                            </div>
                        </div>
                    </div>
                    <div className='alert-footer'>
                        <div className='btn-group'>
                            <a href='javascript:;' className='btn btn-ghost' onClick={this.onCancel}>取消</a>
                            <a href='javascript:;' className='btn' onClick={this.onConfirm}>我已知晓，继续预订</a>
                        </div>
                    </div>
                </div>)
            } />) : null
    }
}
