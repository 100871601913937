
// 获取鼠标相对于触发元素的相对位置
export const getMousePositionByTriggerElement = (e) => {
  // 触发元素的位置

  let { top, left, width, height } = e && e.target && e.target.getBoundingClientRect()
  // 鼠标位置
  let { clientX, clientY } = e
  // 鼠标相对于触发元素的位置
  let position = { x: clientX - left, y: clientY - top }
  let isCenter = Math.abs(width / 2 - position.x) <= 2 && Math.abs(height / 2 - position.y) <= 2
  return {
    position,
    isCenter
  }
}
