// const rootUrl = document.getElementById('static.resource.url').value;

import React from 'react';
import { Provider } from 'react-redux';
import qs from 'qs';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import store from '@/src/stores/list';

import ListHome from '@/src/components/list';
import { getConfigsFromGlobal, getPrevCondFromGlobal, getSwitchesFromGlobal } from '@/src/actions/common';
import { genInitPrevSearchCondSuccess, genInitGlobalSwitch, genInitGlobalConfig } from '@/src/actions/list';

import searchDataHelper from '@/src/constants/common/searchDataHelper';
import { GLOBAL_PREFIX } from '@/src/constants/common';
import { performanceTrace } from '@/src/utils/performanceTrace';
// 搜索提前
import '@/src/sources/list/quickSearchFlights';

// eslint-disable-next-line global-require
require('intersection-observer');

// 开发模式引入mock
// if (process.env.NODE_ENV === 'development') {
// 	// eslint-disable-next-line global-require
// 	require('@/src/mock');
// }

if (!window.GlobalSwitches?.newChannel) {
	searchDataHelper.validateDateAndJumpOneway();
}

const setSearchCookie = (prevCond) => {
	const flightWay = prevCond.flightWay,
		resolveSegment = (segmentIndex, extStr) => {
			const {
				departureCityCode,
				departureCityName,
				departureCityId,
				arrivalCityCode,
				arrivalCityName,
				arrivalCityId,
				departureCityTimeZone,
				arrivalCityTimeZone,
				departureDate,
				departureAirportCode,
				departureAirportName,
				arrivalAirportCode,
				arrivalAirportName,
			} = prevCond.flightSegments[segmentIndex];
			let departStr = '',
				arrivalStr = '';
			if (departureAirportCode && departureAirportName) {
				// Shanghai|上海(浦东国际机场)(PVG)|2|SHA|PVG|0
				// SHA|上海(浦东国际机场)(PVG)|2|SHA|PVG|480
				departStr = `"${departureCityCode}|${departureCityName}(${departureAirportName})(${departureAirportCode})|${departureCityId}|${departureCityCode}|${departureAirportCode}|${departureCityTimeZone}"`;
			} else {
				departStr = `"${departureCityCode}|${departureCityName}(${departureCityCode})|${departureCityId}|${departureCityCode}|${departureCityTimeZone}"`;
			}
			if (arrivalAirportCode && arrivalAirportName) {
				arrivalStr = `"${arrivalCityCode}|${arrivalCityName}(${arrivalAirportName})(${arrivalAirportCode})|${arrivalCityId}|${arrivalCityCode}|${arrivalAirportCode}|${arrivalCityTimeZone}"`;
			} else {
				arrivalStr = `"${arrivalCityCode}|${arrivalCityName}(${arrivalCityCode})|${arrivalCityId}|${arrivalCityCode}|${arrivalCityTimeZone}"`;
			}
			return `${departStr},${arrivalStr},"${departureDate}"${extStr}`;
		},
		cookieOption = {
			path: '/',
			expires: 365,
			domain: location.host.split('.').slice(-2).join('.'),
		};

	if (flightWay === 'S' || flightWay === 'D') {
		const arriveDate = flightWay === 'D' ? prevCond.flightSegments[1].departureDate : '',
			extStr = flightWay === 'D' ? `,"${arriveDate}"` : '',
			finalCookieValue = resolveSegment(0, extStr);

		// Search=["SHA|上海(SHA)|2|SHA|480","NYC|纽约(NYC)|633|NYC|480","2018-05-24"]
		Cookies.remove('FlightIntl', { path: '/' });
		Cookies.set('FlightIntl', `Search=[${finalCookieValue}]`, cookieOption);
	} else {
		// Search=["Shanghai|上海(SHA)|2|SHA|480","New York|纽约(NYC)|633|NYC|-240","2018-05-24","",[["New York|纽约(NYC)|633|NYC|-240","Hong Kong|香港(HKG)|58|HKG|480","2018-05-31"],["Hong Kong|香港(HKG)|58|HKG|480","Manila|马尼拉(MNL)|364|MNL|480","2018-06-05"]]]
		const seg1CookieValue = resolveSegment(0, `,""`),
			segmentCount = prevCond.flightSegments.length;
		const otherCookieValue: string[] = [];

		for (let i = 1; i < segmentCount; i++) {
			otherCookieValue.push(`[${resolveSegment(i, '')}]`);
		}

		Cookies.remove('FlightIntl', { path: '/' });
		Cookies.set('FlightIntl', `Search=[${seg1CookieValue},[${otherCookieValue.join(',')}]]`, cookieOption);
	}
};

(() => {
	// 应该只有此处读取此全局变量
	const prevCond = getPrevCondFromGlobal(),
		globalSwitches = getSwitchesFromGlobal(),
		globalConfig = getConfigsFromGlobal();
	// eslint-disable-next-line no-undef
	const search = location.search && location.search[0] === '?' ? location.search.substring(1) : location.search;
	const query = qs.parse(search);
	const dispatch = store.dispatch;

	if (globalSwitches) {
		dispatch(genInitGlobalSwitch(globalSwitches, prevCond));
	}

	if (globalConfig) {
		dispatch(genInitGlobalConfig(globalConfig, prevCond));
	}

	if (prevCond) {
		dispatch(genInitPrevSearchCondSuccess(prevCond, query, {}));
	}

	setSearchCookie(prevCond);
})();

type ListContainerProps = {
	offlineInfo: {
		validUser: boolean;
	};
};

export default function ListContainer({ offlineInfo }: ListContainerProps) {
	performanceTrace.trace('afterPageMount');

	return (
		<Provider store={store}>
			<Router basename={`${GLOBAL_PREFIX}/list`}>
				<Route component={ListHome} offlineInfo={offlineInfo} />
			</Router>
		</Provider>
	);
}
