import React, { useContext } from 'react';
import classnames from 'classnames';
import { DeferredContent } from 'primereact/deferredcontent';

import AirlineLogo from '@/src/components/Logo/AirlineLogo';
import VirtualLogo from '@/src/components/Logo/VirtualLogo';
import ReactToolTip from '@/src/components/Base/reactToolTip';
import Converter from '@/src/sources/list/converter';
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '@/src/ubt.v2/list';
import { getFlightTransAndStopCount, getDomesticFlightStopAndTongchengText } from '@/src/sources/list/getFragment';
import { ITINERARY_TAG_POSITION } from '@/src/constants/list/enums/itineraryTagPosition';
import { VIRTUAL_FLIGHT_TAG } from '@/src/constants/list/enums/virtualFlightInfo';
import { ScopeInternationalContext } from '@/src/sources/list/scopeInternationalContext';
import { getIfScopeDomestic } from '@/src/sources/list/scope';
import AsyncFlightComfortContainer from '@/src/containers/list/result/flight/ComfortContainer/async';
import PopupDomesticFlightDetail from '@/src/containers/list/result/PopupFlightDetailContainerV2/async';

import { VisaFragment, VisaTag, BatchGetVisas, FlightDetailFragment } from '../../common/visaFragment';
import Tooltip from '../../common/tooltip';
import { getOptmizedTag } from '../price/price';
import AsyncFlightHistoryHistoryPunctualityPopup from '../../popups/HistoryPunctualityPopup1/async';
import TooltipList from './TooltipList';

const domesticFlightTagMaxCount = window.GlobalConfigs.domesticFlightTagMaxCount || 2;
// 【未展开运价时的外露价格】不展示优惠标签的航司列表
const noDiscountAirlineList = window.GlobalConfigs.noDiscountAirlineList || [];

const scopDomestic = getIfScopeDomestic();

type FlightRowProps = {
	segment;
	priceList: any;
	itineraryTagEnums;
	cleanItineraryId;
	currentSegmentSeq;
	isExpand;
	changeComfortPopupIndex;
	isBuildUpMode;
	rowIndex;
	onPopupFlightDetail;
	prevCond;
	sortContainsTax;
	tongChengFlightEnum;
	showFlightCouponTag;
	comfortPopupIndex;
	showTongChengRemark;
	splitRound;
	onDetailFooterBtnClick;
	selected;
	buildUpSegmentList;
	theMinPriceOfAllFlights;
	flight;
};

const FlightRow = ({
	segment,
	priceList,
	itineraryTagEnums,
	cleanItineraryId,
	currentSegmentSeq,
	isExpand,
	changeComfortPopupIndex,
	isBuildUpMode,
	rowIndex,
	onPopupFlightDetail,
	prevCond,
	sortContainsTax,
	tongChengFlightEnum,
	showFlightCouponTag,
	comfortPopupIndex,
	showTongChengRemark,
	splitRound,
	onDetailFooterBtnClick,
	selected,
	buildUpSegmentList,
	theMinPriceOfAllFlights,
	flight,
}: FlightRowProps) => {
	const departureAirportEnName = segment.get('departureAirportEnName'),
		arrivalAirportEnName = segment.get('arrivalAirportEnName'),
		departureAirportName = segment.get('departureAirportName'),
		arrivalAirportName = segment.get('arrivalAirportName'),
		isVirtualFlight = segment.get('containsVirtualFlight'),
		isTFUArrivalAirline = segment.get('arrivalAirportCode') === 'TFU',
		isTFUDepartureAirline = segment.get('departureAirportCode') === 'TFU',
		newAirportTFUSwitch = window.GlobalSwitches.newAirportTFUSwitch;
	// 虚拟航班类型
	let trafficType = '';
	// 虚拟航班乘船/火车/汽车提醒
	let travelRemark = '';
	if (isVirtualFlight) {
		const virtualFlight = segment.get('flightList').find((item) => item.get('virtualFlight'));
		trafficType = virtualFlight.get('trafficType');
		travelRemark = virtualFlight.get('travelRemark');
	}

	const newAirportPKXSwitch = window.GlobalSwitches.newAirportPKXSwitch,
		currentSegmentSeqNo = isBuildUpMode ? rowIndex : currentSegmentSeq,
		flightWayEnum = prevCond.get('flightWayEnum'),
		flightWayEnumKey = flightWayEnum.key,
		flightWayEnumValue = flightWayEnum.value;

	const visaFgragmentProps = {
		tongChengFlightEnum,
		segment,
		priceList,
		cleanItineraryId,
		currentSegmentSeq: currentSegmentSeqNo,
		onPopupFlightDetail,
		flightWay: flightWayEnumKey,
		showTongChengRemark,
		splitRound,
		onFooterBtnClick: onDetailFooterBtnClick,
	};
	const visaFragment = new VisaFragment(visaFgragmentProps);

	const selectedFlights = selected && selected.get('flights');
	let flightInfos: any[] = [];

	// 处理往返组合数据
	if (buildUpSegmentList && buildUpSegmentList.length) {
		const segObj = {
			tongChengFlightEnum,
			priceList,
			cleanItineraryId,
			currentSegmentSeq: 0,
			onPopupFlightDetail,
			flightWay: flightWayEnumKey,
			showTongChengRemark,
			splitRound,
			onFooterBtnClick: onDetailFooterBtnClick,
		};
		flightInfos = [
			{ ...segObj, segment: buildUpSegmentList[0] },
			{ ...segObj, segment: buildUpSegmentList[1] },
		];
	} else {
		// 已选的排在最前面
		if (selectedFlights) {
			const priceList = selectedFlights.get('priceList'),
				cleanItineraryId = selectedFlights.get('itineraryId').replace(/[,\|]/gi, ''),
				flightSegments = selectedFlights.get('flightSegments'),
				tongChengFlightEnum = selectedFlights.get('tongChengFlightEnum');
			flightSegments.forEach((currentSegment, index) => {
				if (index < currentSegmentSeq) {
					flightInfos.push({
						tongChengFlightEnum,
						segment: currentSegment,
						currentSegmentSeq: index,
						cleanItineraryId,
						priceList,
						showTongChengRemark,
					});
				}
			});
		}
		flightInfos.push(visaFgragmentProps);
	}

	let isLastSegment = segment.get('isLastSegment'),
		isMultiplePassengerType = prevCond.get('isMultiplePassengerType'),
		transferCount = segment.get('transferCount'),
		transferAndStopCount = segment.get('transferAndStopCount'),
		theMinPriceItem = segment.get('theMinPriceItem'),
		tagEnumsOfMiddle = itineraryTagEnums.filter(
			(wrapper) => wrapper.tag.value.position === ITINERARY_TAG_POSITION.MIDDLE,
		),
		tagEnumsOfLeft = itineraryTagEnums.filter(
			(wrapper) => wrapper.tag.value.position === ITINERARY_TAG_POSITION.LEFT,
		),
		onlyHiddenTagEnumsOfBestChoiceAtLeft = false, // 精选航班需要隐藏标签，但又要上报埋点，此时 more-padding 不需要加到类名上面
		getIndex = (wrapper) => `${wrapper.tag.key}-${cleanItineraryId}`,
		getItineraryTagTooltip = (wrapper) => (
			<ReactToolTip
				key={getIndex(wrapper)}
				parentId={getIndex(wrapper)}
				toolTipContent={wrapper.tag.value.getTooltip(getIndex(wrapper), wrapper)}
			/>
		),
		getItineraryTags = () => {
			let tagRenderOfMiddle = null,
				tagRenderOfLeft = null;

			if (tagEnumsOfMiddle.size > 0) {
				tagRenderOfMiddle = tagEnumsOfMiddle
					.map((wrapper) => wrapper.tag.value.getTag(wrapper, getIndex(wrapper)))
					.push(tagEnumsOfMiddle.map((wrapper) => getItineraryTagTooltip(wrapper)));
			}

			// 过滤出同一个group中低优先级的tag
			tagEnumsOfLeft = tagEnumsOfLeft.filter(
				(tagEnum) => tagEnum.tag.key !== 'FlightGeneralCoupon' || showFlightCouponTag,
			); // 航班通用优惠券只在单程、往返自由搭配返程、往返组合显示

			// 找出group为空，或者同group中不存在更小seq的tag(即找出每个group最小seq的那个tag)
			tagEnumsOfLeft = tagEnumsOfLeft
				.filter(
					(tagEnum) =>
						!tagEnum.tag.value.group ||
						!tagEnumsOfLeft.some(
							(innerTagEnum) =>
								innerTagEnum.tag.key !== tagEnum.tag.key &&
								innerTagEnum.tag.value.group === tagEnum.tag.value.group &&
								innerTagEnum.tag.value.seq < tagEnum.tag.value.seq,
						),
				)
				.sort((prev, next) => prev.tag.value.seq - next.tag.value.seq);

			if (tagEnumsOfLeft.size > 0) {
				tagRenderOfLeft = (
					<div className="tab-box">
						{tagEnumsOfLeft
							.map((wrapper) => wrapper.tag.value.getTag(wrapper, getIndex(wrapper)))
							.push(tagEnumsOfLeft.map((wrapper) => getItineraryTagTooltip(wrapper)))}
					</div>
				);

				onlyHiddenTagEnumsOfBestChoiceAtLeft = tagEnumsOfLeft.every(
					(theWrapper) => theWrapper.tag.value.getIfHideTag && theWrapper.tag.value.getIfHideTag(theWrapper),
				);
			}

			return {
				[ITINERARY_TAG_POSITION.LEFT]: tagRenderOfLeft,
				[ITINERARY_TAG_POSITION.MIDDLE]: tagRenderOfMiddle,
			};
		},
		itineraryTagsRenderResult = getItineraryTags(),
		isAllTrains = segment.get('isAllTrains'),
		firstFlightOperateAirlineName = segment.getIn(['flightList', 0, 'operateAirlineName']),
		firstFlightMarketAirlineName = segment.getIn(['flightList', 0, 'marketAirlineName']),
		transAirLineName = isAllTrains ? firstFlightOperateAirlineName || firstFlightMarketAirlineName : null,
		airLineClsName = 'name',
		TKUArriveairLineClsName = isTFUArrivalAirline && newAirportTFUSwitch ? 'new-airport' : '',
		TKUDepartAirlineClsName = isTFUDepartureAirline && newAirportTFUSwitch ? 'new-airport' : '',
		productTypeWrapperOfMinPrice = theMinPriceItem.get('productTypeEnum'),
		flightRowLabelIdOfMinPrice = `row_label_${cleanItineraryId}`,
		hideFlightRowLabel = isExpand || isBuildUpMode || isLastSegment, // 展开了运价就不在航班级别显示运价的产品类型
		flightRowLabelOfMinPrice = hideFlightRowLabel
			? null
			: productTypeWrapperOfMinPrice.tag.value.getFlightRowLabel(
					productTypeWrapperOfMinPrice.tag,
					flightRowLabelIdOfMinPrice,
				),
		priceOfTaxTip =
			(isLastSegment || isBuildUpMode) && sortContainsTax
				? flightWayEnumValue.getPriceText(isMultiplePassengerType, true)
				: null,
		totalTransferDuration = segment.get('totalTransferDuration'),
		batchGetVisasAndShowFlightDetail = () => {
			BatchGetVisas({
				flightDetailInfos: flightInfos,
				onPopupFlightDetail,
				currentSegmentSeq: currentSegmentSeqNo,
				cleanItineraryId,
			});
		},
		scopeInternational = useContext(ScopeInternationalContext),
		getFlightDetail = () => {
			return scopeInternational
				? FlightDetailFragment({
						cleanItineraryId,
						flightWay: flightWayEnumKey,
						currentSegmentSeq: currentSegmentSeqNo,
						onFlightDetailClick: () => {
							batchGetVisasAndShowFlightDetail();
						},
					})
				: null;
		},
		onTrsnferMouseOver = () => {
			// if (!scopeInternational && totalTransferDuration) {
			//     batchGetVisasAndShowFlightDetail()
			// }
		},
		flightDuration = () => {
			return scopeInternational ? (
				<div className="flight-consume" id={`${'durationDayHourMin' + cleanItineraryId}`}>
					{segment.get('durationDayHourMin')}
				</div>
			) : null;
		},
		totalTransferDurationFragment = () => {
			return !scopeInternational && totalTransferDuration && !tongChengFlightEnum ? (
				<span className="transfer-duration">{`${
					transferCount > 1 ? '中转总时长' : '中转'
				}${totalTransferDuration}`}</span>
			) : null;
		},
		flightArrivalPunctuality = () => {
			return scopeInternational ? null : (
				<div className="flight-arrival-punctuality-list">
					{segment.get('flightList').map((flight, index) => {
						const flightNo = flight.get('flightNo'),
							parentId = `flightArrivalPunctuality_${cleanItineraryId}_${flightNo}`,
							arrivalDateTime = flight.get('arrivalDateTime'),
							trafficType = flight.get('trafficType'),
							arrivalPunctuality = flight.get('arrivalPunctuality'),
							showPunctualityPopup = arrivalPunctuality !== '-';

						if (trafficType === 'FLIGHT' && showPunctualityPopup) {
							return (
								<React.Fragment key={index}>
									<div id={parentId} className="flight-arrival-punctuality">
										到达准点率{arrivalPunctuality}
									</div>
									{showPunctualityPopup && (
										<AsyncFlightHistoryHistoryPunctualityPopup
											flightNo={flightNo}
											parentId={parentId}
											arrivalPunctuality={flight.get('arrivalPunctuality')}
											arrivalDateTime={arrivalDateTime}
										/>
									)}
								</React.Fragment>
							);
						} else {
							return null;
						}
					})}
				</div>
			);
		},
		getPriceOfTaxTip = () => {
			return scopeInternational
				? priceOfTaxTip && isExpand && (!isBuildUpMode || rowIndex === 1) && (
						<div className="flight-detail-toggle taxation-tip-msg">{priceOfTaxTip}</div>
					)
				: null;
		};

	const hasMainSegement = segment.get('flightList').some((item) => !!item.get('mainSegment'));
	// let hasMainSegement = false
	// 用来判断显示不显示多个航班信息，国际有主航段mainSegment返回只渲染一个航班，国内多个航班信息渲染全量
	const renderMultiVisibleSegment = !hasMainSegement && segment.get('flightList').size > 1;
	const isTonchengSegement = !!tongChengFlightEnum;

	const renderSingelVisibleAirline = () => {
		const airlineCode = segment.get('subAirlineCode') || segment.get('airlineCode');
		const airlineName = segment.get('subAirlineName') || segment.get('airlineName');
		return (
			<div className="flight-airline">
				<AirlineLogo className="airline-logo" airlineCode={airlineCode} airlineName={airlineName} size="48" />
				<div className="airline-name">
					<span
						id={`airlineName${cleanItineraryId}`}
						className={classnames({ 'mul-airline': segment.get('airlineCount') > 1 && !isVirtualFlight })}
					>
						{segment.get('subAirlineName') || segment.get('airlineName')}
					</span>

					{isVirtualFlight && (
						<span id={`virtualflight${cleanItineraryId}`} className={'virtual-flight'}>
							<VirtualLogo className="virtual-flight-logo" trafficType={trafficType} size="48" />
							{/* 三种类型 */}
							<span className="virtual-flight-tag">{VIRTUAL_FLIGHT_TAG[trafficType]?.value}</span>
						</span>
					)}

					{flightRowLabelOfMinPrice ? (
						<div className="fltcard-tags" id={flightRowLabelIdOfMinPrice}>
							{flightRowLabelOfMinPrice}
						</div>
					) : null}
				</div>

				<UbtBoundary
					tracker={ListUbtGenerator(
						LIST_UBT_GROUP_TYPES.UPLOAD_NOW_MOUSE_ENTER,
						LIST_UBT_KEY_TYPES.FLIGHT_COMFORT_HOVER,
					)}
				>
					<div className={'plane'} id={`comfort-${cleanItineraryId}`}>
						{segment.get('airlineCount') === 1 ? (
							segment
								.get('flightList')
								.map((flight, index) => (
									<AsyncFlightComfortContainer
										key={flight.get('flightNo')}
										tongChengFlightEnum={tongChengFlightEnum}
										onMouseOver={() => changeComfortPopupIndex(index)}
										flight={flight}
									/>
								))
						) : (
							<div className={classnames({ plane: true })}>
								<span className="plane-multi">{segment.get('airlineCount')}个航司</span>
								<span className="plane-multi">{segment.get('flightNoCount')}程航班</span>
							</div>
						)}
					</div>
				</UbtBoundary>
			</div>
		);
	};
	const renderMultiVisibleAirline = () => {
		return (
			<div className="flight-airline" id={`comfort-${cleanItineraryId}`}>
				{segment.get('flightList').map((flight, index) => {
					const trafficType = flight.get('trafficType');
					const airlineCode = flight.get('subMarketAirlineCode') || flight.get('marketAirlineCode');
					const airlineName = flight.get('subMarketAirlineName') || flight.get('marketAirlineName');
					return (
						<div key={index} className="airline-item">
							<AirlineLogo
								className="airline-logo"
								airlineCode={airlineCode}
								airlineName={airlineName}
								size="48"
							/>

							<div className="airline-name">
								{trafficType == 'TRAIN' ? flight.get('trainTypeName') : ''}
								{flight.get('subMarketAirlineName') || flight.get('marketAirlineName')}
								{
									<AsyncFlightComfortContainer
										key={flight.get('flightNo')}
										tongChengFlightEnum={tongChengFlightEnum}
										onMouseOver={() => changeComfortPopupIndex(index)}
										flight={flight}
									/>
								}
							</div>
						</div>
					);
				})}
			</div>
		);
	};
	const renderTongchengAirline = () => {
		const segments = tongChengFlightEnum.data.segments;
		const isTongCheng = tongChengFlightEnum.tag?.value?.isTongCheng;

		return segments.map((segment, index) => {
			const { flightList, airlineName, airlineCode, flightNo } = segment;
			// 通程分中转的通程和经停通程，由于经停通程数据缺失不能请求到舒适度，就不展示舒适度了
			// 国内旧版所有的通程都没展示舒适度
			const isTransferTongcheng = transferCount > 0;

			return (
				<div className="flight-airline" key={index}>
					<AirlineLogo
						className="airline-logo"
						isTongCheng={isTongCheng}
						airlineCode={airlineCode}
						airlineName={airlineName}
						size="48"
					/>
					<div className="airline-name">
						<span className={classnames({ 'mul-airline': true })}>
							{airlineName || (isTransferTongcheng && '通程航班')} {flightNo}
						</span>
					</div>

					<UbtBoundary
						tracker={ListUbtGenerator(
							LIST_UBT_GROUP_TYPES.UPLOAD_NOW_MOUSE_ENTER,
							LIST_UBT_KEY_TYPES.FLIGHT_COMFORT_HOVER,
						)}
					>
						<div className={'plane'} id={`${isTransferTongcheng ? `comfort-${cleanItineraryId}` : ``}`}>
							{flightList.map((flight, index) => {
								const { airlineName, flightNo } = flight;
								return (
									<span
										key={index}
										className={`plane-No ${isTransferTongcheng ? 'enable-hover' : ''}`}
									>
										{airlineName} {flightNo}
									</span>
								);
							})}
						</div>
					</UbtBoundary>
				</div>
			);
		});
	};
	const getFlightPriceTagId = (group, seq) => `flightPriceTag_${cleanItineraryId}_${group}_${seq}`,
		getPriceTagTooltip = (wrapper, id) => {
			return (
				<ReactToolTip
					splitRound={splitRound}
					parentId={id}
					key={id}
					toolTipContent={wrapper.tag.value.getTooltip(wrapper)}
				/>
			);
		},
		getPriceTagLabel = ({ label, id, labelClassName }) => {
			if (!label) {
				return null;
			}
			if (typeof label === 'string') {
				return (
					<span
						id={id}
						key={id + '_tag'}
						className={'tag ' + labelClassName}
						dangerouslySetInnerHTML={{ __html: label }}
					></span>
				);
			} else {
				return (
					<span id={id} key={id + '_tag'} className="tag">
						{label}
					</span>
				);
			}
		};

	const domesticFlightStopText = getDomesticFlightStopAndTongchengText({
		transferCount,
		transferAndStopCount,
		tongChengFlightEnum,
	});
	const stopTextList = segment.get('stopTextList');
	const flightPriceTags = [],
		flightPriceTagToolTips = [],
		optimizedTags = [];

	if (!isExpand && scopDomestic) {
		// 国内航班，如果运价是折叠状态，提取和航班外露价格相等的运价集合
		const theFlightPrice = flight.getIn(['theMinPriceItem', 'avgPriceWithTax']);
		const theToken = flight.getIn(['theMinPriceItem', 'routeSearchToken']);
		const AirlineCodeList = [...(flight?.get('flightSegments')?.map((item) => item.get('airlineCode')) || [])];

		const priceTagEnums = priceList
			.filter((item) => item.get('avgPriceWithTax') === theFlightPrice)
			.reduce((acc, cur) => {
				const curPriceTags = cur.get('priceTagEnums');
				const token = cur.get('routeSearchToken');
				const dAllowAsLowestPrice = cur.get('dAllowAsLowestPrice'); // 是否能够作为最低价展示在折叠的外层
				// 如果不能够作为最低价展示在外层并且不是外露的低价，则标签也不展示
				if (curPriceTags && curPriceTags.size && (dAllowAsLowestPrice || theToken === token)) {
					curPriceTags.forEach((theEnum) => {
						if (!acc.find((item) => item.tag && theEnum.tag && item.tag.key === theEnum.tag.key)) {
							acc.push(theEnum);
						}
					});
				}

				return acc;
			}, []);

		const travelPackageWrapper = priceTagEnums.find((wrapper) => wrapper.tag.key === 'TravelPackage'); // 是否有旅套

		priceTagEnums.map((wrapper, i) => {
			if (
				wrapper.data.showInFlight !== false &&
				flightPriceTags.length + optimizedTags.length < domesticFlightTagMaxCount
				// && !(wrapper.tag.key === 'BookAlreadyDiscounted' || wrapper.tag.key === 'BookAndDiscount')
			) {
				const id = getFlightPriceTagId(wrapper.tag.key, i),
					label = getPriceTagLabel({
						id,
						label: wrapper.tag.value.getLabel(wrapper),
						labelClassName: wrapper.tag.value.labelClassName,
					});
				if (wrapper.tag.key === 'BookAlreadyDiscounted' || wrapper.tag.key === 'BookAndDiscount') {
					!(
						noDiscountAirlineList.some((item) => AirlineCodeList.includes(item)) &&
						scopDomestic &&
						prevCond.get('flightWay') === 'S'
					) && optimizedTags.push(getOptmizedTag(wrapper, travelPackageWrapper, splitRound, id));
				} else {
					flightPriceTags.push(label);
					flightPriceTagToolTips.push(getPriceTagTooltip(wrapper, id));
				}
			}
		});
	}
	const showLowPriceTag =
		scopDomestic &&
		// 单程低舱才显示当日最低价
		prevCond.get('flightWay') == 'S' &&
		theMinPriceOfAllFlights.get('totalPrice') == flight.getIn(['theMinPriceItem', 'totalPrice']);

	const preferentialTransit = flight.getIn(['theMinPriceItem', 'preferentialTransit']);
	const terminalClass = `terminal ${scopDomestic ? '' : 'highlight'}`;

	const flightList = segment.get('flightList');
	const tripList = flightList.map((item) => {
		return {
			Departure: item.get('departureCityCode'),
			Destination: item.get('arrivalCityCode'),
			Date: item.get('departureDateTime').slice(0, 10),
			Airline: item.get('marketAirlineCode'),
			AirlineNumber: item.get('flightNo'),
		};
	});
	const showTraceData = {
		tripList,
		FlightWay: prevCond.get('flightWay'),
	};

	return (
		<React.Fragment>
			<UbtBoundary
				tracker={ListUbtGenerator(
					LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW,
					LIST_UBT_KEY_TYPES.FLIGHT_ITEM_SHOW,
					showTraceData,
				)}
			>
				<div
					className={classnames({
						'flight-row': true,
						'more-padding':
							(itineraryTagsRenderResult[ITINERARY_TAG_POSITION.LEFT] &&
								!onlyHiddenTagEnumsOfBestChoiceAtLeft) ||
							showLowPriceTag,
					})}
				>
					{itineraryTagsRenderResult[ITINERARY_TAG_POSITION.LEFT]}
					{isTonchengSegement
						? renderTongchengAirline()
						: renderMultiVisibleSegment
							? renderMultiVisibleAirline()
							: renderSingelVisibleAirline()}

					{showLowPriceTag && !preferentialTransit && (
						<div className="tab-box">
							<span className="tag-best-choice-flights">当日低价</span>
						</div>
					)}
					{preferentialTransit && (
						<div className="tab-box">
							<span className="tag-best-choice-flights">中转特惠</span>
						</div>
					)}
					<div className="flight-detail">
						<div className="depart-box">
							{itineraryTagsRenderResult[ITINERARY_TAG_POSITION.MIDDLE]}
							<div className="time">{Converter.extractTime(segment.get('departureDateTime'))}</div>
							<div className="airport">
								<span
									id={`departureFlightTrain${cleanItineraryId}`}
									className={(airLineClsName, TKUDepartAirlineClsName)}
								>
									{departureAirportName}
								</span>
								<span className={terminalClass} id={`departureTerminal${cleanItineraryId}`}>
									{segment.get('departureTerminal')}
								</span>
								{segment.get('departureAirportCode') === 'PKX' && newAirportPKXSwitch ? (
									<span id={`departureFlightIcon${cleanItineraryId}`} className="new-airport-icon">
										新
									</span>
								) : null}
							</div>
							{segment.get('departureNearbyAirportDistance') > 0 ? (
								<div className="distance">
									与{segment.get('departureNearbyAirportName')}直线距离{' '}
									<span className="km">{segment.get('departureNearbyAirportDistance')}km</span>
								</div>
							) : null}
						</div>

						<UbtBoundary
							skip={transferAndStopCount === 0}
							tracker={ListUbtGenerator(
								LIST_UBT_GROUP_TYPES.UPLOAD_NOW_MOUSE_ENTER,
								LIST_UBT_KEY_TYPES.FLIGHT_INFO_HOVER,
							)}
						>
							<div className="arrow-box" id={`flightInfo-${cleanItineraryId}`}>
								<div
									onMouseOver={onTrsnferMouseOver}
									id={
										scopDomestic && transferCount > 0
											? `flightDetailPopup-${cleanItineraryId}`
											: null
									}
								>
									{transferAndStopCount ? (
										<DeferredContent
											key={`remind-tag-${cleanItineraryId}-${segment.get('segmentNo')}`}
										>
											<VisaTag
												{...{
													cleanItineraryId,
													theVisaLuggageDetailPromise:
														visaFragment.theVisaLuggageDetailPromise,
												}}
											/>
										</DeferredContent>
									) : null}
									{totalTransferDurationFragment()}
									<span id={'transfer-text-' + cleanItineraryId}>
										{domesticFlightStopText &&
											stopTextList.map((item) => {
												const { cityName } = item;
												return (
													<span className="stop-text" key={item}>
														{domesticFlightStopText}
														{cityName}
													</span>
												);
											})}
									</span>
									{getFlightTransAndStopCount(
										tongChengFlightEnum,
										transferCount,
										transferAndStopCount,
										cleanItineraryId,
									)}
									<div className="transfer-info-group">
										<div className="horizontal-center">
											{stopTextList.map((stop, stopIndex) => {
												const {
													cityName,
													duration,
													airportName,
													tag,
													buildingShortName,
													isDifferentTerminal,
												} = stop;
												return (
													<div
														key={`stop_${stopIndex}_${cleanItineraryId}`}
														className="transfer-info"
													>
														{domesticFlightStopText ? (
															<div>
																<div
																	className="stop-text"
																	style={{ marginBottom: 4, display: 'block' }}
																>
																	{airportName}
																	{buildingShortName}
																</div>
																<div className="stop-text">{duration}</div>
															</div>
														) : (
															//    (
															//     <span className={`${tongChengFlightEnum ? '' : 'high-light'}`} id={`flightStop-${cleanItineraryId}-${stopIndex}`}>
															//         {`${cityName}${duration}`}
															//
															//         <ReactToolTip
															//             group="flightStopTxt"
															//             key={`${stopIndex}`}
															//             parentId={`flightStop-${cleanItineraryId}-${stopIndex}`}
															//             toolTipContent={<Tooltip content={airportName} />}
															//         />
															//     </span>
															// )
															<React.Fragment>
																<i>{tag}</i>
																{cityName}
																{duration}
																{scopDomestic && isDifferentTerminal ? (
																	<div className="diffTiminal">不同航站楼中转</div>
																) : (
																	''
																)}
															</React.Fragment>
														)}
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
						</UbtBoundary>

						<div className="arrive-box">
							<div className="time">
								{Converter.extractTime(segment.get('arrivalDateTime'))}
								<span
									className="day"
									id={segment.get('crossDays') ? `crossDays${cleanItineraryId}` : null}
								>
									{' '}
									{segment.get('crossDays')
										? `${segment.get('crossDays') > 0 ? '+' : ''}${segment.get('crossDays')}天`
										: ''}
								</span>
							</div>
							<div className="airport">
								<span
									id={`arrivalFlightTrain${cleanItineraryId}`}
									className={(airLineClsName, TKUArriveairLineClsName)}
								>
									{arrivalAirportName}
								</span>
								<span className={terminalClass} id={`arrivalTerminal${cleanItineraryId}`}>
									{segment.get('arrivalTerminal')}
								</span>
								{segment.get('arrivalAirportCode') === 'PKX' && newAirportPKXSwitch ? (
									<span id={`arrivalFlightIcon${cleanItineraryId}`} className="new-airport-icon">
										新
									</span>
								) : null}
							</div>
							{segment.get('arrivalNearbyAirportDistance') > 0 ? (
								<div className="distance">
									与{segment.get('arrivalNearbyAirportName')}直线距离{' '}
									<span className="km">{segment.get('arrivalNearbyAirportDistance')}km</span>
								</div>
							) : null}
						</div>
					</div>

					{flightDuration()}
					{getFlightDetail()}
					{flightArrivalPunctuality()}
					{
						<TooltipList
							{...{
								cleanItineraryId,
								changeComfortPopupIndex,
								segment,
								priceList,
								isBuildUpMode,
								rowIndex,
								currentSegmentSeq,
								comfortPopupIndex,
								isTFUDepartureAirline,
								isTFUArrivalAirline,
								newAirportTFUSwitch,
								departureAirportEnName,
								arrivalAirportEnName,
								isVirtualFlight,
								trafficType,
								travelRemark,
								departureAirportName,
								arrivalAirportName,
							}}
						/>
					}
					{getPriceOfTaxTip()}
					{flightPriceTags.length || optimizedTags.length ? (
						<React.Fragment>
							<div className="flight-tags">
								{optimizedTags.length ? optimizedTags : null}
								{flightPriceTags.length ? flightPriceTags : null}
							</div>
							{flightPriceTagToolTips}
						</React.Fragment>
					) : null}

					{flightRowLabelOfMinPrice ? (
						<ReactToolTip
							key={flightRowLabelIdOfMinPrice}
							parentId={flightRowLabelIdOfMinPrice}
							toolTipContent={
								<Tooltip childrenReplaceRoot={true}>
									{productTypeWrapperOfMinPrice.tag.value.getFlightRowLabelTooltip(
										theMinPriceItem,
										currentSegmentSeq,
									)}
								</Tooltip>
							}
						/>
					) : null}
					{scopeInternational ? null : (
						<ReactToolTip
							group="flightDetailPopup"
							key={`flightDetailPopup-${cleanItineraryId}`}
							parentId={`flightDetailPopup-${cleanItineraryId}`}
							toolTipContent={
								<PopupDomesticFlightDetail
									prevCond={prevCond}
									flightDetailInfos={flightInfos}
									currentSegmentSeq={currentSegmentSeq}
									showClose={false}
								/>
							}
						/>
					)}
				</div>
			</UbtBoundary>
		</React.Fragment>
	);
};

export default FlightRow;
