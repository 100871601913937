import moment from 'moment';
import qs from 'qs';
import { add as JsKit_LocalStorage_Add } from 'x-js-kit/lib/cache/localStorage';
import { addClickRec } from '@/src/ubt.v2/addClickRec';
import Converter, { SessionHandler } from './converter';
import { fetchPostWithHeaders, getTransactionIdFromGlobal } from '../../actions/common';
import { RUNTIME_SERVER_HOST } from '../../constants/common';
import CONFIG_URL from '../../constants/list/api';
import CONSTS from '../../constants/list/constConfig';
import { LIST_UBT_GROUP_TYPES, UbtHandler, LIST_UBT_KEY_TYPES } from '../../ubt.v2/list';
import LibHelper from '../../utils/lib';
import varStore from '../../constants/list/varStore';
import { fetchUserStar } from '../../actions/list/userstar';
import { getNgsDataFromCache } from '../../actions/list/getNgsPromise.js';
import { genAntiCrawlerHeader } from './genAntiCrawlerHeader.js';
import { sendUBT } from '../common/lightUbt';
import { UBT_KEY } from '../../constants/ubtKeys';
import { getIfScopeInternational } from './scope';

const TOTAL_BOOKING_PEOPLE_STORAGE_KEY = 'totalBookingPeopleStorage';

let isBook = false,
	isRedirectToPassenger = false,
	lastBookDateTime = new Date(),
	bookClick = [];

export const strictSearch = (
	itineraryTags,
	price,
	{ prevCond, minPrice, minRefundFee, minRefundFeeSign, flightIndex, priceIndex, globalSwitch, channel, fakeMark }
) => {
	let clickCountReason = {
		clickBtnTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss:SSS'),
		reason: '',
	};

	const strictSearchInProgress = new Date() - lastBookDateTime <= CONSTS.SEARCH.BOOK_TIME_OUT_IN_MS;

	const isLoginFn = typeof CLogin !== 'undefined' && CLogin.authBiz && CLogin.authBiz.isLogin;
	if (fakeMark) {
		UbtHandler.onUploadData('c_list_fake_mark', {});
		if (isLoginFn) {
			isLoginFn({}, function (result) {
				// 0表示成功，510未登录，其他异常
				const returnCode = result ? result.returnCode : null;
				if (returnCode === 0) {
					UbtHandler.onUploadData('c_list_fake_mark_while_login', {});
					window.location.reload();
				} else {
					CLogin.Mask.showView('', function () {
						UbtHandler.onUploadData('c_list_fake_mark_login_success', {});
						window.location.reload();
					});
				}
			});
		} else {
			UbtHandler.onUploadData('c_list_login_sdk_error_fake_mark', {});
			// eslint-disable-next-line no-alert, no-undef
			alert('页面数据加载失败，请重新查询。');
			window.location.reload();
		}
		return;
	}

	if ((isBook && strictSearchInProgress) || isRedirectToPassenger) {
		UbtHandler.onUploadData('c_click_book_button_no_action', {
			text: '点击预订按钮，但不进入严格反查流程',
			isBook,
			strictSearchInProgress,
			isRedirectToPassenger,
		});
		clickCountReason.reason = '点击预订按钮，但不进入严格反查流程';
		return;
	} else {
		isBook = true;
		lastBookDateTime = new Date();

		window.__SSO_submit = () => {
			//此处跳转到新版填写页
			let condition = Converter.getBookCondition(prevCond, price, minPrice),
				theRouteSearchToken = price.get('routeSearchToken'),
				filterStorageTotalPeopleByTime = (item) => +new Date() - item.time <= 5 * 60 * 1000,
				storageTotalBookingPeopleList = JSON.parse(
					localStorage.getItem(TOTAL_BOOKING_PEOPLE_STORAGE_KEY) || `[]`
				).filter((item) => filterStorageTotalPeopleByTime(item)),
				storageTotalBookingPeople = storageTotalBookingPeopleList.find(
					(item) => item.token === theRouteSearchToken && filterStorageTotalPeopleByTime(item)
				) || { token: theRouteSearchToken, time: 0, value: 0 },
				totalBookingPeople;

			//清理失效缓存
			localStorage.setItem(TOTAL_BOOKING_PEOPLE_STORAGE_KEY, JSON.stringify(storageTotalBookingPeopleList));

			if (filterStorageTotalPeopleByTime(storageTotalBookingPeople)) {
				totalBookingPeople = storageTotalBookingPeople.value;
			}

			const ngsData = getNgsDataFromCache(theRouteSearchToken);

			const priceAttributes = {
					isLowestPrice: condition.IsLowPrice,
					productName: price.get('productTypeEnum').tag.value.rawLabel,
					itineraryTags,
					totalBookingPeople,
					hasNgsScore: !!ngsData,
				},
				header = genAntiCrawlerHeader(prevCond);

			if (condition.OpenJawCitySequence) priceAttributes.openJawCitySequence = condition.OpenJawCitySequence;

			// 国内航班的三选X，需要附加  chooseXProoductId
			const chooseWrapper = price.getIn(['chooseXWrapper']);
			const xDetailId = price.getIn(['xDetail', 'id']);
			chooseWrapper && xDetailId && (priceAttributes.chooseXProductId = xDetailId);

			let searchCriteria = prevCond.withMutations((cond) => {
				return cond.set('routeSearchToken', theRouteSearchToken).set('priceAttributes', priceAttributes);
			});

			if (typeof minRefundFee !== 'undefined') {
				searchCriteria = searchCriteria
					.set('minRefundFee', minRefundFee)
					.set('minRefundFeeSign', minRefundFeeSign);
			}
			let adjacentRecommendType = SessionHandler.getAdjacentRecommendType();
			if (adjacentRecommendType) {
				searchCriteria = searchCriteria.set('adjacentRecommendType', adjacentRecommendType);
			}
			if (channel) {
				const searchSourceChannel = {
					subChannelId: channel,
				};
				searchCriteria = searchCriteria.set('searchSourceChannel', searchSourceChannel);
			}

			// 亚航超级会员..
			const ubtAirVipTag = LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.AirVipTag;
			if (ubtAirVipTag) {
				ubtAirVipTag.ischoose = 0;
			}

			searchCriteria = searchCriteria.set('preposePolicyToken', '').set('membershipFlag', false);
			const xproductPreposeInfo = varStore.PriceXProductPreposeInfoMap[theRouteSearchToken];
			if (xproductPreposeInfo) {
				if (xproductPreposeInfo.policyToken) {
					searchCriteria = searchCriteria.set('preposePolicyToken', xproductPreposeInfo.policyToken);
				}

				if (xproductPreposeInfo.isShowFlag) {
					if (ubtAirVipTag) {
						ubtAirVipTag.ischoose = 1;
					}
					searchCriteria = searchCriteria.set('membershipFlag', true);
				}
			}
			let servicePackage = price.get('servicePackage');
			if (servicePackage) {
				searchCriteria = searchCriteria.set('servicePackage', servicePackage);
			}

			let airlineMemberToken = price.getIn(['extData', 'airlineMemberToken']);
			// X前置会员权益技改新增加 airlineMemberToken 参数
			if (airlineMemberToken) {
				searchCriteria = searchCriteria.setIn(
					['extensionAttributes', 'airlineMemberToken'],
					airlineMemberToken
				);
			}

			// penaltyCriteria 退改签
			let penaltyCriteria = price.getIn(['penalty', 'penaltyCriteria']);
			if (penaltyCriteria) {
				searchCriteria = searchCriteria.set('penaltyCriteria', penaltyCriteria);
			}
			// 优惠券信息
			const bookingCouponList = price.get('bookingCouponList');
			if (bookingCouponList) {
				searchCriteria = searchCriteria.set('bookingCouponList', bookingCouponList);
			}

			fetchPostWithHeaders(CONFIG_URL.javaBook, searchCriteria, header).then((res) => {
				const json = (res && res.data) || {};
				const headers = (res && res.headers) || {};
				const valid = json && json.data;
				const orderId = valid ? +json.data.orderId : 0;
				const bookToken = valid ? json.data.bookToken : '';
				const orderIdOrBookToken = orderId || bookToken;

				if (orderIdOrBookToken) {
					const transactionId = getTransactionIdFromGlobal() || '';
					const signature = headers.flightsignature;
					// 国际和国内航班，跳转的填写页URL不同
					// const urlSuffix = channel ? `&Channel=${channel}` : ''
					// let redirectUrl = orderId ? `${RUNTIME_SERVER_HOST}/international/search/book/${orderId}/s1?from_sign=32271${urlSuffix}` : `${RUNTIME_SERVER_HOST}/itinerary/checkout/passenger?tt=${bookToken}&tx=${transactionId}&from_sign=32271${urlSuffix}`
					const { token } = window.offlineInfo || {};
					const redirectUrl = orderId
						? `${RUNTIME_SERVER_HOST}/international/search/book/${orderId}/s1?${qs.stringify({
								flightsignature: signature,
								from_sign: '32271',
								'offline-record-token': token,
						  })}`
						: `${RUNTIME_SERVER_HOST}/itinerary/checkout/passenger?${qs.stringify({
								tt: bookToken,
								tx: transactionId,
								from_sign: '32271',
								'offline-record-token': token,
						  })}`;

					UbtHandler.onUploadData('c_disable_book_button', {
						url: redirectUrl,
						text: '严格反查成功后，先禁掉预订按钮可点击，等待页面跳转',
					});
					clickCountReason.reason = '严格反查成功后，先禁掉预订按钮可点击，等待页面跳转';
					isRedirectToPassenger = true;
					const redirectTimeout = CONSTS.SEARCH.REDIRECT_TO_BOOK_TIME_OUT_IN_MS;

					setTimeout(() => {
						UbtHandler.onUploadData('c_redirect_book_page_timeout', {
							url: redirectUrl,
							text: '严格反查成功后跳转到乘机人页超时，重新放开预订按钮可点击',
							redirectTimeout,
						});
						clickCountReason.reason = '严格反查成功后跳转到乘机人页超时，重新放开预订按钮可点击';
						isRedirectToPassenger = false;
					}, redirectTimeout);
					// 缓存为空时就添加
					try {
						if (!totalBookingPeople && json.data.totalBookingPeople) {
							localStorage.setItem(
								TOTAL_BOOKING_PEOPLE_STORAGE_KEY,
								JSON.stringify(
									storageTotalBookingPeopleList.concat({
										token: theRouteSearchToken,
										time: +new Date(),
										value: +json.data.totalBookingPeople,
									})
								)
							);
						}

						if (LibHelper.isFromMapPrice()) {
							JsKit_LocalStorage_Add(`pricemap_flag_${orderIdOrBookToken}`, {
								value: LibHelper.getMapPriceQueryInfoFromUrl(),
								expire: new Date().valueOf() + 3600 * 1000,
							});
						}
						if (LibHelper.isFromHistorySearch()) {
							JsKit_LocalStorage_Add(`search_history_flag_${orderIdOrBookToken}`, {
								value: 'true',
								expire: new Date().valueOf() + 3600 * 1000,
							});
						}
						SessionHandler.clearAdjacentRecommendType();
					} catch (err) {
						UbtHandler.onUploadData('c_error_before_booking_ubt', err);
						clickCountReason.reason = '点击预订按钮出现异常';
					}

					UbtHandler.onUploadData('c_reday_to_redirect_book_page', redirectUrl);
					clickCountReason.reason = '严格反查成功';

					location.href = redirectUrl;
				} else {
					isBook = false;
					UbtHandler.onUploadData('c_strict_search_error', { data: json });

					if (!json || json.data == null) {
						clickCountReason.reason = '接口响应为空，可能生成orderId/booktoken出错';
					} else {
						clickCountReason.reason = '严格反查失败';
					}

					alert('页面数据加载失败，请重新查询。');
				}
			});

			const productType = price.getIn(['productType', 'category']) || '';
			const productTypeEnum = price.getIn(['productTypeEnum']);
			const productName = productTypeEnum ? productTypeEnum.tag.value.rawLabel : '';
			const packageId = price.get('servicePackage') || '';
			addClickRec('strictSearch', `严格反查[token:${theRouteSearchToken}]`);
			UbtHandler.onUploadData('c_click_book', { priceIndex, flightIndex, productType, productName, packageId });
			sendUBT(UBT_KEY.LIST.BOOK_BUTTON_PRESS_SUCCESS, { isInternational: getIfScopeInternational() }, true);
		};
		if (isLoginFn) {
			// strictStopQuickLoginSwitch开关打开情况下，要求匿名登录状态也弹登录框
			const strictStopQuickLoginSwitch = globalSwitch ? globalSwitch.get('strictStopQuickLoginSwitch') : false;
			// 与产品确定，为非会员用户预定入口加一个开关
			const canNoLoginUserBookSwitch = globalSwitch.get('canNoLoginUserBookSwitch');
			CLogin.authBiz.isLogin({}, function (result) {
				// 0表示成功，510未登录，其他异常
				const returnCode = result ? result.returnCode : null;
				// 是否快速登录
				const isQuickLogin = window.GlobalConfigs && window.GlobalConfigs.quickBooking;

				// strictStopQuickLoginSwitch 如果打开，则要求不能是快速登录
				// CLogin登录api的相关文档：http://conf.ctripcorp.com/pages/viewpage.action?pageId=156615771
				if (returnCode === 0 && (strictStopQuickLoginSwitch ? !isQuickLogin : true)) {
					window.__SSO_submit();
				} else {
					UbtHandler.onUploadData('c_list_login_show', {});
					CLogin.Mask.showView(
						'',
						function () {
							window.__SSO_submit();
							fetchUserStar((data) => {
								localStorage.setItem('userStarData', JSON.stringify(data));
								LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.UserStar = data;
							});
						},
						canNoLoginUserBookSwitch
					);
				}
			});
		} else {
			UbtHandler.onUploadData('c_list_login_sdk_error', {});
			alert('页面数据加载失败，请重新查询。');
			location.reload();
		}
	}

	bookClick.push(clickCountReason);
	LIST_UBT_KEY_TYPES.FLIGHT_BOOK_COUNT_REASON.value.process({ tag: bookClick });
};
