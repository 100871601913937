import React from 'react'

export const FakeLowPriceCalendar = () => {
    return <React.Fragment>
        <div className='fake-lowprice-calendar'>
            <div className='content'>
                <div className='arrow left' />
                <div className='arrow right' />
                <div className='more' />

                <div className='date c0' />
                <div className='date c1' />
                <div className='date c2' />
                <div className='date c3' />
                <div className='date c4' />
                <div className='date c5' />
                <div className='date c6' />
            </div>
        </div>
    </React.Fragment>
}