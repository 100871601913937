import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';

const OBSERVER_OPTIONS = {
	root: null,
	rootMargin: '0px',
	threshold: [0, 1],
};
const RenderCount = 6;

export const SmartScroll = ({ dataSource, renderItem, initRenderSize, flightSegmentTypeGroupIndex }) => {
	const [endIndex, setEndIndex] = useState(initRenderSize);
	const bottomRef = useRef();

	const observeElement = useCallback((el, onNotify) => {
		const observer = new IntersectionObserver((entries) => {
			const entry = entries[0];
			if (entry) {
				const { intersectionRatio, isIntersecting, target, boundingClientRect } = entry;
				const index = +target.getAttribute('index');

				onNotify({ intersectionRatio, isIntersecting, index, boundingClientRect });
			}
		}, OBSERVER_OPTIONS);

		observer.observe(el);

		return () => observer.disconnect();
	}, []);

	const addEndIndex = ({ dataSource, endIndex }) => {
		const newEndIndexCount = endIndex + RenderCount,
			dataSourceLength = dataSource.length;
		setEndIndex(newEndIndexCount > dataSourceLength ? dataSourceLength : newEndIndexCount);
	};

	useEffect(() => {
		if (bottomRef.current) {
			return observeElement(bottomRef.current, ({ intersectionRatio, isIntersecting }) => {
				// bottom进入屏幕（防止endIndexMinus1IndexItemIndex在屏幕外时不会继续渲染）
				if (intersectionRatio > 0 && isIntersecting && endIndex < dataSource.size) {
					addEndIndex({ dataSource, endIndex });
				}
			});
		}
	}, [endIndex, dataSource, observeElement]);

	const renderDataSource = useMemo(() => {
		if (flightSegmentTypeGroupIndex > 0 && flightSegmentTypeGroupIndex > endIndex) {
			return dataSource
				.slice(0, endIndex)
				.concat(dataSource.slice(flightSegmentTypeGroupIndex, flightSegmentTypeGroupIndex + 1));
		} else {
			return dataSource.slice(0, endIndex);
		}
	}, [flightSegmentTypeGroupIndex, endIndex, dataSource]);
	return (
		<React.Fragment>
			{renderDataSource.map((item, index) => {
				return <div key={item.get('itineraryId')}>{renderItem(item, index)}</div>;
			})}
			<div ref={bottomRef} />
		</React.Fragment>
	);
};
