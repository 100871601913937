import React, { useState } from 'react'
import classnames from 'classnames'
import ReactToolTip from '@/src/components/Base/reactToolTip'
import { extractPriceSummaryAsString } from '../../../../sources/common/extractPriceSummary'
import { PriceFragment } from '../../common/priceFragment'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list';
import { genPriceMiddleTags, genMinrefundfeeAndSign } from './genPriceMiddleTags';
import { getBrandPriceTag } from '../../../../sources/common/genRelatedPrices';

const getMaxAttrsCount = (relatedPrices) => {
	let max = 0;
	if (relatedPrices && relatedPrices.size) {
		relatedPrices.valueSeq().forEach((price) => {
			const plainPrice = price.toJS();
			const priceTag = getBrandPriceTag(plainPrice);
			const attrs = priceTag.data.attrs;

			if (attrs.length > max) {
				max = attrs.length;
			}
		});
	}

	return max;
};

const getHeightOfCard = (relatedPrices, hasRestrict) => {
	const attrsCount = getMaxAttrsCount(relatedPrices) + (hasRestrict ? 1 : 0);
	if (attrsCount > 9) {
		return 290 + (attrsCount - 9) * 22;
	} else {
		return 0;
	}
};

export const BrandPrice = (props) => {
	const {
			price,
			containsTax,
			prevCond,
			index,
			seq,
			minPrice,
			onClickBookBtn,
			allSegmentList,
			mileagePointSwitch,
			flight,
			relatedPrices,
			discountWrapper,
			showSalePrice,
			setIsFromOrderBtn,
			showLotteryDialog,
			setBrandPrice,
			setIsFromBrandPrice,
		} = props,
		plainPrice = price.toJS(),
		brandPriceTag = getBrandPriceTag(plainPrice),
		priceTagEnums = price.get('priceTagEnums'),
		avgPriceWithTax = price.get('avgPriceWithTax'),
		avgPriceWithoutTax = price.get('avgPriceWithoutTax'),
		avgTax = price.get('avgTax'),
		leftPriceTag = priceTagEnums.find((t) => t.tag.value.position.key === 'LEFT'), // 最多出来一个，所以可以用 find
		restrictionList = price.get('restrictionList'),
		theMinPriceToCompare = minPrice.get('totalPrice'),
		isTheMinPrice = price.get('avgPriceWithTax') === theMinPriceToCompare,
		isMultiplePassengerType = prevCond.get('isMultiplePassengerType'),
		flightWayEnum = prevCond.get('flightWayEnum').value;

	if (!brandPriceTag) {
		console.error(`品牌运价信息为空，extractPriceSummaryAsString：${extractPriceSummaryAsString(price, flight)}`);
		return null;
	}

	const { cabinName = '', brandName, cabinDesc } = brandPriceTag.data;
	const isBaseBrand = brandName === 'BaseBrand';
	const ATTR_TYPE_MAP = {
		'-1': 'bad',
		0: 'medium',
		1: 'good',
	};
	const ticketCount = price.get('ticketCount');
	const getId = (group, theIndex) => `id${index}_${theIndex}_${group}`;
	const leftPriceTagId = getId('p_tag', seq);

	let attrs = brandPriceTag.data.attrs || [];

	if (restrictionList && restrictionList.size) {
		const joinedRestrictionLabel = restrictionList.reduce(
				(prev, next) => prev + (prev ? '、' : '') + next.get('label'),
				''
			),
			title = `限${joinedRestrictionLabel}预订`;

		attrs = attrs.concat([
			{
				tag: title,
				title: title,
				desc: '',
				badness: -1,
			},
		]);
	}

	const cardHeight = getHeightOfCard(relatedPrices, restrictionList && restrictionList.size),
		cardStyle = cardHeight ? { style: { height: cardHeight } } : {};

	const newProp = {
		...props,
		brandPricePenaltyTag: attrs
			.filter((attr) => attr.tag)
			.map((attr) => (
				<div key={attr.tag} className={`brand-price-entry brand-entry-${ATTR_TYPE_MAP[attr.badness]}`}>
					<i className="brand-price-icon"></i>
					<span className="brand-entry-text">{attr.tag}</span>
				</div>
			)),
	};

	const [minRefundWrapper, setMinRefundWrapper] = useState({ minRefundFee: undefined, minRefundFeeSign: undefined }),
		{ dataMinrefundfee, dataMinrefundfeesign } = genMinrefundfeeAndSign(minRefundWrapper);

	const { nonGeneralTagsOfMiddle, generalTagsOfMiddle } = genPriceMiddleTags({
		price,
		setMinRefundWrapper,
		allSegmentList,
		prevCond,
		mileagePointSwitch,
		getId,
	});

	const priceFragment = new PriceFragment(newProp),
		priceSummary = extractPriceSummaryAsString(price, flight);

	return (
		<div className={`brand-price-card ${leftPriceTag ? 'more-padding' : ''}`}>
			<div className="brand-price-card-title">
				{leftPriceTag ? (
					<React.Fragment>
						{leftPriceTag.tag.value.getLabel(leftPriceTagId)}
						<ReactToolTip
							parentId={leftPriceTagId}
							key="tip"
							toolTipContent={leftPriceTag.tag.value.getTooltip(
								leftPriceTag,
								prevCond,
								price,
								leftPriceTagId
							)}
						/>
					</React.Fragment>
				) : null}

				<div className="main-card-title">{cabinName}</div>
				<div className={`sub-card-title ${isBaseBrand ? 'bad' : 'good'}-title`}>{cabinDesc}</div>
			</div>

			<div className="brand-price-card-content" {...cardStyle}>
				<UbtBoundary
					tracker={ListUbtGenerator(
						LIST_UBT_GROUP_TYPES.BRAND_PRICE,
						LIST_UBT_KEY_TYPES.BRAND_PRICE_HOVER_PENALTY,
						attrs,
						price.get('routeSearchToken')
					)}
				>
					{priceFragment.PenaltyFragment}
				</UbtBoundary>

				<div className="brand-price-container">
					<div className="brand-price-box">
						{containsTax ? null : <div className="brand-price-tax taxation">税费&yen;{avgTax}</div>}
						<div className="brand-price-amount"></div>
						<div
							id={`price_${index}`}
							className={classnames({ 'brand-price-amount': true, 'low-price': isTheMinPrice })}
						>
							<span className={classnames({ abbr: isMultiplePassengerType })}>
								<dfn>&yen;</dfn>
								{containsTax ? avgPriceWithTax : avgPriceWithoutTax}
							</span>
						</div>
						{isMultiplePassengerType ? (
							<ReactToolTip
								parentId={`price_${index}`}
								toolTipContent={flightWayEnum.getPriceTooltip(
									prevCond.get('adultCount'),
									prevCond.get('childCount'),
									prevCond.get('infantCount'),
									price,
									containsTax
								)}
							/>
						) : null}
					</div>

					<div
						className={classnames('brand_price_tags_root', {
							contains_tax: containsTax,
							not_contains_tax: !containsTax,
						})}
					>
						{nonGeneralTagsOfMiddle}
						{generalTagsOfMiddle}
					</div>
				</div>
				<UbtBoundary
					tracker={ListUbtGenerator(
						LIST_UBT_GROUP_TYPES.BOOK_BUTTON_CLICK,
						LIST_UBT_KEY_TYPES.BOOK_BUTTON_CLICK
					)}
				>
					<button
						u_key="book"
						u_remark={`预订品牌运价航班[priceSummary:${priceSummary}]`}
						className="btn btn-book book-operate"
						data-minrefundfee={dataMinrefundfee}
						data-minrefundfeesign={dataMinrefundfeesign}
						onClick={(ev) => {
							if (discountWrapper && !showSalePrice) {
								setIsFromOrderBtn(true);
								setBrandPrice(price);
								setIsFromBrandPrice(true);
								showLotteryDialog();
							} else {
								onClickBookBtn(price, ev);
								ev.stopPropagation();
							}
						}}
					>
						{seq === 0
							? isBaseBrand
								? '接受限制并预订'
								: '预订'
							: isBaseBrand
							? '预订'
							: `升级至${cabinName}`}
					</button>
				</UbtBoundary>
				{ticketCount ? <div className="rest-ticket">仅剩{ticketCount}张</div> : null}
			</div>
		</div>
	);
};
