import Enum from 'enum'

/**
 * 航班筛选
 */
export const EXT_FILTER_TYPE = new Enum({
    /**
   * 所有航班
   */
    Flight: {
    },
    /**
    * 直飞航班
    */
    Direct: {
    },
    /**
     * 中转航班
     */
    Transit: {
    },
    /**
     * 空铁
     */
    FlightTrain: {
    },
    /**
     * 空巴
     */
    FlightBus: {
    },
    /**
    * 推荐位数据
    */
    Recommendation: {
    },
})