import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import queryString from 'qs';

import { sendTraceExpose } from '@/src/sources/common/lightUbt';
import Price from '../price/price.js';
import FlightOperate from './flightOperate';
import CONSTS from '../../../../constants/list/constConfig';
import { PRODUCT_CATEGORY_TAG_TYPE } from '../../../../constants/list/enums';
import { CABIN } from '../../../../constants/common/enum/common';
import ubt from '../../../../ubt/listUbt';
import { UbtHandler } from '../../../../ubt.v2/handler';

import { TongChengRemark } from './tongChengRemark';
import { UbtBoundary } from '../../../../ubt.v2/list';
import { LIST_UBT_GROUP_TYPES } from '../../../../ubt.v2/list/groupTypes';
import { LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list/keyTypes';
import { BookingUbtGenerator } from '../../../../ubt.v2/book/passenger/handler';
import { ITINERARY_TAG_POSITION } from '../../../../constants/list/enums/itineraryTagPosition';
import FlightRow from './flightRow';
import { SELECTED_FLIGHT_FIX_STATUS } from '../../../../constants/list/enums/selectedFlightFixStatus.js';
import { ifIE, ifFireFox } from '../../../../sources/common/getIEVersion';
import { scopeFunctionAndExec } from '../../../../sources/list/scopeFunction.js';
import { ScopeInternationalContext } from '../../../../sources/list/scopeInternationalContext.js';
import FlightColspanBar from './flightColspanBar';
import { PRICE_SORT_TYPE_GROUP } from '../../../../constants/list/enums/domesticPriceSortTypeGroup';
import { EXT_FILTER_TYPE } from '../../../../constants/list/enums/extFilterType';

const Flight = ({
	allSegmentList,
	itineraryTagEnums,
	selected,
	index,
	itineraryId,
	isExpand,
	isBuildUpMode,
	showFlightCabin,
	onPopupFlightDetail,
	isFCRecommendYS,
	currentSegmentSeq,
	sortContainsTax,
	prevCond,
	onToggleExpand,
	onRouteSearch,
	onBook,
	flight,
	showTongChengRemark,
	globalSwitch,
	minPrice,
	isMinPriceLessPrice,
	changeComfortPopupIndex,
	comfortPopupIndex,
	lastSearchTime,
	showFlightCouponTag,
	splitRound,
	isSelected,
	selectedFlightFixStatus,
	filters,
	sortTypes,
	searchFlightsIsFinished,
	roundTabModeSwitch,
	onSetActiveRoundTabIndex,
	searchCriteriaToken,
	roundTabActiveArrivalTab,
	theMinPriceOfAllFlights,
	theMinPriceOfAllDirectFlights,
	intlPriceList,
}) => {
	const [expandAllCollapsedPrice, setExpandAllCollapsedPrice] = useState(false); // 国内航班默认折叠部分运价
	let lastestSegment = allSegmentList[currentSegmentSeq],
		isLastSegment = lastestSegment.get('isLastSegment'), // 往返组合的 currentSegmentSeq === 1
		tongChengFlightEnum = flight.get('tongChengFlightEnum'),
		cleanItineraryId = itineraryId.replace(/[,\|]/gi, ''),
		latestSegment = allSegmentList[currentSegmentSeq],
		priceList = flight.get('priceList'),
		containsVirtualFlight = allSegmentList.some((seq) => seq.get('containsVirtualFlight')),
		flightList = lastestSegment.get('flightList'),
		virtualFlight = flightList?.find((item) => item.get('virtualFlight')),
		trafficType = virtualFlight?.get('trafficType'),
		arrivalDateTime = lastestSegment.get('arrivalDateTime'),
		departureDateTime = lastestSegment.get('departureDateTime'),
		airlineCode = lastestSegment.get('airlineCode'),
		price = sortContainsTax
			? lastestSegment.get('minAvgPriceWithTax')
			: lastestSegment.get('minAvgPriceWithoutTax'),
		anyEligibilityRestriction = (price) =>
			price.get('restrictionList').some((restriction) => restriction.get('type') === 'Eligibility'),
		scopeInternational = useContext(ScopeInternationalContext),
		prevCondCabin = prevCond.get('cabinEnum').value;

	const virtualFlightData = useMemo(() => {
		return trafficType
			? {
					arrivaltime: arrivalDateTime,
					price,
					departdate: departureDateTime,
					virtualFlightAirline: airlineCode,
					// 虚拟航班OD对
					VirtualFlight_OD: flightList
						?.map((flg) => `${flg.get('departureAirportName')}-${flg.get('arrivalAirportName')}`)
						.join(','),
					VirtualFlight_position: index + 1,
					virtualFlightType: trafficType,
			  }
			: {};
	}, [arrivalDateTime, price, departureDateTime, airlineCode, flightList, index, trafficType]);

	useEffect(() => {
		virtualFlight && sendTraceExpose('online_list_virtureflights_show', virtualFlightData);
	}, [virtualFlight, virtualFlightData]);

	// 1.先判断 存在 经济和两舱的情况 再进行分舱渲染
	// 2.如果只有经济/ 两舱的情况的话 那么选择全部渲染
	// 3.目前只限制国内单程
	if (window.GlobalSearchCriteria.flightWay === 'S' && window.GlobalSearchCriteria.scope === 'd') {
		const minPriceItem = flight.get('theMinPriceItem');
		const priceItem = priceList.find((price) => {
			return price.get('routeSearchToken') === minPriceItem?.get('routeSearchToken');
		});
		// 默认展开时，展示经济舱
		let defaultCabin = 1;
		if (priceItem.size) {
			const priceCabin = priceItem.get('cabin');
			const extData = priceItem.get('extData');
			if (priceCabin === 'Y' || priceCabin === '@Y-Y') {
				// 经济舱
				if (extData?.get('SPECIAL_CABIN_TAB')) {
					// 特舱
					defaultCabin = 2;
				}
			} else {
				defaultCabin = 3;
			}
		}
		let allCabins = [];
		priceList.map((price) => allCabins.push(price.get('cabin')));
		var YcabinPriceList = priceList.filter((price) => {
			return (
				(price.get('cabin') === 'Y' || price.get('cabin') === '@Y-Y') &&
				!price?.get('extData')?.get('SPECIAL_CABIN_TAB')
			);
		});
		var SpecialYcabinPriceList = priceList.filter((price) => {
			return (
				(price.get('cabin') === 'Y' || price.get('cabin') === '@Y-Y') &&
				price?.get('extData')?.get('SPECIAL_CABIN_TAB')
			);
		});
		var specialYcabinName =
			priceList
				.find((price) => {
					return (
						(price.get('cabin') === 'Y' || price.get('cabin') === '@Y-Y') &&
						price?.get('extData')?.get('SPECIAL_CABIN_TAB')
					);
				})
				?.get('extData')
				?.get('SPECIAL_CABIN_TAB_NAME') || '特舱';
		var notYcabinPriceList = priceList.filter((price) => {
			return price.get('cabin') !== 'Y' && price.get('cabin') !== '@Y-Y';
		});
		// tab栏数量，超过2时才展示tab栏
		let tabNumber = 0;
		YcabinPriceList.size && tabNumber++;
		SpecialYcabinPriceList.size && tabNumber++;
		notYcabinPriceList.size && tabNumber++;
		var isContainBothCabins = tabNumber >= 2;
		var minPriceInYcabins = Math.min(...YcabinPriceList.map((price) => price.get('adultPrice')));
		var minPriceInSpecialYcabins = Math.min(...SpecialYcabinPriceList.map((price) => price.get('adultPrice')));
		var minPriceInNotYcabins = Math.min(...notYcabinPriceList.map((price) => price.get('adultPrice')));

		var [priceListAfterFilter, setPriceListAfterFilter] = useState([]);

		// 1是普通经济舱 2是特舱（经济舱的一种） 3是高舱
		var [cabin, setCabin] = useState(defaultCabin);

		useEffect(() => {
			switch (cabin) {
				case 2:
					setPriceListAfterFilter(SpecialYcabinPriceList);
					break;
				case 3:
					setPriceListAfterFilter(notYcabinPriceList);
					break;
				case 1:
				default:
					setPriceListAfterFilter(YcabinPriceList);
					break;
			}
		}, [cabin]);
	}

	// 显示经济舱售罄的水印，条件：1）搜索完成 2）最后一程或者往返组合  3）查询低舱  4）不包含虚拟航班 5）所有运价的所有舱位都是高舱
	// 6）当搜索国内航班时，要求航班运价是展开的
	let showEconomicalCabinWaterMark =
			searchFlightsIsFinished &&
			(isLastSegment || isBuildUpMode) &&
			!containsVirtualFlight &&
			!prevCondCabin.isHighCabin &&
			flight.get('allPriceIsHighCabin') &&
			(scopeInternational || isExpand),
		getFlightRow = (seg, rowIndex, isExpand, buildUpSegmentList) => (
			<FlightRow
				searchFlightsIsFinished={searchFlightsIsFinished}
				onDetailFooterBtnClick={null}
				splitRound={splitRound}
				showTongChengRemark={showTongChengRemark}
				showFlightCouponTag={showFlightCouponTag}
				tongChengFlightEnum={tongChengFlightEnum}
				itineraryId={itineraryId}
				prevCond={prevCond}
				sortContainsTax={sortContainsTax}
				onPopupFlightDetail={onPopupFlightDetail}
				isExpand={isExpand}
				key={index * 100 + rowIndex}
				segment={seg}
				priceList={priceList}
				itineraryTagEnums={itineraryTagEnums[rowIndex]}
				cleanItineraryId={`${cleanItineraryId}-${index * 100 + rowIndex}`}
				currentSegmentSeq={currentSegmentSeq}
				globalSwitch={globalSwitch}
				changeComfortPopupIndex={changeComfortPopupIndex}
				comfortPopupIndex={comfortPopupIndex}
				isBuildUpMode={isBuildUpMode}
				rowIndex={rowIndex}
				selected={selected}
				buildUpSegmentList={buildUpSegmentList}
				flight={flight}
				minPrice={minPrice}
				theMinPriceOfAllFlights={theMinPriceOfAllFlights}
			/>
		), // 国际运价需要前端排序
		getPriceListForceTopForInternational = (oldPriceList) => {
			// 航司旗舰店、航司授权店运价强制置顶规则
			let nonForceTop_AuthorizedStore = CONSTS.PRICE.NON_FORCE_TOP_AirlineAuthorizedStore, // 授权店置顶航司
				forceTop_FlagShipStore = CONSTS.PRICE.FORCE_TOP_FlagShipStore, // 旗舰店置顶航司
				priceField = sortContainsTax ? 'avgPriceWithTax' : 'avgPriceWithoutTax',
				sortedPriceList = oldPriceList
					.sort((prev, next) => {
						return (
							prev.get(priceField) -
							next.get(priceField) +
							((anyEligibilityRestriction(prev) ? 100000 : 0) -
								(anyEligibilityRestriction(next) ? 100000 : 0))
						);
					})
					.map((price) => price.set('sortContainsTax', sortContainsTax))
					.slice();

			// 先授权店置顶，再旗舰店置顶
			// 1）授权店置顶规则：
			//    出票航司不能出现在配置 NON_FORCE_TOP_AirlineAuthorizedStore 中
			//    要求主舱等是经济舱
			//    非限制类运价
			if (globalSwitch.get('authorizedStoreForceTopSwitch')) {
				let priceListOfAuthorizedStore = sortedPriceList.filter(
					(p) =>
						p.get('productTypeEnum').tag === PRODUCT_CATEGORY_TAG_TYPE.AirlineAuthorizedStore &&
						p.get('restrictionList').size === 0 &&
						!nonForceTop_AuthorizedStore.includes(latestSegment.get('airlineCode')) && // 航司授权店强制置顶黑名单，即配置内的出票航司不置顶
						(isBuildUpMode
							? p.get('mainCabinEnumList').every((c) => c === CABIN.Y)
							: p.get('mainCabinEnum') === CABIN.Y)
				);

				if (priceListOfAuthorizedStore.size) {
					let getMinPrice = (price) =>
							sortContainsTax
								? price.getIn(['minPriceWithTax', 'avg'])
								: price.getIn(['minPriceWithoutTax', 'avg']),
						theAuthorizedStoreOfMinPrice = priceListOfAuthorizedStore
							.sort((prev, next) => getMinPrice(prev) - getMinPrice(next))
							.get(0),
						theIndex = sortedPriceList.findIndex((p) => p === theAuthorizedStoreOfMinPrice);

					if (theIndex > 0) {
						sortedPriceList = sortedPriceList.delete(theIndex);
						sortedPriceList = sortedPriceList.insert(0, theAuthorizedStoreOfMinPrice);
					}
				}
			}

			// 2)旗舰店置顶规则：
			//   出票航司要出现在配置 FORCE_TOP_FlagShipStore 中
			//   要求最后一程包含经济舱
			//   最多只会置顶一条旗舰店运价，优先级是：品牌运价 > 低运价
			//   排除掉限留学生资质运价
			let priceListOfFlagShipStore = sortedPriceList.filter(
				(p) =>
					p.get('productTypeEnum').tag === PRODUCT_CATEGORY_TAG_TYPE.FlagShipStore &&
					forceTop_FlagShipStore.includes(latestSegment.get('airlineCode')) &&
					p.get('mainCabinEnum') === CABIN.Y &&
					!anyEligibilityRestriction(p)
			);

			if (priceListOfFlagShipStore.size) {
				const getMinPrice = (price) => price.getIn(['avgPriceWithTax']);
				const getBrandWrapperWeight = (price) => (price.get('brandWrapper') ? 0 : 1000000);

				if (priceListOfFlagShipStore.size > 1) {
					// 最多只能置顶一条
					priceListOfFlagShipStore = priceListOfFlagShipStore
						.sort((prev, next) => {
							return (
								getMinPrice(prev) -
								getMinPrice(next) +
								(getBrandWrapperWeight(prev) - getBrandWrapperWeight(next))
							);
						})
						.filter((_val, index) => index === 0);
				}

				for (let i = priceListOfFlagShipStore.size - 1; i >= 0; i--) {
					let thePrice = priceListOfFlagShipStore.get(i),
						theIndex = sortedPriceList.findIndex((p) => p === thePrice);

					if (theIndex > 0) {
						sortedPriceList = sortedPriceList.delete(theIndex);
						sortedPriceList = sortedPriceList.insert(0, thePrice);
					}
				}
			}

			return sortedPriceList;
		},
		// 国内运价在服务端已经排序，前端就不需要排序，此处过滤掉默认折叠的运价
		filterCabin = useCallback((cabin, cabinName) => {
			UbtHandler.onUploadData('C_FLT_online_TecangClassTab', { ClassTab: cabin, Class: cabinName });
			setCabin(cabin);
			setExpandAllCollapsedPrice(false);
		}),
		getPriceListForceTopForDomestic = (oldPriceList) =>
			oldPriceList.filter((price) => expandAllCollapsedPrice || !price.get('defaultCollapse')),
		priceListForceTop = scopeFunctionAndExec(
			{
				i: getPriceListForceTopForInternational,
				d: getPriceListForceTopForDomestic,
			},
			isContainBothCabins &&
				window.GlobalSearchCriteria.flightWay === 'S' &&
				window.GlobalSearchCriteria.scope === 'd'
				? priceListAfterFilter
				: intlPriceList?.length || intlPriceList?.size
				? intlPriceList
				: priceList
		);
	let hasDefaultCollapsedPrice = !scopeInternational && priceList.some((price) => price.get('defaultCollapse')), // 国内航班，默认折叠部分运价
		getIsSuperFlyer = () => {
			if (currentSegmentSeq == 0 || isBuildUpMode) {
				return itineraryTagEnums.some((itineraryTagEnum) => {
					let tagEnumsOfMiddle = itineraryTagEnum.filter(
						(wrapper) => wrapper.tag.value.position === ITINERARY_TAG_POSITION.MIDDLE
					);
					return tagEnumsOfMiddle.some((wrapper) => wrapper.tag.key == 'SuperFlyer');
				});
			} else if (selected.get('existSuperFlyer')) {
				return true;
			}
			return false;
		},
		isSuperFlyer = getIsSuperFlyer(),
		isPreSelected = () => {
			let selectedFlights = selected.get('selectedFlights');
			return (
				selectedFlights &&
				selectedFlights.size &&
				selectedFlights.some((flight) => flight.get('itineraryId') == itineraryId)
			);
		},
		selectedMinAvgPrice = () => {
			let selectedFlights = selected.get('flights');

			return (
				selectedFlights &&
				selectedFlights.getIn([sortContainsTax ? 'minPriceWithTax' : 'minPriceWithoutTax', 'avg'])
			);
		},
		selectedMinTaxPrice = () => {
			let selectedFlights = selected.get('flights');

			return selectedFlights && selectedFlights.getIn(['taxOfMinPriceWithoutTax', 'avg']);
		},
		toggleExpandAllCollapsedPrice = useCallback(() => {
			const Airline = latestSegment.get('airlineCode');
			const flightList = latestSegment.get('flightList');
			const AirlineNumber = flightList.map((flight) => flight.get('flightNo')).join('|');
			UbtHandler.onUploadData('C_FLT_Online_MoreButton', {
				ClassTab: cabin,
				Airline,
				AirlineNumber,
				PolicyNumber: priceListForceTop?.size,
				Type: expandAllCollapsedPrice ? '收起' : '展开',
			});
			setExpandAllCollapsedPrice(!expandAllCollapsedPrice);
		}, [expandAllCollapsedPrice, priceListForceTop, cabin]),
		onMoreFlightTainRecommend = useCallback(() => {
			const query = queryString.parse(location.search && location.search[0] === '?' ? location.search.substring(1) : location.search);
			const queryStr = queryString.stringify({ ...query, extfilters: EXT_FILTER_TYPE.get('FlightTrain').key });
			const { pathname, origin } = window.location;
			const newLocation = origin + pathname + '?' + queryStr;
			UbtHandler.onUploadData('c_click', { type: 'more_flight_train', url: newLocation });
			window.open(newLocation);
		}, []);

	// 产品标签埋点
	ubt.setPriceAndProductTag(priceListForceTop, itineraryTagEnums);
	let showMoreFlightTrain =
		flight.getIn(['theMinPriceItem', 'sortTypeGroupEnum']) &&
		flight.getIn(['theMinPriceItem', 'sortTypeGroupEnum']).key ==
			PRICE_SORT_TYPE_GROUP.OTHER_TRANSFER_TYPE.value.key;

	// let forceUnfoldPriceList = (isLastSegment || roundTabActiveArrivalTab) && scopeInternational; //如果是国际最后一程，就直接返回
	let forceUnfoldPriceList = false; // 如果是国际最后一程，就直接返回
	let cabinNew = cabin || 1;
	let theRenderedPriceList =
		(isExpand || forceUnfoldPriceList) && !(!roundTabActiveArrivalTab && roundTabModeSwitch) ? (
			<div className="flight-seats" key={cleanItineraryId}>
				{isContainBothCabins &&
				window.GlobalSearchCriteria.flightWay === 'S' &&
				window.GlobalSearchCriteria.scope === 'd' ? (
					<div className="cabin-tabs">
						{!!YcabinPriceList.size && (
							<div
								className={classnames({ tab: true, 'tab-active': cabin === 1 })}
								onClick={() => filterCabin(1, '经济舱')}
							>
								经济舱 ¥{minPriceInYcabins}起
							</div>
						)}
						{!!SpecialYcabinPriceList.size && (
							<div
								className={classnames({ tab: true, 'tab-active': cabin === 2, 'special-tab': true })}
								onClick={() => filterCabin(2, specialYcabinName)}
							>
								{specialYcabinName} ¥{minPriceInSpecialYcabins}起
							</div>
						)}
						{!!SpecialYcabinPriceList.size && <div className="special-tab-icon">优享</div>}
						{!!notYcabinPriceList.size && (
							<div
								className={classnames({ tab: true, 'tab-active': cabin === 3 })}
								onClick={() => filterCabin(3, '公务/头等舱')}
							>
								公务/头等舱 ¥{minPriceInNotYcabins}起
							</div>
						)}
					</div>
				) : null}
				{priceListForceTop.map((price, priceIndex) => (
					<Price
						splitRound={splitRound}
						tongChengFlightEnum={tongChengFlightEnum}
						flight={flight}
						lastSearchTime={lastSearchTime}
						isBuildUpMode={isBuildUpMode}
						currentSegmentSeq={currentSegmentSeq}
						penaltyTag={priceListForceTop.getIn([priceIndex, 'penalty', 'defaultPenaltyTag'])}
						onBook={onBook}
						allSegmentList={allSegmentList}
						prevCond={prevCond}
						containsTax={sortContainsTax}
						onPopupFlightDetail={onPopupFlightDetail}
						isFCRecommendYS={isFCRecommendYS}
						priceIndex={priceIndex}
						flightIndex={index}
						cabinNew={cabinNew}
						index={`${index}_${priceIndex}${
							flight
								.get('priceList')
								.some((price) => price.get('cabinEnumList').some((cabin) => cabin.value.virtualFlight))
								? '_v'
								: ''
						}`}
						key={`${price.get('routeSearchToken')}${price.get('servicePackage') == null ? '' : '_S'}`}
						minPrice={minPrice}
						price={price}
						containsVirtualFlight={containsVirtualFlight}
						isSuperFlyer={isSuperFlyer}
						cleanItineraryId={cleanItineraryId}
						globalSwitch={globalSwitch}
						searchFlightsIsFinished={searchFlightsIsFinished}
						searchCriteriaToken={searchCriteriaToken}
						theMinPriceOfAllFlights={theMinPriceOfAllFlights}
						theMinPriceOfAllDirectFlights={theMinPriceOfAllDirectFlights}
						virtualFlightData={virtualFlightData}
					/>
				))}
				{hasDefaultCollapsedPrice && (
					<FlightColspanBar
						className={expandAllCollapsedPrice ? 'up' : 'down'}
						text={expandAllCollapsedPrice ? '收起' : `展开查看所有产品(${priceListAfterFilter.size})`}
						onClick={toggleExpandAllCollapsedPrice}
					/>
				)}
				{showMoreFlightTrain && (
					<FlightColspanBar className="down" text={'更多空铁组合推荐'} onClick={onMoreFlightTainRecommend} />
				)}
			</div>
		) : null;

	// 已选航班的吸顶
	const flightIsFixed = isSelected && selectedFlightFixStatus !== SELECTED_FLIGHT_FIX_STATUS.NORMAL;

	// 火狐 和 ie 吸顶的行卡需要调整下宽度
	let needSetIEWidth = false;
	let needSetFFWidth = false;
	if (ifIE()) {
		needSetIEWidth = true;
	}
	if (ifFireFox() && !('scrollbar-width' in document.documentElement.style)) {
		needSetFFWidth = true;
	}
	let refundChangeTip = latestSegment.getIn(['transferRemark', 'refundChangeTip']),
		generalFlightTag = flight.get('itineraryTags').find((tag) => tag.get('type') == 'GeneralFlight');
	const roundTabModeSelected = roundTabModeSwitch && isSelected;
	return (
		<React.Fragment>
			{isSelected ? <div id="selected_flight_prefix" /> : null}
			<div
				className={classnames({
					fixed: flightIsFixed,
					'flight-item': true,
					_isFoldHide: flight.get('_isFoldHide'),
					active: isExpand,
					selected: isSelected,
					'selected-before': !isSelected && isPreSelected(),
					'ff-width': needSetFFWidth,
					'ie-width': needSetIEWidth,
					whole_click: roundTabModeSelected,
					'no-economic-cabin': showEconomicalCabinWaterMark,
					domestic: true,
				})}
				data-curseg-minmiseryindex={latestSegment.get('curSegmentMinMiseryIndex', '')}
				data-minmiseryindex={latestSegment.get('minMiseryIndex')}
				onClick={roundTabModeSelected ? () => onSetActiveRoundTabIndex(1) : null}
			>
				<div className={`flight-box${isExpand ? ' active' : ''}`}>
					{isBuildUpMode
						? [
								getFlightRow(allSegmentList[0], 0, isExpand, allSegmentList),
								getFlightRow(allSegmentList[1], 1, isExpand, allSegmentList),
						  ]
						: getFlightRow(latestSegment, 0, isExpand)}

					{forceUnfoldPriceList ? null : (
						<FlightOperate
							flight={flight}
							index={index}
							splitRound={splitRound}
							currentSegmentSeq={currentSegmentSeq}
							prevCond={prevCond}
							showFlightCabin={showFlightCabin || flight.get('isCFRecommendYS')}
							isFCRecommendYS={isFCRecommendYS}
							allSegmentList={allSegmentList}
							isBuildUpMode={isBuildUpMode}
							priceList={priceList}
							cleanItineraryId={cleanItineraryId}
							isExpand={isExpand}
							onToggleExpand={onToggleExpand}
							isLastSegment={isLastSegment}
							onRouteSearch={onRouteSearch}
							minPrice={minPrice}
							selectedMinAvgPrice={selectedMinAvgPrice()}
							selectedMinTaxPrice={selectedMinTaxPrice()}
							isMinPriceLessPrice={isMinPriceLessPrice}
							filters={filters}
							sortTypes={sortTypes}
							searchFlightsIsFinished={searchFlightsIsFinished}
							globalSwitch={globalSwitch}
							roundTabModeSelected={roundTabModeSelected}
							roundTabModeSwitch={roundTabModeSwitch}
							roundTabActiveArrivalTab={roundTabActiveArrivalTab}
							theMinPriceOfAllDirectFlights={theMinPriceOfAllDirectFlights}
							virtualFlightData={virtualFlightData}
						/>
					)}
				</div>

				{showTongChengRemark && isExpand && tongChengFlightEnum && (
					<UbtBoundary
						tracker={BookingUbtGenerator(
							LIST_UBT_GROUP_TYPES.TONGCHENG_REMARK,
							LIST_UBT_KEY_TYPES.TONGCHENG_REMARK
						)}
					>
						<TongChengRemark
							tongChengFlightEnum={tongChengFlightEnum}
							cleanItineraryId={cleanItineraryId}
							currentSegmentSeq={currentSegmentSeq}
						/>
					</UbtBoundary>
				)}
				{/* 中转退改说明，展开的时候显示,通程包含退改服务不需要展示 */}
				{isExpand && refundChangeTip && !tongChengFlightEnum ? (
					<div className="flight-tip">{refundChangeTip}</div>
				) : null}
				{/* 通用航班提示 */}
				{isExpand && generalFlightTag ? <div className="flight-tip">{generalFlightTag.get('data')}</div> : null}

				{theRenderedPriceList}
			</div>
			{isSelected ? <div id="selected_flight_posfix" /> : null}
		</React.Fragment>
	);
};

export default Flight;
