/* eslint-disable no-script-url */
import React from 'react';
import { sendBlockTrace } from '@/src/utils/sendBlockTrace';
import { BlockTypeValue, InterruptTypeValue, MainTypeValue, SubTypeValue } from '@/src/utils/types';
import PopDialog from '../common/PopDialog';
import styles from './travelTipPopup.module.scss';

/**
 * 旅套提示弹窗
 *
 */
const TravelTipPopup = ({ travelData, isShow, onClose }) => {
	if (!isShow || !travelData) {
		return null;
	}
	sendBlockTrace({
		blockType: BlockTypeValue.Pop,
		interruptType: InterruptTypeValue.Block,
		mainType:  MainTypeValue.Search,
		subType: SubTypeValue.TravelDisadvantageTip,
    });
	
	const { title, desc, subDataList } = travelData;
	return (
		<PopDialog
			contents={
				<div className={styles.PopDialogContainer}>
					<div className={styles.title}>{title}</div>
					{subDataList &&
						subDataList.map((item, index) => {
							return (
								<div key={index} className={styles.subData}>
									<span className={styles.subDataDesc}>{item.title}：</span>
									<span
										className={styles.subDataDesContent}
										dangerouslySetInnerHTML={{
											__html: item.content,
										}}
									></span>
								</div>
							);
						})}
					{desc && <div className={styles.desc}>{desc}</div>}
					<div className={styles.buttonContainer}>
						<a href='javascript:;' className={styles.commonButton} onClick={() => onClose(false)}>
							重新选择
						</a>
						<a href='javascript:;' className={styles.primaryButton} onClick={() => onClose(true)}>
							继续购买
						</a>
					</div>
				</div>
			}
		/>
	);
};

export default TravelTipPopup;
