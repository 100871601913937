import React from 'react';
import { connect } from 'react-redux';
import { scrollTo } from 'scroll-js';
import qs from 'qs';
import { Result } from '@/src/components/list/result/result';
import {
	fetchAdjacentCityLowPrice,
	fetchOpenJawRecommend,
	fetchDirectRecommend,
	fetchAllRecommendFlights,
} from '@/src/actions/list/recommend';
import Converter from '@/src/sources/list/converter';
import { fetchSearchFlights } from '@/src/actions/list/search';
import SuperFlyerOrderPopup from '@/src/components/list/popups/superFlyerOrderPopup';
import TravelTipPopup from '@/src/components/list/popups/travelTipPopup';
import FlightTrainOrderPopup from '@/src/components/list/result/recommend/flightTrainOrderPopup';
import { TransitPUConfirmPopup } from '@/src/components/list/popups/transitPUConfirm';
import { PRODUCT_CATEGORY_TAG_TYPE } from '@/src/constants/list/enums';
import { LIST_UBT_GROUP_TYPES, UbtHandler } from '@/src/ubt.v2/list';
import { genOnFixTheTop } from '@/src/actions/list/sort';
import { calcSplitRoundSelector } from '@/src/sources/list/calcSplitRoundStateSelector';
import { AirportPkX } from '@/src/components/list/popups/popupAirportPKX';
import { getLastGlobalSearchCriteria } from '@/src/sources/list/lastGlobalSearchCriteria';
import { strictSearch } from '@/src/sources/list/strictSearch';
import { getIfScopeInternational } from '@/src/sources/list/scope';
import { sendUBT } from '@/src/sources/common/lightUbt';
import { UBT_KEY } from '@/src/constants/ubtKeys';
import { getGlobalSearchCriteria } from '../selector/search';
import { searchNoResultRecommendSelector } from './recommend/searchNoResultRecommendSelector';
import {
	flightsSelector,
	prevCondSelector,
	containsTaxSelector,
	currentSegmentSeqSelector,
	isBuildUpSelector,
	isSearchSubAirportSelector,
	searchFlightsIsFinishedSelector,
	isResearchingSelector,
	getChannelIdSelector,
	getSubChannelIdSelector,
	getRouterKeySelector,
	globalSwitchSelector,
	getFixSortBarSelector,
	getFixLowPriceCalendarSelector,
	lastSearchTimeSelector,
	getSplitRoundFlightsSwitchSelector,
	hasAnyFlightsSelector,
	getSecondSegmentHasDirectFlightSelector,
	getMinPriceSelector,
	getAdBarSwitchSelector,
	fakeMarkSelector,
} from './flight/baseSelector';
import { existRecommendSelector, recommendVersionSelector } from './recommend/recommendSelector';
import { getMinMiseryIndexSelector, getFirstSegmentHasDirectFlightSelector } from './recommend/baseRecommendSelector';
import { getHighCabinHideSortVisibleFlightsSelector } from './flight/flightSelector';
import { nearByDateRecommendDataSelector } from './recommend/nearByDateRecommendSelector';

class ResultContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			popFlightTrainConfirm: false,
			popTransitPUConfirm: false,
			popSuperFlyerConfirm: false,
			flightDetailPopup: null,
			popAirportPkXConfirm: false,
			showRecommend: false,
			showNearByRecommend: false,
			popTravelTipPopup: false,
			travelData: undefined,
		};

		this.finishDirectRecommend = false;
		this.resultWrapperRef = React.createRef();
		this.lastResultWrapperScroll = null;
		this.isScroll = false;

		this.listenScrollToCancelFixCalendar = false;
		this.pendingBookParameter = {};
		this.onBook = this.onBook.bind(this);
		this.propsOnBook = this.props.onBook.bind(this);
		this.closeTravelTip = this.closeTravelTip.bind(this);
		this.continueOrdering = this.continueOrdering.bind(this);
		this.backToSelectFlights = this.backToSelectFlights.bind(this);
		this.hideTransitPUConfirmPopup = this.hideTransitPUConfirmPopup.bind(this);
		this.setShowRecommend = this.setShowRecommend.bind(this);
		this.setShowNearByRecommend = this.setShowNearByRecommend.bind(this);
	}
	closeTravelTip(isCountinue) {
		this.setState({ popTravelTipPopup: false });
		this.travelTipClick(isCountinue);
	}

	continueOrdering() {
		this.genPopDialogBeforeOrder.next(true);
		this.props.onSetPopupStatus(false);
	}

	backToSelectFlights() {
		this.genPopDialogBeforeOrder.next(false);
		this.props.onSetPopupStatus(false);
	}

	hideTransitPUConfirmPopup() {
		this.props.onSetPopupStatus(false);
		this.setState({ popTransitPUConfirm: false });
	}

	setShowRecommend(show) {
		this.setState({ showRecommend: show });
	}

	setShowNearByRecommend(show) {
		this.setState({ showNearByRecommend: show });
	}

	sendUbt(_isFCRecommendYS) {
		let ubtProps = {},
			ubtSourceInfo = LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct
				? LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct.SourceInfo
				: null;
		if (ubtSourceInfo && Object.keys(ubtSourceInfo).length > 0) {
			ubtProps.SourceInfo = Converter.ubtSourceInfoCombiner(ubtSourceInfo);
		}

		if (ubtProps && Object.keys(ubtProps).length > 0) {
			Converter.saveRecommendSourceInfoForBookingPage(ubtProps, this.props.prevCond.get('transactionID'));
		}
	}

	async onBook(
		flight,
		price,
		{ isSuperFlyer, needSendUbt, isFCRecommendYS, minRefundFee, minRefundFeeSign, flightIndex, priceIndex }
	) {
		const _this = this,
			prevCond = this.props.prevCond,
			lastSearchTime = this.props.lastSearchTime,
			isBuildUpMode = this.props.isBuildUpMode,
			minPrice = this.props.minPrice,
			globalSwitch = this.props.globalSwitch,
			itineraryTags = flight
				.get('itineraryTagEnums')
				.toJS()
				.filter((theEnum) => theEnum.tag.value.submitOnBook)
				.map((theEnum) => theEnum.tag.key);

		const query = qs.parse(
				location.search && location.search[0] === '?' ? location.search.substring(1) : location.search
			),
			{ subchannelid } = query,
			params = [
				itineraryTags,
				price,
				{
					prevCond,
					lastSearchTime,
					isBuildUpMode,
					minPrice,
					minRefundFee,
					minRefundFeeSign,
					flightIndex,
					priceIndex,
					globalSwitch,
					channel: subchannelid,
					fakeMark: this.props.fakeMark,
				},
			],
			onSetPopupStatus = this.props.onSetPopupStatus;
		let showPopup = false,
			isHasPKX = false,
			newAirportPKXSwitch = window.GlobalSwitches.newAirportPKXSwitch;

		flight.get('flightSegments').forEach((flightSegment) => {
			let departureAirportCode = flightSegment.get('departureAirportCode'),
				arrivalAirportCode = flightSegment.get('arrivalAirportCode');

			if (departureAirportCode == 'PKX' || arrivalAirportCode == 'PKX') {
				isHasPKX = true;
			}
		});
		needSendUbt && this.sendUbt(isFCRecommendYS);
		sendUBT(UBT_KEY.LIST.BOOK_BUTTON_PRESS, { isInternational: getIfScopeInternational() }, true);
		const TravelPackage = (price.get('priceTags') || []).find((item) => {
			return item.get('type') === 'TravelPackage';
		});

		const travelData =
			TravelPackage &&
			(TravelPackage.get('data')?.get('hoverDataList') || []).find((item) => {
				return item?.get('type') === '15';
			});
		const awaitTravelTipClick = async () => {
			this.setState({
				popTravelTipPopup: true,
				travelData: travelData.toJS(),
			});
			return new Promise((resolve) => {
				this.travelTipClick = resolve;
			});
		};
		
		if (travelData) {
			showPopup = true;
			const isCountinue = await awaitTravelTipClick();
			if (!isCountinue) {
				return;
			}
		}

		if (isSuperFlyer) {
			this.setState({ popSuperFlyerConfirm: true });
			showPopup = true;

			this.popDialogBeforeOrder = function* () {
				let continueOrder = yield;
				this.setState({ popSuperFlyerConfirm: false });
				if (continueOrder) _this.props.onBook(...params);

				return continueOrder;
			};

			this.genPopDialogBeforeOrder = this.popDialogBeforeOrder();
			this.genPopDialogBeforeOrder.next();
		} else if (isHasPKX && newAirportPKXSwitch) {
			this.setState({ popAirportPkXConfirm: true });
			showPopup = true;

			this.popDialogBeforeOrder = function* () {
				let continueOrder = yield;
				this.setState({ popAirportPkXConfirm: false });
				if (continueOrder) _this.props.onBook(...params);

				return continueOrder;
			};
			this.genPopDialogBeforeOrder = this.popDialogBeforeOrder();
			this.genPopDialogBeforeOrder.next();
		} else {
			//多PU且有签证风险，弹窗确认
			let isTransitPU = price.get('productTypeEnum').tag === PRODUCT_CATEGORY_TAG_TYPE.TransitPU,
				hasVisaRiskCityList = price.get('transferCheckInCities').filter((city) => city.get('hasVisaRisk')),
				cityList = [];

			if (isTransitPU) {
				hasVisaRiskCityList.forEach((city) => {
					cityList.push(city);
				});
			}

			if (cityList.length) {
				this.pendingBookParameter = params;
				showPopup = true;
				this.setState({
					popTransitPUConfirm: true,
					cityList,
				});
			} else {
				_this.props.onBook(...params);
			}
		}
		onSetPopupStatus(showPopup);
	}

	onFetchDirectRecommend = (prevProps, nextProps) => {
		let { searchIsFinish, isSearchSubAirport, hasDirect, searchCriteria } = nextProps;

		if (searchIsFinish && hasDirect === false && !isSearchSubAirport && !this.finishDirectRecommend) {
			this.finishDirectRecommend = true;

			// 获取直飞推荐参数
			let params = {};
			params.cabin = searchCriteria.get('cabin');
			params.flightWay = searchCriteria.get('flightWay');
			params.departCityCode = searchCriteria.getIn(['flightSegments', 0, 'departureCityCode']);
			params.departDate = searchCriteria.getIn(['flightSegments', 0, 'departureDate']);
			params.arrivalCityCode = searchCriteria.getIn(['flightSegments', 0, 'arrivalCityCode']);

			// 判断是否需要获取直飞推荐
			searchCriteria.get('flightWay') === 'S' ? prevProps.fetchDirectRecommend(params) : undefined;

			searchCriteria.get('flightWay') === 'D'
				? ((params.returnDate = searchCriteria.getIn(['flightSegments', 1, 'departureDate'])),
				  prevProps.fetchDirectRecommend(params))
				: undefined;
		}
	};

	onFetchSearchFlights = () => {
		this.props.fetchSearchFlights(
			this.props.prevCond,
			this.props.currentSegmentSeq,
			this.props.isBuildUpMode,
			false
		);
	};

	onFetchAllWhenInit = () => {
		this.onFetchSearchFlights();
	};

	fixTopBarOnScroll = () => {
		if (!this.isScroll) {
			const now = new Date();
			const elapsed = now - getLastGlobalSearchCriteria();

			// 防止重查后的页面滚屏导致吸顶状态频繁变化
			if (elapsed >= 2000) {
				this.isScroll = true;

				setTimeout(() => {
					const sortBar = document.querySelector('.topbar-v2'), //新版筛选、排序条，下拉时候如果超出屏幕范围就要吸顶
						lowPriceCalendar = document.querySelector('.calendarlp-tab'),
						getScrollTopWrapper = () => {
							const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop,
								scrollTopDelta = currentScrollTop - this.lastScrollTop;

							return { currentScrollTop, scrollTopDelta };
						};

					if (sortBar || this.listenScrollToCancelFixCalendar) {
						// 往返分屏情况下，低价日历永远跟筛选排序条一起吸顶
						const { fixSortBar, fixLowPriceCalendar } = this.props;

						const sortBarBoundingRect = sortBar ? sortBar.getBoundingClientRect() : { top: 0, height: 0 },
							sortBarBoundingRectTop = sortBarBoundingRect.top,
							{ currentScrollTop, scrollTopDelta } = getScrollTopWrapper();

						if (scrollTopDelta > 0) {
							//向下滚屏
							// listenScrollToCancelFixCalendar 时只监听取消吸顶，不监听设置吸顶
							if (!this.listenScrollToCancelFixCalendar) {
								if (!fixSortBar) {
									//筛选排序条的吸顶
									//筛选排序条 距离顶部很近，且没有添加吸顶样式 fixed
									if (sortBarBoundingRectTop <= 5) {
										this.props.onFixTheTop({
											fixSortBar: true,
											fixLowPriceCalendar: false,
										});
									}
								} else if (lowPriceCalendar && fixLowPriceCalendar && scrollTopDelta > 25) {
									//取消低价日历的吸顶 往返分屏不会取消低价日历吸顶
									this.props.onFixTheTop({
										fixSortBar: true,
										fixLowPriceCalendar: false,
									});
								}
							}
						} else if (scrollTopDelta < 0) {
							//向上滚屏，需要判断下是否已经滚动到页面顶部去了，是的话就要清掉日历和筛选排序的固定样式，恢复默认显示
							if (fixSortBar) {
								//只有固定了排序条才需要取消置顶：如果一直都没将筛选条固定，说明当前在页面顶部范围，没有任何吸顶固定，不用特殊处理
								const flightsRoot =
										document.querySelector('.result-wrapper') ||
										document.querySelector('.result-wrapper-v3'),
									flightsRootBoundingRect = flightsRoot.getBoundingClientRect(),
									// 往返分屏时候的取消吸顶比较简单粗暴
									toNearToTop =
										flightsRootBoundingRect.top >
										sortBarBoundingRectTop + sortBarBoundingRect.height + 2;

								if (toNearToTop) {
									//向上滚屏到太靠近顶部，就要取消吸顶效果
									this.props.onFixTheTop({
										fixSortBar: false,
										fixLowPriceCalendar: false,
									});
								} else if (lowPriceCalendar && !fixLowPriceCalendar) {
									// 筛选排序条已经固定，低价日历没有固定，就固定下低价日历 往返分屏低价日历一直吸顶，就不用再刻意吸顶
									this.props.onFixTheTop({
										fixSortBar: true,
										fixLowPriceCalendar: true,
									});
								}
							}
						}

						this.lastScrollTop = currentScrollTop;
					}

					this.isScroll = false;
				}, 100);
			}
		}
	};

	onClickDocument = (ev) => {
		const target = ev.target;
		if (target && target.getAttribute) {
			const id = target.getAttribute('id');
			if (id === 'nsubmit' || id === 'personSubmit') {
				// 点击登录弹窗的 登录 按钮
				UbtHandler.onUploadData('c_list_login_account', {});
			} else if (id === 'btn_nologin') {
				// 点击登录弹窗的 不登陆直接预订 按钮
				UbtHandler.onUploadData('c_list_login_no_account', {});
			}
		}
	};

	componentDidMount() {
		this.onFetchAllWhenInit();
		this.listenScrollToCancelFixCalendar = false;

		this.lastScrollTop = 0; //最后滚屏Y轴偏移量，记录滚动方向

		//注册滚动后的筛选框和低价日历的吸顶
		window.addEventListener('scroll', this.fixTopBarOnScroll);
		document.addEventListener('click', this.onClickDocument, true);
	}

	componentWillUnmount() {
		//取消注册滚动后的筛选框和低价日历的吸顶
		window.removeEventListener('scroll', this.fixTopBarOnScroll);
		document.removeEventListener('click', this.onClickDocument, true);
	}

	componentDidUpdate(prevProps) {
		if (!this.props.splitRound) {
			if (prevProps.routerKey !== this.props.routerKey) {
				this.finishDirectRecommend = false;

				this.onFetchAllWhenInit();
			}

			this.onFetchDirectRecommend(prevProps, this.props);
		}

		// 高舱就不用拿推荐接口
		const isHighCabin = this.props.prevCond.get('cabinEnum').value.isHighCabin;
		if (
			!isHighCabin &&
			this.props.prevCond.get('flightWay') !== 'M' &&
			this.props.enableAllRecommendSwitch &&
			!prevProps.splitRound &&
			!prevProps.searchIsFinish &&
			this.props.searchIsFinish
		) {
			// 搜索完成后，拿推荐航班
			const {
				prevCond,
				minMiseryIndexOfAllFlights,
				minPrice,
				hasAnyFlight,
				firstSegmentHasDirect,
				secondSegmentHasDirect,
			} = this.props;
			this.props.fetchAllRecommendFlights({
				prevCond,
				minMiseryIndexOfAllFlights,
				minPriceOfAllFlights: minPrice.get('totalPrice'),
				hasAnyFlight,
				firstSegmentHasDirect,
				secondSegmentHasDirect,
			});
		}
		const { searchIsFinish, currentSegmentSeq } = this.props;
		// 选择第n+1程成功后，吸顶
		if (!prevProps.searchIsFinish && searchIsFinish && currentSegmentSeq >= 1) {
			const tabRoundRoot = document.querySelector('.segment_tab_root');
			if (tabRoundRoot) {
				setTimeout(() => {
					let tabRoundRootTop = tabRoundRoot.getBoundingClientRect().top;
					scrollTo(window, {
						top: tabRoundRootTop + window.scrollY,
						duration: 50,
						behavior: 'smooth',
					});
				}, 300);
			} else {
				// window.scrollTo(0, 194)
			}
		}
	}

	render() {
		const {
			splitRound,
			selectFlightItineryId,
			fixSortBar,
			fixLowPriceCalendar,
			fixSplitFlights,
			ubtOriginFlight,
			prevCond,
			showNoresultRecommendHeader,
			nearByDateRecommendData,
			lotterFloatShow,
			priceSortTypeGroupTitlesRef,
			adBarSwitch,
		} = this.props;
		const { showRecommend, showNearByRecommend } = this.state;

		return (
			<React.Fragment>
				<Result
					ubtOriginFlight={ubtOriginFlight}
					ref={this.resultWrapperRef}
					flights={this.props.flights}
					searchIsFinish={this.props.searchIsFinish}
					isResearching={this.props.isResearching}
					globalSwitch={this.props.globalSwitch}
					filterV2={this.props.filterV2}
					visibleFlights={this.props.visibleFlights}
					isBuildUpMode={this.props.isBuildUpMode}
					onBook={this.onBook}
					currentSegmentSeq={this.props.currentSegmentSeq}
					searchNoResult={this.props.searchNoResult}
					sortContainsTax={this.props.sortContainsTax}
					onPopupFlightDetail={this.props.onPopupFlightDetail}
					splitRoundFlightsSwitch={false}
					splitRound={splitRound}
					selectFlightItineryId={selectFlightItineryId}
					fixSortBar={fixSortBar}
					fixLowPriceCalendar={fixLowPriceCalendar}
					fixSplitFlights={fixSplitFlights}
					prevCond={prevCond}
					setShowRecommend={this.setShowRecommend}
					showRecommend={showRecommend}
					showNoresultRecommendHeader={showNoresultRecommendHeader}
					setShowNearByRecommend={this.setShowNearByRecommend}
					showNearByRecommend={showNearByRecommend}
					nearByDateRecommendData={nearByDateRecommendData}
					lotterFloatShow={lotterFloatShow}
					priceSortTypeGroupTitlesRef={priceSortTypeGroupTitlesRef}
					adBarSwitch={adBarSwitch}
				/>
				<FlightTrainOrderPopup
					isShow={this.state.popFlightTrainConfirm}
					back={this.backToSelectFlights}
					continueOrder={this.continueOrdering}
				/>
				<SuperFlyerOrderPopup
					isShow={this.state.popSuperFlyerConfirm}
					back={this.backToSelectFlights}
					continueOrder={this.continueOrdering}
				/>
				<TransitPUConfirmPopup
					show={this.state.popTransitPUConfirm}
					pendingBookParameter={this.pendingBookParameter}
					onConfirm={this.propsOnBook}
					cityList={this.state.cityList}
					onHide={this.hideTransitPUConfirmPopup}
				/>
				<AirportPkX
					isShow={this.state.popAirportPkXConfirm}
					back={this.backToSelectFlights}
					continueOrder={this.continueOrdering}
					prevCond={prevCond}
				/>
				<TravelTipPopup
					isShow={this.state.popTravelTipPopup}
					travelData={this.state.travelData}
					onClose={this.closeTravelTip}
				></TravelTipPopup>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { splitRound } = props,
		splitRoundFlightsSwitch = getSplitRoundFlightsSwitchSelector(state),
		flights = calcSplitRoundSelector(splitRound, flightsSelector, state, true),
		visibleFlights = calcSplitRoundSelector(splitRound, getHighCabinHideSortVisibleFlightsSelector, state, true),
		currentSegmentSeq = splitRound ? 1 : splitRoundFlightsSwitch ? 0 : currentSegmentSeqSelector(state),
		searchNoResult = calcSplitRoundSelector(splitRound, searchNoResultRecommendSelector, state, true),
		channelId = calcSplitRoundSelector(splitRound, getChannelIdSelector, state, true),
		subChannelId = calcSplitRoundSelector(splitRound, getSubChannelIdSelector, state, true),
		hasDirect = calcSplitRoundSelector(splitRound, getFirstSegmentHasDirectFlightSelector, state, true),
		searchIsFinish = calcSplitRoundSelector(splitRound, searchFlightsIsFinishedSelector, state, true),
		isResearching = calcSplitRoundSelector(splitRound, isResearchingSelector, state, true);

	return {
		flights,
		visibleFlights,
		currentSegmentSeq,
		searchNoResult,
		channelId,
		subChannelId,
		hasDirect,
		fixSortBar: getFixSortBarSelector(state),
		fixLowPriceCalendar: getFixLowPriceCalendarSelector(state),
		searchIsFinish,
		isResearching,
		minPrice: getMinPriceSelector(state),
		searchCriteria: getGlobalSearchCriteria(state),
		prevCond: prevCondSelector(state),
		isBuildUpMode: isBuildUpSelector(state),
		isSearchSubAirport: isSearchSubAirportSelector(state),
		sortContainsTax: containsTaxSelector(state),
		recommendVersion: recommendVersionSelector(state),
		isExistRecommend: existRecommendSelector(state),
		routerKey: getRouterKeySelector(state),
		lastSearchTime: lastSearchTimeSelector(state),
		globalSwitch: globalSwitchSelector(state),
		filterV2: true,
		splitRoundFlightsSwitch: false,
		fixSplitFlights: false,
		minMiseryIndexOfAllFlights: getMinMiseryIndexSelector(state),
		hasAnyFlight: hasAnyFlightsSelector(state),
		firstSegmentHasDirect: getFirstSegmentHasDirectFlightSelector(state),
		secondSegmentHasDirect: getSecondSegmentHasDirectFlightSelector(state),
		enableAllRecommendSwitch: true,
		showNoresultRecommendHeader: searchNoResultRecommendSelector(state),
		nearByDateRecommendData: nearByDateRecommendDataSelector(state),
		adBarSwitch: getAdBarSwitchSelector(state),
		fakeMark: fakeMarkSelector(state),
	};
};

const mapDispatchToProps = (dispatch) => ({
	onBook: (...params) => {
		strictSearch(...params);
	},
	fetchAdjacentCityRecommend: (params) => {
		dispatch(fetchAdjacentCityLowPrice(params));
	},
	fetchDirectRecommend: (params) => {
		dispatch(fetchDirectRecommend(params));
	},
	getOpenJawRecommend: (params) => {
		dispatch(fetchOpenJawRecommend(params));
	},
	fetchSearchFlights: (...params) => {
		dispatch(fetchSearchFlights(...params));
	},
	onFixTheTop: ({ fixSortBar, fixLowPriceCalendar }) => {
		dispatch(genOnFixTheTop(fixSortBar, fixLowPriceCalendar));
	},
	fetchAllRecommendFlights: (params) => {
		getIfScopeInternational() && dispatch(fetchAllRecommendFlights(params));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultContainer);
