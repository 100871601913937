import { ListUbt } from './listUbt'
import { LIST_UBT_KEY_TYPES } from '../ubt.v2/list';

class CFRecYSUbt extends ListUbt {
    constructor(props) {
        super(props)
    }

    setRecommendInfo(SearchClass, SearchDfNum, SearchTransNum, RecDfNum, RecClass, isUbtValid) {
        let searchClass = ''
        if (SearchClass == 'SEARCH_FC')
            searchClass = 'FC'
        if (SearchClass == 'SEARCH_F')
            searchClass = 'F'
        if (SearchClass == 'SEARCH_C')
            searchClass = 'C'

        let ubtInfo = {
            SearchClass: searchClass,
            SearchDfNum,
            SearchTransNum,
            RecDfNum,
            RecClass
        }
        if (isUbtValid) {
            LIST_UBT_KEY_TYPES.FC_REC_SY_INFO.value.invoke(ubtInfo)
        }
    }

    expandFoldCount(isOpen) {
        LIST_UBT_KEY_TYPES.FC_REC_SY_FOLD_COUNT.value.invoke(isOpen)
    }

}

export default new CFRecYSUbt()