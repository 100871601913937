import Enum from 'enum'
import Cookies from 'js-cookie'
import { UBT_EVENT_TYPE } from '../../common'
import { BOOKING_UBT_GROUP_TYPES } from './groupTypes'
import { UbtHandler } from '../../handler'
import LibHelper from "../../../utils/lib"

//此处是引用的而不是重新定义，给各种自定义事件的 process 中使用
const rawBasicDataFlow = BOOKING_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData
let isLoadUpload = false

let getSumPrice = (price) => {
    let sumPrice = 0
    if (price.get("adultPrice")) {
        sumPrice += price.get("adultPrice") + price.get("adultTax")
    }
    if (price.get("childPrice")) {
        sumPrice += price.get("childPrice") + price.get("childTax")
    }
    if (price.get("infantPrice")) {
        sumPrice += price.get("infantPrice") + price.get("infantTax")
    }
    return sumPrice
}

let processOnLoad = (flightSegments, itineraryTags, prevCond) => {
    let rawBasicDataFlowLoad = Object.assign({}, rawBasicDataFlow)  //防止仅传引用
    rawBasicDataFlowLoad.virtual_flight = flightSegments.some(seq => seq.get('containsVirtualFlight')) ? 1 : 0
    rawBasicDataFlowLoad.IsSuperFlight = itineraryTags ? (itineraryTags.some(e => e.get("type") === "SuperFlyer") ? 1 : 0) : 0
    //delete rawBasicDataFlowLoad.PriceRule
    delete rawBasicDataFlowLoad.multiple_tickets
    //delete rawBasicDataFlowLoad.multiple_tickets_protection_show
    if (window.sessionStorage.ubtSourceDataForBooking) {
        try {
            var storageUbtSourceData = JSON.parse(window.sessionStorage.ubtSourceDataForBooking)
            var transactionID = prevCond.get("transactionID")
            if (storageUbtSourceData) {
                var ubtSourceDataOfTxid = storageUbtSourceData[transactionID]
                if (ubtSourceDataOfTxid && Object.keys(ubtSourceDataOfTxid).length > 0) {
                    rawBasicDataFlowLoad = Object.assign(rawBasicDataFlowLoad, ubtSourceDataOfTxid)
                    delete storageUbtSourceData[transactionID]
                    window.sessionStorage.ubtSourceDataForBooking = JSON.stringify({ storageUbtSourceData })
                }
            }
        } catch (e) { 
            //
        }
    }

    if (sessionStorage.FC_rec_SY) {
        Object.assign(rawBasicDataFlowLoad, JSON.parse(sessionStorage.FC_rec_SY))
    }

    rawBasicDataFlowLoad.source_module = ""
    if (LibHelper.isFromMapPrice()) {
        rawBasicDataFlowLoad.source_module = "mh_search"
    }
    if (LibHelper.isFromHistorySearch()) {
        rawBasicDataFlowLoad.source_module = "recommend_card"
    }
    rawBasicDataFlowLoad.TriggerType = 'Load'
    rawBasicDataFlowLoad.UserStar = JSON.parse(localStorage.getItem('userStarData'))
    isLoadUpload = true
    UbtHandler.onUploadData(`flt_oversea_ordering_input_online_basic_v2`, rawBasicDataFlowLoad)
}

let generateSequence = (flightSegments, productTypeEnum, price) => {
    let sequence = [],
        ProductType = productTypeEnum ? productTypeEnum.tag.key : "",
        cabins = price.get("cabin"),
        splitCabin = cabins ? cabins.split('|') : []
    if (ProductType == "MultiPU" || ProductType == "TransitPU") {
        ProductType = "CombinedProduct"
    }
    flightSegments.forEach(flightSegment => {
        let currentSegmentCabin = splitCabin.length > 1 ? splitCabin[flightSegment.get('segmentNo') - 1] : splitCabin[0],
            currentSegmentCabinList = currentSegmentCabin ? currentSegmentCabin.split('-') : []
        flightSegment.get("flightList").forEach((flightSequence, flightIndex) => {
            let cabin = currentSegmentCabinList.length > 0 ? currentSegmentCabinList[flightIndex] || currentSegmentCabinList[0] : ""
            sequence.push({
                From: {
                    CityName: flightSequence.get("departureCityName"),
                    CityCode: flightSequence.get("departureCityCode"),
                    AirPortCode: flightSequence.get("departureAirportCode"),
                    CityID: flightSequence.get("departureCityId")
                },
                To: {
                    CityName: flightSequence.get("arrivalCityName"),
                    CityCode: flightSequence.get("arrivalCityCode"),
                    AirPortCode: flightSequence.get("arrivalAirportCode"),
                    CityID: flightSequence.get("arrivalCityId")
                },
                StartTime: flightSequence.get("departureDateTime"),
                EndTime: flightSequence.get("arrivalDateTime"),
                SegmentNo: flightSegment.get("segmentNo"),
                Sequence: flightSequence.get("sequenceNo"),
                Airline: flightSequence.get("marketAirlineCode"),
                Productid: flightSequence.get("flightNo"),
                ProductType: ProductType,
                Class: cabin.replace('@', '')
            })
        })
    })
    return sequence
}

export const BOOKING_UBT_KEY_TYPES = new Enum({
    //基础业务信息的上报
    BASIC_DATA_FLOW_FLIGHTINFO: {
        listen: UBT_EVENT_TYPE.LOAD,
        invoke: (...args) => {
            BOOKING_UBT_KEY_TYPES.BASIC_DATA_FLOW_FLIGHTINFO.value.process(BOOKING_UBT_KEY_TYPES.BASIC_DATA_FLOW_FLIGHTINFO.key, null, args)
        },
        process: (handler, source, _ev = []) => {
            let [flightSegments, price, hasUpsell, itineraryTags, prevCond] = handler.tag,
                bfaCookie = Cookies.get("_bfa"),
                priceTagEnums = price.get("priceTagEnums"),
                productTypeEnum = price.get("productTypeEnum"),
                sequence = generateSequence(flightSegments, productTypeEnum, price),
                theRestriction = null
            rawBasicDataFlow.Price = getSumPrice(price)
            rawBasicDataFlow.BookingChannel = price.get("channel") ? price.get("channel").split(",") : []
            rawBasicDataFlow.class_level_up = hasUpsell ? 1 : 0
            rawBasicDataFlow.TriggerType = "Exit"

            if (bfaCookie) {
                let bfaCookieList = bfaCookie.split(".")
                rawBasicDataFlow.frompvid = bfaCookieList[bfaCookieList.length - 1]
            } else {
                rawBasicDataFlow.frompvid = ""
            }
            rawBasicDataFlow.Sequence = sequence
            rawBasicDataFlow.abandon_display = 0
            rawBasicDataFlow.Is_Crawler = 0
            rawBasicDataFlow.ProductType = []
            if (price.get('restrictionList') && price.get('restrictionList').size > 0) {
                theRestriction = price.get('restrictionList').find(restriction => restriction.get('type') === 'Eligibility')
            }
            if (theRestriction && theRestriction.get('label') === "留学生") {
                rawBasicDataFlow.ProductType.push("留学生票")
            } else if (productTypeEnum) {
                rawBasicDataFlow.ProductType.push(productTypeEnum.tag.value.rawLabel)
            }
            //rawBasicDataFlow.PriceRule = price.get("comparePriceLog")
            rawBasicDataFlow.product_info = ""
            if (priceTagEnums) {
                if (priceTagEnums.some(e => e.tag.key === "SpeedIssueTicket")) {
                    rawBasicDataFlow.product_info += "极速出票"
                }
                if (priceTagEnums.some(e => e.tag.key === "TodayFreeRefund")) {
                    if (rawBasicDataFlow.product_info && rawBasicDataFlow.product_info != "") {
                        rawBasicDataFlow.product_info += ","
                    }
                    rawBasicDataFlow.product_info += "甄选服务"
                }
                if (priceTagEnums.some(e => e.tag.key === "Bomb")) {
                    rawBasicDataFlow.Is_Crawler = 1
                }
            }
            
            rawBasicDataFlow.multiple_tickets = price.get("multiple_tickets") && price.get("multiple_tickets").size > 0 ? 1 : 0
            //rawBasicDataFlow.multiple_tickets_protection_show = 1
            rawBasicDataFlow.PrePoseXProduct = []
            rawBasicDataFlow.IsNewMultiPU = ((price.get("priceBitAttributes") & 2) > 0) ? 1 : 0
            if (!isLoadUpload && flightSegments.size > 0) {
                processOnLoad(flightSegments, itineraryTags, prevCond)
            }
            rawBasicDataFlow.UserStar = JSON.parse(localStorage.getItem('userStarData'))
        }
    },
    BASIC_DATA_FLOW_PASSENGER: {
        listen: UBT_EVENT_TYPE.LOAD,
        invoke: (...args) => {
            BOOKING_UBT_KEY_TYPES.BASIC_DATA_FLOW_PASSENGER.value.process(BOOKING_UBT_KEY_TYPES.BASIC_DATA_FLOW_PASSENGER.key, null, args)
        },
        process: (handler, source, _ev = []) => {
            let [allPassengeCount, getDumpedPassengers, cardTypesMap] = handler.tag,
                dumpedPassengers = getDumpedPassengers(),
                passengersUbt = []
            rawBasicDataFlow.RealPassengerType = {
                Adult: allPassengeCount.adultCount,
                Children: allPassengeCount.childCount,
                Infant: allPassengeCount.infantCount
            }
            if (dumpedPassengers.length > 0) {
                dumpedPassengers.forEach(dumpedPassenger => {
                    if (dumpedPassenger.idCards && dumpedPassenger.idCards.length > 0) {
                        dumpedPassenger.idCards.forEach(passengerCard => {
                            if (passengerCard.cardNum && cardTypesMap.get(`${passengerCard.cardType}`)) {
                                passengersUbt.push(`${cardTypesMap.get(`${passengerCard.cardType}`)}_${passengerCard.cardNum}`)
                            }
                        })
                    }
                })
            }
            rawBasicDataFlow.Passenger = passengersUbt
        }
    },
    BASIC_DATA_FLOW_CONCAT_AREA: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: (handler, _source, _ev) => {
            rawBasicDataFlow.ContactArea = handler.tag
        }
    },
    BASIC_DATA_FLOW_PASSENGER_AREA: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: (handler, _source, _ev) => {
            rawBasicDataFlow.PassengerArea = handler.tag
        }
    },
    //点击即上报
    SEND_NOW_CLICK: {
        listen: UBT_EVENT_TYPE.MOUSE_DOWN,
        process: (handler, _source, _ev) => {
            UbtHandler.onUploadData(`c_click`, handler.tag)
            return false
        }
    },
    //load即上报
    SEND_NOW_LOAD: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: (handler, _source, _ev) => {
            UbtHandler.onUploadData(`c_load`, handler.tag)
            return false
        }
    },
    //load即上报
    SEND_NOW_LOAD_REMIND: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: (handler, _source, _ev) => {
            UbtHandler.onUploadData(`c_remind`, handler.tag)
            return false
        }
    },
    //hover即上报
    SEND_NOW_HOVER: {
        listen: UBT_EVENT_TYPE.MOUSE_ENTER,
        process: (handler, _source, _ev) => {
            UbtHandler.onUploadData(`c_hover`, handler.tag)
            return false
        }
    },
    CLASS_LEVEL_UP: {
        listen: UBT_EVENT_TYPE.MOUSE_DOWN,
        process: (handler, _source, _ev) => {
            let [params] = handler.tag
            UbtHandler.onUploadData(params.key, 1)
            return false
        }
    },
    PROMPT_MESSAGE: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: (handler, _source, _ev) => {
            const id = handler.tag[0]
            delete handler.rawData['__skip__']
            handler.rawData[id] = true

            return false
        }
    }
})