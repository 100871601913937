import React from 'react'
import { autoScrollToSearchBox } from '../../../sources/list/autoScrollToSearchBox'

const FakePriceIndicator = () => {
    return (
        <div className="fake-flight-price">
            <div className="r0c0"></div>
            <div className="r0c1"></div>
            <div className="r0c2"></div>
        </div>
    )
}

const FakeFlightSummaryIndicator = () => {
    return (
        <div className="fake-flight-summary">
            <div className="r0c0"></div>
            <div className="r0c1"></div>
            <div className="r1c1"></div>
            <div className="r0c2"></div>
            <div className="r0c3"></div>
            <div className="r0c4"></div>
            <div className="r0c5"></div>
        </div>
    )
}

const FakeFlightSummaryV2Indicator = () => {
    return (
        <div className={`fake-flight-summary`}>
            <div className="r0c0"></div>
            <div className="r0c1"></div>
            <div className="r1c1"></div>
            <div className="r0c2"></div>
            <div className="r1c2"></div>
            <div className="r0c3"></div>
            <div className="r1c3"></div>
            <div className="r0c4"></div>
            <div className="r1c4"></div>
        </div>
    )
}

const FakeFlightIndicator = () => {
    return (
        <React.Fragment>
            <FakeFlightSummaryIndicator />
            <div>
                <FakePriceIndicator />
                <FakePriceIndicator />
            </div>
        </React.Fragment>
    )
}

const FakeFlightIndicatorV2 = () => {
    return (
        <React.Fragment>
            <FakeFlightSummaryV2Indicator />
            <FakeFlightSummaryV2Indicator />
            <FakeFlightSummaryV2Indicator />
        </React.Fragment>
    )
}

export const FakeFlightsIndicator = ({ isBusy, splitRoundFlightsSwitch }) => {
    React.useLayoutEffect(() => {
        autoScrollToSearchBox()
    }, [])

    return isBusy ? (
        <div className='fake-flights'>
            <div className='bling' />
            {splitRoundFlightsSwitch ? (
                <React.Fragment>
                    <FakeFlightIndicatorV2 />
                    <FakeFlightIndicatorV2 />
                    <FakeFlightIndicatorV2 />
                    <FakeFlightIndicatorV2 />
                    <FakeFlightIndicatorV2 />
                </React.Fragment>
            ) : (
                    <React.Fragment>
                        <FakeFlightIndicator />
                        <FakeFlightIndicator />
                        <FakeFlightIndicator />
                        <FakeFlightIndicator />
                        <FakeFlightIndicator />
                    </React.Fragment>
                )}
        </div>
    ) : null
}