import React from 'react';
import classnames from 'classnames';
import Immutable from 'immutable';
import ReactToolTip from '@/src/components/Base/reactToolTip';
import getCabinListTitle from '@/src/sources/list/converter/getCabinListTitle';
import getAllSegsCabinHoverContent from '@/src/sources/list/converter/getAllSegsCabinHoverContent';

import { FLIGHT_WAY } from '../../../../constants/list/enums/common';
import { LIST_UBT_KEY_TYPES, UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES } from '../../../../ubt.v2/list';
import PriceChangePopover from '../price/PriceChangePopover';
import { scopeFunctionAndExec } from '../../../../sources/list/scopeFunction';
import { getIfScopeDomestic } from '../../../../sources/list/scope';
import { UbtHandler } from '../../../../ubt.v2/handler';
// import Tooltip from '../../../../components/list/common/tooltip'
import FlightTag from '../../../Base/FlightTag';
import { sendTrace } from '@/src/sources/common/lightUbt';
import { sendBasicTrace } from '../../common/utils';
// import { getOptmizedTag } from '../price/price';

const FlightOperate = ({
	flight,
	allSegmentList,
	cleanItineraryId,
	isBuildUpMode,
	isExpand,
	showFlightCabin,
	isFCRecommendYS,
	prevCond,
	currentSegmentSeq,
	index,
	onToggleExpand,
	onRouteSearch,
	minPrice,
	isMinPriceLessPrice,
	searchFlightsIsFinished,
	// splitRound,
	splitRoundFlightsSwitch,
	selectedMinAvgPrice = 0,
	selectedMinTaxPrice = 0,
	roundTabModeSelected,
	roundTabActiveArrivalTab,
	theMinPriceOfAllDirectFlights,
	virtualFlightData,
}) => {
	const lastestSegment = allSegmentList[currentSegmentSeq],
		isLastSegment = lastestSegment.get('isLastSegment'), // 往返组合的 currentSegmentSeq === 1
		Airline = lastestSegment.get('airlineCode'),
		flightList = lastestSegment.get('flightList'),
		AirlineNumber = flightList.map((flight) => flight.get('flightNo')).join('|'),
		theMinPriceItem = flight.getIn(['theMinPriceItem']),
		lccServiceWrapper = theMinPriceItem.get('lccServiceWrapper'),
		allSegsCabinsEnum = theMinPriceItem.getIn(['allSegsCabinsEnum']), // lastestSegment.getIn(['theMinPriceItem', 'allSegsCabinsEnum']),
		allSegsCabinsEnumList = allSegsCabinsEnum.reduce((prev, next) => prev.concat(next), Immutable.List()),
		firstRelatedCabinEnumItemKey = allSegsCabinsEnumList.first().key,
		isMixedCabinEnumList = allSegsCabinsEnumList.some((cabin) => cabin.key !== firstRelatedCabinEnumItemKey), // 是否混舱
		manyCabins = allSegsCabinsEnumList.size > 1, // 是否多舱等
		isDifferentCabin = allSegsCabinsEnumList.some((cabin) => prevCond.get('cabin').includes(cabin.key) == false),
		isMultiplePassengerType = prevCond.get('isMultiplePassengerType'),
		flightWayEnum = prevCond.get('flightWayEnum'),
		flightWayEnumValue = flightWayEnum.value,
		isMultipleFlightWay = flightWayEnum === FLIGHT_WAY.MT,
		sortContainsTax = lastestSegment.get('sortContainsTax'),
		minAvgPrice = flight.getIn([sortContainsTax ? 'minPriceWithTax' : 'minPriceWithoutTax', 'avg']),
		minTaxPrice = flight.getIn(['taxOfMinPriceWithoutTax', 'avg']),
		isTheMinPrice = isMinPriceLessPrice && minPrice.get('totalPrice') === minAvgPrice,
		priceList = flight.get('priceList'),
		minPriceList = priceList.filter(
			(price) => price.get(sortContainsTax ? 'avgPriceWithTax' : 'avgPriceWithoutTax') === minAvgPrice
		), // 航班中运价最低的所有运价，可能多个
		minPriceListEveryHasTicketCount = minPriceList.size && minPriceList.every((price) => price.get('ticketCount')), // 是否所有最低运价的余票数量都存在（服务端决定，一般是少于5张），才需要在航班级别显示
		showFewTicketCount = isLastSegment && isExpand === false && minPriceListEveryHasTicketCount,
		buttonText = roundTabModeSelected
			? '已选去程'
			: flightWayEnumValue.getBookBtnText(isExpand, isBuildUpMode, currentSegmentSeq),
		rtMinAvgAddPrice = minAvgPrice - selectedMinAvgPrice,
		rtMinTaxAddPrice = minTaxPrice - selectedMinTaxPrice,
		ticketCount = theMinPriceItem && theMinPriceItem.get('ticketCount'),
		isTheMinPriceOfAllDirectFlights =
			theMinPriceOfAllDirectFlights.get('totalPriceWithTax') == theMinPriceItem.get('totalPriceWithTax') &&
			lastestSegment.getIn(['transferCount']) == 0,
		// 为澳航打“澳航买一送一”标签
		tags = theMinPriceItem.get('priceTags'),
		referenceSalePrice = theMinPriceItem.get('referenceSalePrice'),
		MacauBuyOneGetOneTag = tags.toJS().find((tag) => tag.type === 'NxBuyOneGetOne'),
		hasMacauBuyOneGetOneTag = !!MacauBuyOneGetOneTag,
		hasReferenceSalePrice = !!referenceSalePrice,
		{ label, description } = hasMacauBuyOneGetOneTag ? MacauBuyOneGetOneTag : {},
		hasLabel = !!label,
		// priceTagEnums = theMinPriceItem?.get('priceTagEnums'),
		// optimizedDiscountWrapper = priceTagEnums.find(wrapper => wrapper.tag.key === 'BookAlreadyDiscounted' || wrapper.tag.key === 'BookAndDiscount'), //是否有已减或订立减
		// 原价渲染
		ReferenceSalePriceComponent = () => {
			if (hasReferenceSalePrice) {
				const finalPrice = sortContainsTax
					? referenceSalePrice + flight.getIn(['taxOfMinPriceWithoutTax', 'avg'])
					: referenceSalePrice;
				return (
					<React.Fragment>
						<span className="reference-Sale-Price">¥{finalPrice}</span>
					</React.Fragment>
				);
			} else {
				return null;
			}
		},
		// 澳航原价
		MacauOriginalPrice = () => {
			return sortContainsTax ? (
				<div>
					{ReferenceSalePriceComponent()}
					<span className={`price${isTheMinPrice ? ' low-price' : ''}`}>
						<dfn>&yen;</dfn>
						{minAvgPrice}
					</span>
					<span className="qi">起/人</span>
				</div>
			) : (
				<div className="price-verticle">
					<div>
						<span className={`price${isTheMinPrice ? ' low-price' : ''}`}>
							<dfn>&yen;</dfn>
							{minAvgPrice}
						</span>
						<span className="qi">起/人</span>
					</div>
					{ReferenceSalePriceComponent()}
				</div>
			);
		},
		getInternationalTax = () => {
			return sortContainsTax ? (
				splitRoundFlightsSwitch ? (
					''
				) : (
					<div className="tip">{flightWayEnumValue.getPriceText(isMultiplePassengerType, true)}</div>
				)
			) : splitRoundFlightsSwitch ? (
				isLastSegment ? (
					<div className="taxation">
						税费{rtMinTaxAddPrice >= 0 ? ' + ' : ' - '}
						<dfn>¥</dfn>
						{Math.abs(rtMinTaxAddPrice)}
					</div>
				) : (
					<div className="taxation">
						税费<dfn>¥</dfn>
						{flight.getIn(['taxOfMinPriceWithoutTax', 'avg'])}起
					</div>
				)
			) : (
				<div className="taxation">
					{flightWayEnumValue.getTaxText(isMultiplePassengerType)}
					<dfn>&yen;</dfn>
					{flight.getIn(['taxOfMinPriceWithoutTax', 'avg'])}
				</div>
			);
		},
		taxText = scopeFunctionAndExec({ i: getInternationalTax, d: () => null }),
		internationalFlightPrice = () => {
			const seatTypeId = `flightCabin_${cleanItineraryId}`;
			return (
				<PriceChangePopover
					top={5}
					isTheMinPrice={isTheMinPrice}
					price={minAvgPrice}
					currentSegmentSeq={currentSegmentSeq}
					flightWay={flightWayEnumValue.key}
				>
					{splitRoundFlightsSwitch && isLastSegment && ticketCount ? (
						<div className="less-tag">剩{ticketCount}张</div>
					) : null}
					<div
						id={`price_${index}`}
						className={classnames({
							price: true,
							'over-size': true,
							abbr: isMultiplePassengerType && !splitRoundFlightsSwitch,
						})}
					>
						{splitRoundFlightsSwitch && isLastSegment ? (
							<span className={`price`}>
								<dfn className="icon-plus">{rtMinAvgAddPrice >= 0 ? ' + ' : ' - '}</dfn>
								<dfn>¥</dfn>
								{Math.abs(rtMinAvgAddPrice)}
							</span>
						) : (
							<React.Fragment>
								{hasMacauBuyOneGetOneTag ? (
									MacauOriginalPrice()
								) : (
									<div>
										<span className={`price${isTheMinPrice ? ' low-price' : ''}`}>
											<dfn>&yen;</dfn>
											{minAvgPrice}
										</span>
										<span className="qi">起</span>
									</div>
								)}
							</React.Fragment>
						)}
					</div>

					{isMultiplePassengerType && !splitRoundFlightsSwitch ? (
						<ReactToolTip
							key={`flight_${index}`}
							parentId={`price_${index}`}
							toolTipContent={flightWayEnumValue.getPriceTooltip(
								prevCond.get('adultCount'),
								prevCond.get('childCount'),
								prevCond.get('infantCount'),
								flight.get('theMinPriceItem'),
								sortContainsTax
							)}
						/>
					) : null}
					{taxText}
					{/* 澳航往返买一送一标签 */}
					{hasLabel ? (
						<FlightTag
							id="tag-Macau-buyOneGetOne"
							name={label}
							className="tag-Macau-buyOneGetOne"
							description={description}
						/>
					) : null}
					{showFlightCabin ? (
						<div
							id={seatTypeId}
							className={classnames({
								'seat-type': true,
								highlight: isFCRecommendYS || isMixedCabinEnumList || isDifferentCabin,
								abbr: manyCabins && isMixedCabinEnumList,
							})}
						>
							{getCabinListTitle(allSegsCabinsEnumList, allSegmentList, lccServiceWrapper)}
						</div>
					) : null}
					{showFlightCabin && manyCabins && isMixedCabinEnumList ? (
						<ReactToolTip
							parentId={seatTypeId}
							toolTipContent={getAllSegsCabinHoverContent(
								prevCond,
								allSegsCabinsEnum,
								allSegmentList,
								theMinPriceItem
							)}
						/>
					) : null}
				</PriceChangePopover>
			);
		},
		domesticFlightPrice = () => {
			const containsLowCabin = flight.get('containsLowCabin');
			return isExpand
				? null
				: flightWayEnumValue.getDomesticPrice(
						{
							adultCount: prevCond.get('adultCount'),
							childCount: prevCond.get('childCount'),
							infantCount: prevCond.get('infantCount'),
							priceItem: flight.get('theMinPriceItem'),
							cabinEnum: prevCond.get('cabinEnum'),
							flightWay: flightWayEnum.key,
							allSegmentList,
							containsLowCabin,
							isMinPrice: isTheMinPriceOfAllDirectFlights,
							renderFlightPrice: true,
						}
						// , getOptmizedTag(optimizedDiscountWrapper, splitRound, index)
				  );
		},
		priceFragement = scopeFunctionAndExec({
			i: internationalFlightPrice,
			d: domesticFlightPrice,
		}),
		onFlightBtnMouseEvent = (type) => {
			UbtHandler.onUploadData('c_flight_btn_mouse_event', { type });
		};

	return (
		<div className="flight-operate">
			{isExpand ? null : <div className="flight-price">{priceFragement}</div>}
			{
				// 国内航班 && 非往返分屏的第一程
				isLastSegment || roundTabActiveArrivalTab ? (
					<div className="flight-action">
						<button
							u_key="flight_action_expand_all_price"
							u_remark={`航班上展开/收起运价`}
							className={classnames('btn', 'btn-book', {
								'txt-overflow': isMultipleFlightWay,
								ghost: isExpand,
							})}
							onClick={() => {
								getIfScopeDomestic() &&
									UbtHandler.onUploadData('C_FLT_flight_online_liebioaye_dingpiao', {
										Airline,
										AirlineNumber,
										Type: isExpand ? '收起' : '展开',
									});
								if (virtualFlightData && Object.keys(virtualFlightData).length > 0) {
									sendTrace('online_list_virtureflights_click', {
										...virtualFlightData,
										action: isExpand ? 'fold' : 'unfold',
									});
								}
								!isExpand &&
									sendBasicTrace({
										allSegmentList,
										priceList,
										minPriceList,
										sortContainsTax,
										inter: !getIfScopeDomestic(),
										flightWay: prevCond.get('flightWay'),
										passengerType: {
											adult: prevCond.get('adultCount'),
											child: prevCond.get('childCount'),
											infant: prevCond.get('infantCount'),
										},
									});
								onToggleExpand();
							}}
							onMouseEnter={() => onFlightBtnMouseEvent('mouseEnter')}
							onMouseLeave={() => onFlightBtnMouseEvent('mouseLeave')}
						>
							{isExpand ? '收起' : '订票'}
							<i className={isExpand ? 'arrow-t-up-orange' : 'arrow-t-down-white'}></i>
							{/* {showFewTicketCount ? <i className="tag tag-less">少量</i> : null} */}
							{isExpand
								? null
								: ticketCount > 0 &&
								  ticketCount < 5 && (
										<div className="book-tooltip">
											<span className="tooltip-tail"></span>
											<span className="tooltip-tail-bottom"></span>
											<div className="tooltip-content">剩{ticketCount}张</div>
										</div>
								  )}
						</button>
					</div>
				) : (
					<UbtBoundary
						tracker={ListUbtGenerator(
							LIST_UBT_GROUP_TYPES.FIRST_SEG_FLIGHT_CARD_CLICK,
							LIST_UBT_KEY_TYPES.FIRST_SEG_FLIGHT_CARD_CLICK,
							{
								clickBookingBeforeSearchIsFinished: !searchFlightsIsFinished && currentSegmentSeq == 0,
							}
						)}
					>
						<div className="flight-action">
							<div
								u_key="next_segment"
								u_remark={`航班上点击下一程进行普通反查[itineraryId:${cleanItineraryId}][${buttonText}]`}
								className={classnames({
									btn: true,
									'btn-book': true,
									'txt-overflow': isLastSegment && isMultipleFlightWay,
									'selected-flight': roundTabModeSelected,
								})}
								onClick={roundTabModeSelected ? null : isLastSegment ? onToggleExpand : onRouteSearch}
							>
								{buttonText}
								{isLastSegment ? (
									<i className={isExpand ? 'arrow-t-up-white' : 'arrow-t-down-white'}></i>
								) : null}
								{showFewTicketCount ? <i className="tag tag-less">少量</i> : null}
								{isExpand
									? null
									: ticketCount > 0 &&
									  ticketCount < 5 && (
											<div className="book-tooltip">
												<span className="tooltip-tail"></span>
												<span className="tooltip-tail-bottom"></span>
												<div className="tooltip-content">剩{ticketCount}张</div>
											</div>
									  )}
							</div>
						</div>
					</UbtBoundary>
				)
			}
		</div>
	);
};

export default FlightOperate;
