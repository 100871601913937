import mergeConfig from '../common/mergeConfig'
import Q_CONFIG_KEYS from './qConfigKeys'

const OW_RECOMMEND_RT = {
    // 7天内低价数量多余这个数才显示推荐位
    lowPriceCount: 3,
    // 返程从去程的第几天开始算起
    defaultADays: 2,
    ow_recommend_rt_index: 5,
    // 最低价系数
    lowestAirlinePriceRatio: 1.8,
    // 支持单程推往返的航线,如果有省则到则精确到省
    airlines: [
        {
            dCountryId: 1,
            dCountryName: '中国',
            aCountryId: 78,
            aCountryName: '日本',
            aDays: 1
        }
    ]    
}


export default mergeConfig(Q_CONFIG_KEYS.OW_RECOMMEND_RT, OW_RECOMMEND_RT)
