import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import ErrorBoundary from '@/src/components/Base/ErrorBoundary';

import Flight from '../../../../components/list/result/flight/flight';
import {
	getPriceListSelector,
	getIsFCRecommendYSSelector,
	getItineraryIdSelector,
	getContainsFCRecommendYS,
	getAllFlightsToSegmentListDictSelector,
	getAllFlightsToItineraryTagEnumsSelector,
	getShowTongChengRemarkSelector,
	getShowFlightCouponTagSelector,
	getIntlPriceList,
} from './flightSelector';
import { onRouteSearch, onSearchIntlPriceList } from '../../../../actions/list/flight';
import { genChangeAppendShowMoreVisibility } from '../../../../actions/list/recommend';
import { isVisibleFlightsHasDiffMinPrice } from '../recommend/baseRecommendSelector';
import {
	prevCondSelector,
	isBuildUpSelector,
	lastSearchTimeSelector,
	cfRecommendYSShowCabinSelector,
	globalSwitchSelector,
	searchFlightsIsFinishedSelector,
	autoFixOnSplitRoundRouteSwitchSelector,
	getCurrentSegmentIsLastSelector,
	getRoundTabModeSwitchSelector,
	getIfRoundTabActiveArrivalTabSelector,
	getSearchCriteriaTokenSelector,
	getMinPriceSelector,
	getTheMinPriceOfAllFlightsSelector,
	getTheMinPriceOfAllDirectFlightsSelector,
} from './baseSelector';
import { getSelectedFiltersSelector, getSelectedFlightSelector , getClassGradeFilterEnumSelector } from '../../sidebar/filterbar/filterbarSelector';
import CFRecYSUbt from '../../../../ubt/CFRecommendYS';
import { LIST_UBT_KEY_TYPES, UbtHandler } from '../../../../ubt.v2/list';

import { calcSplitRoundSelector } from '../../../../sources/list/calcSplitRoundStateSelector';
import { getElapsedTime } from '../../../../ubt.v2/getFirstViewTime';
import PriceChangePopover from '../../../../components/list/result/price/PriceChangePopover';
import { genSetActiveRoundTabIndex } from '../../../../actions/list/router';
import { getIfScopeInternational } from '../../../../sources/list/scope';
import { ExposeStat } from '../../../../ubt.v2/exposeStat';
import { getMousePositionByTriggerElement } from '../../../../sources/common/getMousePositionByTriggerElement';
import { addClickRec } from '@/src/ubt.v2/addClickRec';

const EMPTY_2D_ARRRY = [[]];
const EMPTY_2D_2ELEMENTS_ARRAY = [[], []];

class FlightContainer extends React.Component {
	constructor(props) {
		super(props);

		const itineraryTagEnums = this.getCurrentItineraryTagEnums();

		this.state = {
			// 国内情况全部收起|| 国际非最后一程收起
			// isExpand: (getIfScopeInternational() && this.props.currentSegmentIsLast),
			isExpand: false,
			comfortPopupIndex: 0,
			itineraryTagEnums,
		};

		this.setPenaltyTagFromDetail = false;
		this.onToggleExpand = this.onToggleExpand.bind(this);
		this.changeComfortPopupIndex = this.changeComfortPopupIndex.bind(this);
		this.onDetailToggleExpand = this.onDetailToggleExpand.bind(this);
		this.onRouteSearch = this.props.onRouteSearch.bind(
			this,
			() => this.props.flight,
			this.props.prevCond,
			this.props.currentSegmentSeq + 1,
			() => this.props.lastSearchTime,
			this.props.isBuildUpMode,
			() => this.props.classGradeFilterEnum,
			false,
			this.props.autoFixOnSplitRoundRouteSwitch,
			() => this.props.fixSortBar
		);
		this.onSearchIntlPriceList = this.props.onSearchIntlPriceList.bind(
			this,
			() => this.props.flight,
			this.props.prevCond,
			this.props.currentSegmentSeq + 1,
			() => this.props.lastSearchTime,
			this.props.isBuildUpMode,
			() => this.props.classGradeFilterEnum
		);
	}

	changeAppendShowMoreVisibility(visibility) {
		if (visibility !== this.state.showMore) {
			this.setState({ showMore: visibility });
		}
	}

	changeComfortPopupIndex(index) {
		if (index !== this.state.comfortPopupIndex) {
			this.setState({ comfortPopupIndex: index });
		}
	}

	onDetailToggleExpand() {
		if (!this.state.isExpand) {
			this.onToggleExpand();
		}
	}

	onToggleExpand(e) {
		//由关闭到展开，需要上报埋点
		const originalIsExpand = this.state.isExpand;
		if (originalIsExpand === false) {
			LIST_UBT_KEY_TYPES.BASIC_DATA_FLOW.value.invoke(this.props.flight);
		}

		this.setState({ isExpand: !originalIsExpand }, () => {
			// 保存航班是否展开，因为滚动时会重新render
			this.props.setExpandFlightItem({ itineraryId: this.props.itineraryId, expand: this.state.isExpand });
		});

		if (typeof this.props.updateTooFewFlights === 'function') {
			const priceListCount = this.props.flight.get('priceList').size;
			this.props.updateTooFewFlights(originalIsExpand ? -1 * priceListCount : priceListCount);
		}
		if (e && e.nativeEvent) {
			let { position, isCenter } = getMousePositionByTriggerElement(e.nativeEvent);
			UbtHandler.onUploadData('c_flight_btn_expand_click', { position, isCenter });
		}

		if (getIfScopeInternational() && !originalIsExpand) {
			this.onSearchIntlPriceList();
		}
	}

	getCurrentItineraryTagEnums = () => {
		let {
				allFlightsToSegmentListDict,
				allFlightsToItineraryTagEnumsDict,
				isBuildUpMode,
				currentSegmentSeq,
				itineraryId,
				flight,
				searchFlightsIsFinished,
			} = this.props,
			flightsToSegContainsTheItineraryId = !!allFlightsToSegmentListDict[itineraryId],
			flightsToItineraryTagContainsTheItineraryId = !!allFlightsToItineraryTagEnumsDict[itineraryId];

		if (flightsToSegContainsTheItineraryId && flightsToItineraryTagContainsTheItineraryId) {
			let latestSegment = allFlightsToSegmentListDict[itineraryId][currentSegmentSeq];

			let getItineraryTagEnums = () => {
				// 此处是为了将精选航班标签排到航班标签最后面
				let allItineraryEnums = allFlightsToItineraryTagEnumsDict[itineraryId][0]
					.filter((item) => !item.tag.value.ofBestChoiceFlight)
					.concat(
						flight.get('itineraryTagEnums').filter((item) => {
							// 精选航班标签只有在查询完成后才有效
							return searchFlightsIsFinished ? item.tag.value.ofBestChoiceFlight : false;
						})
					);

				return allItineraryEnums;
			};

			if (!isBuildUpMode) {
				//空铁组合不展示精选
				if (latestSegment.get('containsVirtualFlight')) return EMPTY_2D_ARRRY;
				return [getItineraryTagEnums()];
			} else {
				if (latestSegment.get('containsVirtualFlight')) return EMPTY_2D_2ELEMENTS_ARRAY;
				return [getItineraryTagEnums(), allFlightsToItineraryTagEnumsDict[itineraryId][1]];
			}
		} else {
			if (!isBuildUpMode) {
				return EMPTY_2D_ARRRY;
			} else {
				return EMPTY_2D_2ELEMENTS_ARRAY;
			}
		}
	};

	isTheFirstSegmentInroundTabMode() {
		return !this.props.roundTabActiveArrivalTab && this.props.roundTabModeSwitch;
	}

	componentDidUpdate(prevProps, prevState) {
		const itineraryTagEnums = this.getCurrentItineraryTagEnums();

		if (!Immutable.is(Immutable.fromJS(prevState.itineraryTagEnums), Immutable.fromJS(itineraryTagEnums))) {
			this.setState({ itineraryTagEnums });
		}
	}

	componentWillReceiveProps(nextProps) {
		if (!getIfScopeInternational() && nextProps.isExpand != this.state.isExpand) {
			// 国内的情况下 由于滚动页面重新render了航班，同步下上次展开的航班
			this.setState({
				isExpand: this.props.isExpand,
			});
		}
	}

	componentDidMount() {
		// 此处记录页面初始显示航班的时间，减掉 start_time 的时间，就是整个页面首次显示航班所花费时间
		if (this.props.index === 0) {
			const flightWay = this.props.prevCond.get('flightWay');
			const isBuildUp = this.props.isBuildUpMode;

			const firstViewTime = getElapsedTime('page_start_time', 'page_end_time');
			if (firstViewTime) {
				UbtHandler.onUploadData('c_first_page_time', { ...firstViewTime, flightWay, isBuildUpMode: isBuildUp });
			}

			const noFreshResearchViewTime = getElapsedTime(
				'no_refresh_search_start_time',
				'no_refresh_search_end_time'
			);
			if (noFreshResearchViewTime) {
				UbtHandler.onUploadData('c_no_fresh_search_page_time', {
					...noFreshResearchViewTime,
					flightWay,
					isBuildUpMode: isBuildUp,
				});
			}
		}
	}

	onSetActiveRoundTabIndex = (acitveSegmentSeq) => {
		this.props.onSetActiveRoundTabIndex(acitveSegmentSeq);
	};

	render() {
		const { allFlightsToSegmentListDict, itineraryId } = this.props,
			allSegmentList = allFlightsToSegmentListDict[itineraryId];

		if (allSegmentList) {
			let {
				flight,
				showFlightCabin,
				isFCRecommendYS,
				onBook,
				cfRecommendYSShowCabin,
				roundTabActiveArrivalTab,
				index,
			} = this.props;
			let flightItem = (
				<Flight
					{...this.props}
					allSegmentList={allSegmentList}
					itineraryTagEnums={this.state.itineraryTagEnums}
					isFCRecommendYS={isFCRecommendYS || flight.get('isCFRecommendYS')}
					onToggleExpand={this.onToggleExpand}
					isExpand={this.state.isExpand}
					showFlightCabin={showFlightCabin || cfRecommendYSShowCabin}
					onBook={onBook}
					onRouteSearch={this.onRouteSearch}
					onDetailToggleExpand={this.onDetailToggleExpand}
					//两舱推荐
					changeComfortPopupIndex={this.changeComfortPopupIndex}
					comfortPopupIndex={this.state.comfortPopupIndex}
					onSetActiveRoundTabIndex={this.onSetActiveRoundTabIndex}
					roundTabActiveArrivalTab={roundTabActiveArrivalTab}
				/>
			);
			return (
				<ErrorBoundary>
					<div>
						{index == 0 ? <ExposeStat ubtKey={'c_first_flight_show'}>{flightItem}</ExposeStat> : flightItem}
					</div>
				</ErrorBoundary>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state, props) => {
	const { splitRound } = props,
		allFlightsToSegmentListDict = calcSplitRoundSelector(
			splitRound,
			getAllFlightsToSegmentListDictSelector,
			state,
			true
		),
		allFlightsToItineraryTagEnumsDict = calcSplitRoundSelector(
			splitRound,
			getAllFlightsToItineraryTagEnumsSelector,
			[state, props],
			true
		),
		filters = calcSplitRoundSelector(splitRound, getSelectedFiltersSelector, state, true),
		showFlightCouponTag = calcSplitRoundSelector(splitRound, getShowFlightCouponTagSelector, state, true),
		globalSwitch = globalSwitchSelector(state);

	return {
		filters,
		allFlightsToSegmentListDict,
		allFlightsToItineraryTagEnumsDict,
		intlPriceList: getIntlPriceList(state, props),
		selected: getSelectedFlightSelector(state),
		priceList: getPriceListSelector(state, props),
		itineraryId: getItineraryIdSelector(state, props),
		prevCond: prevCondSelector(state),
		isBuildUpMode: isBuildUpSelector(state),
		showFlightCabin: getContainsFCRecommendYS(state, props),
		isFCRecommendYS: getIsFCRecommendYSSelector(state, props),
		lastSearchTime: lastSearchTimeSelector(state),
		cfRecommendYSShowCabin: cfRecommendYSShowCabinSelector(state),
		globalSwitch: globalSwitch,
		minPrice: getMinPriceSelector(state),
		isMinPriceLessPrice: isVisibleFlightsHasDiffMinPrice(state),
		showTongChengRemark: getShowTongChengRemarkSelector(state, props), //是否显示华夏通程的描述文字：单程、往返组合、往返的返程
		classGradeFilterEnum: getClassGradeFilterEnumSelector(state),
		showFlightCouponTag,
		searchFlightsIsFinished: searchFlightsIsFinishedSelector(state),
		splitRoundFlightsSwitch: false,
		autoFixOnSplitRoundRouteSwitch: autoFixOnSplitRoundRouteSwitchSelector(state), //往返分屏是否在反查时候自动吸顶
		currentSegmentIsLast: getCurrentSegmentIsLastSelector(state),
		roundTabActiveArrivalTab: getIfRoundTabActiveArrivalTabSelector(state),
		roundTabModeSwitch: getRoundTabModeSwitchSelector(state),
		searchCriteriaToken: getSearchCriteriaTokenSelector(state),
		theMinPriceOfAllFlights: getTheMinPriceOfAllFlightsSelector(state),
		theMinPriceOfAllDirectFlights: getTheMinPriceOfAllDirectFlightsSelector(state),
		// searchConfigId: searchConfigIdSelector(state)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		//普通反查拿最低运价对应的token即可
		onRouteSearch: (
			getFlight,
			prevCond,
			targetSegmentSeq,
			getLastSearchTime,
			isBuildUpMode,
			getClassGradeFilterEnum,
			_splitRoundFlightsSwitch,
			_autoFixOnSplitRoundRouteSwitch,
			_getFixSortBar,
			_flightDetailPromise
		) => {
			let flight = getFlight(),
				theRouteSearchToken = flight.get('minPriceRouteSearchToken'),
				searchCriteria = prevCond.withMutations((cond) => {
					return cond.set('routeSearchToken', theRouteSearchToken);
				});

			if (prevCond.get('flightWay') === 'M') {
				// 多程更新选中的价格
				PriceChangePopover.updateSelectedPrice(flight.get('minPriceWithTax').get('ADULT'));
			}
			dispatch(
				onRouteSearch(
					flight,
					searchCriteria,
					prevCond,
					targetSegmentSeq,
					isBuildUpMode,
					getClassGradeFilterEnum()
				)
			);
			addClickRec('routeSearch', `普通反查[token:${theRouteSearchToken}]`);

			// 上报上一程的结果数据
			LIST_UBT_KEY_TYPES.FLIGHT_LIST_FLOW.value.process({ tag: { upload: true } });
		},
		//虚拟航班折叠
		changeAppendShowMoreVisibility(visibility) {
			return function () {
				CFRecYSUbt.expandFoldCount(visibility == 'show');
				dispatch(genChangeAppendShowMoreVisibility(visibility));
			};
		},
		onSetActiveRoundTabIndex: (acitveSegmentSeq) => {
			dispatch(genSetActiveRoundTabIndex(acitveSegmentSeq));
		},
		onSearchIntlPriceList(
			getFlight,
			prevCond,
			targetSegmentSeq,
			getLastSearchTime,
			isBuildUpMode,
			getClassGradeFilterEnum
		) {
			let flight = getFlight(),
				theRouteSearchToken = flight.get('minPriceRouteSearchToken'),
				searchCriteria = prevCond.withMutations((cond) => {
					return cond.set('routeSearchToken', theRouteSearchToken);
				});
			// dispatch(clearIntlPriceList())
			dispatch(
				onSearchIntlPriceList(
					flight,
					searchCriteria,
					prevCond,
					targetSegmentSeq,
					isBuildUpMode,
					getClassGradeFilterEnum()
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightContainer);
