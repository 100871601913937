import mergeConfig from '../../common/mergeConfig'
import Q_CONFIG_KEYS from '../qConfigKeys'

const FLIGHT_TRAIN_MESSAGE = {
    ALERT_TITLE: '更多出行方案购买需知',
    ALERT_CONTENT: [
        '因天气、航空公司、铁路部门及突发事件等原因，可能影响乘客登机或乘车。请确认换成距离和中转时间，以便确定合适的航班、车次',
        '因航空公司、铁路方面原因或不可抗力原因，影响乘客行程所产生的损失，属于携程旅行网免责范畴之内。请及时关注天气及路况信息。',
        '如遇机票/火车票因系统原因，导致出票失败，携程将会为您自动取消订单并全额退款。'
    ],
    TRAIN_TIP_TITLE: '火车登车手续办理提醒：',
    TRAIN_TIP_CONTENT: '出票后，请务必使用乘机人电子票号在火车段出发前72小时内访问www.accesrail.com/checkin办理登车手续，并打印行程信息，以供乘车使用。'
}

export default mergeConfig(Q_CONFIG_KEYS.FLIGHT_TRAIN_MSG, FLIGHT_TRAIN_MESSAGE)
