import React from 'react';
import styles from './rightsInfo.module.scss';

type CitySpecificRightsType = Map<string, any>;

export const CommonRestrictionComp = ({ rightsInfo }: { rightsInfo: CitySpecificRightsType }) => {
	if (!rightsInfo || !rightsInfo.size) return null;

	const commonRestrictions = rightsInfo.get('commonRestrictions');
	return (
		<div className={styles.commonRestrictions}>
			{(commonRestrictions || []).map((item, index) => {
				return (
					<>
						{!!index && <span className="spacing">|</span>}
						<span className={styles.wrapFont}>{item}</span>
					</>
				);
			})}
		</div>
	);
};
