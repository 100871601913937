import React from "react"
import { sendBlockTrace } from '@/src/utils/sendBlockTrace';
import { BlockTypeValue, InterruptTypeValue, MainTypeValue, SubTypeValue } from '@/src/utils/types';
import PopDialog from '../common/PopDialog'
import { UED } from "./styleConst"

export const AirportPkX = ({ ...props }) => {
    const { isShow, back, continueOrder, prevCond} = props
    const arrival = prevCond.get("arrivalCountryName"),
    depart = prevCond.get("departCountryName")
    let content

    if(depart === "中国") {
        content = '该航班的出发机场为北京大兴机场'
    }else if(arrival === "中国") {
        content = '该航班的到达机场为北京大兴机场'
    }
    if (isShow) {
        sendBlockTrace({
            blockType: BlockTypeValue.Pop,
            interruptType: InterruptTypeValue.Block,
            mainType:  MainTypeValue.Search,
            subType: SubTypeValue.PKAirports,
        });
    }
    return (
        !isShow ? null : (
        <PopDialog contents={(
        <div className="basic-alert alert-giftinfo" style={UED.popupMargin}>
            <div className="alert-title" style={{fontWeight: '700'}}>预订提醒</div>
            <div className="alert-body" style={{paddingTop: '18px',borderColor: 'rgb(153, 153, 153)'}}>
                <h3 className="alert-tit" style={{fontWeight: 'normal',color: '#333', paddingBottom: '10px'}}>{prevCond.get("flightWay") === "S" ? content : '所选航班包含的起飞（到达）机场为北京大兴机场'}</h3>
                <p className="alert-con" style={{color: '#999'}}>距市区约46KM，从大兴机场搭乘地铁到市区（草桥站）需约30分钟</p>
            </div>
            <div className="alert-footer">
                <div className="btn-group">
                    <a href="javascript:;" className="btn btn-ghost" onClick={back}>重新选择航班</a>
                    <a href="javascript:;" className="btn" onClick={continueOrder}>我知道了</a>
                </div>
            </div>
        </div>)} />
        )
    )
}
