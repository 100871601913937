import { MARK_CRAWLER, MARK_PRE_SEARCH, MARK_SPA, MARK_QUICK_SEARCH_FLIGHTS } from '../sources/common/localStorageKey'

export const getElapsedTime = (startElId, endElId) => {
    const page_end_time = document.querySelector(`#${endElId}`)
    if (page_end_time) {
        return null
    } else {
        const now = +new Date()
        const endTimeEl = document.createElement('input')
        endTimeEl.setAttribute('id', endElId)
        endTimeEl.setAttribute('type', 'hidden')
        endTimeEl.setAttribute('value', now)

        document.body.appendChild(endTimeEl)

        const refer = document.referrer
        const bomb = sessionStorage.getItem(MARK_CRAWLER) ? '1' : '0'    //爬虫标记
        const presearch = sessionStorage.getItem(MARK_PRE_SEARCH) ? '1' : '0'    //预搜索标记
        const spa = !!sessionStorage.getItem(MARK_SPA)                   // 是否单页，是的话用完还要马上清理
        spa && sessionStorage.removeItem(MARK_SPA)
        const quick_search = !!sessionStorage.getItem(MARK_QUICK_SEARCH_FLIGHTS)                   // 是否单页，是的话用完还要马上清理
        quick_search && sessionStorage.removeItem(MARK_QUICK_SEARCH_FLIGHTS)

        const page_start_time = document.querySelector(`#${startElId}`)
        if (page_start_time) {
            const pageStartTimeVal = page_start_time.getAttribute('value')
            if (pageStartTimeVal) {
                const pageUsedTime = now - (+pageStartTimeVal)
                return {
                    pageStartTime: pageStartTimeVal,
                    pageEndTime: now,
                    pageUsedTime,
                    bomb,
                    presearch,
                    refer,
                    spa: spa ? '1' : '0',
                    quick_search: quick_search ? '1' : '0'
                }
            }
        }
    }

    return null
}
