import mergeConfig from '../../common/mergeConfig'
import Q_CONFIG_KEYS from '../qConfigKeys'

const _LIST_TIP_MSG = {
    SORT: {
        BEST_CHOICE_TIP_MAIN_TITLE: '已为您优先呈现精选航班',
        BEST_CHOICE_TIP_SUB_TITLE: '（我们通过评估影响出行体验的关键因素，精选出优质航班，让您的选择更省心）'
    },
    SPLIT_FLIGHTS: {
        NO_MORE_FLIGHTS: '没有更多航班了'
    }
}

export const LIST_TIP_MSG = mergeConfig(Q_CONFIG_KEYS.LIST_TIP_CONFIG, _LIST_TIP_MSG)
