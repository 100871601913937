import React, { useState, forwardRef } from 'react'
import PopDialog from '../../common/PopDialog.js'

const FlightsGroupTitle = forwardRef(({ theSegmentTypeGroupEnum }, ref) => {
    const { title, desc, modalTitle } = theSegmentTypeGroupEnum.value || {}
    const [showDialog, setDialogShow] = useState(false)
    const onConfirm = () => {
        setDialogShow(false)
    }
    const onShowDialog = () => {
        setDialogShow(true)
    }
    return title ? <div className='flight-segment-type-group' >
        <h3 ref={ref} >{title}</h3>
        {(desc && desc.length) ? <span className="desc" onClick={onShowDialog}>购买须知</span> : null}
        {showDialog ? <PopDialog contents={(
            <div className="basic-alert popup-commmon">
                <div className="alert-title">{modalTitle}</div>
                <div className="alert-body">
                    {desc.map((t, i) => {
                        return <div className="alert-info" key={i}>{i + 1}.{t}</div>
                    })}
                </div>
                <div className="alert-footer">
                    <div className="btn-group">
                        <a href="javascript:;" className="btn" onClick={() => onConfirm()}>我知道了</a>
                    </div>
                </div>
            </div>)} /> : null}
    </div> : null
})

export default FlightsGroupTitle
