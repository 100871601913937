import React from 'react'
import { connect } from 'react-redux'
import Flights from '../../../../components/list/result/flights/flights'
import {
    globalSwitchSelector, getHighCabinExpandExtraRecFlightsSelector, containsTaxSelector,
    prevCondSelector, selectedFlightsSelector,
    splitRoundMaskFlightsSwitchSelector,
    getRoundTabModeSwitchSelector,
    getActiveRoundTabIndexSelector
} from '../flight/baseSelector';
import { recommendVersionSelector } from '../recommend/recommendSelector'
import {
    getHighCabinRecommendMainTipSelector, getHighCabinRecommendGroupTipListSelector,
    getHighCabinHideOrShowFlightsHandlerIndexSelector, getIfFixSplitFlightsSelector
} from '../flight/flightSelector';
import { getVisiblePriceSortTypeGroupSiezeSelector } from '../../sidebar/filterbar/filterbarSelector'
import { genToggleHighCabinExpandExtraRecFlights } from '../../../../actions/list';
import { isShowOwRecommendRtSelector } from '../recommend/owRecommendRtSelector'
import { LIST_UBT_KEY_TYPES, LIST_UBT_GROUP_TYPES, ListUbtGenerator, UbtHandler } from '../../../../ubt.v2/list';
import { calcSplitRoundSelector } from '../../../../sources/list/calcSplitRoundStateSelector';
import { regWindowEvent } from '../../../../sources/common/regWindowEvent'
import { PRE_SEARCH_COST_TIME } from '../../../../sources/common/localStorageKey'
import { ScopeInternationalContext } from '../../../../sources/list/scopeInternationalContext';
import { performanceTrace } from '../../../../utils/performanceTrace';

class FlightsContainer extends React.Component {
    static contextType = ScopeInternationalContext

    constructor(props) {
        super(props)

        //精选航班
        this.bestChoiceFlightsUbtHandler = ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS)
    }

    onSetResumeIndex = () => {
        this.props.onToggleHighCabinExpandExtraRecFlights(!this.props.highCabinExpandExtraRecFlights)
    }

    uploadChannelPreSearchUbt = () => {
        const preSearchCostTimeStr = sessionStorage.getItem(PRE_SEARCH_COST_TIME)
        if (preSearchCostTimeStr) {
            // 删除首页预搜索耗时埋点相关 sessionStorage
            sessionStorage.removeItem(PRE_SEARCH_COST_TIME)

            const preSearchCostTimeJson = JSON.parse(preSearchCostTimeStr)
            const { s0, s1, s2, s3 } = preSearchCostTimeJson
            UbtHandler.onUploadData('channel_pre_search_cost_time', { t1: s1 - s0, t2: s2 - s0, t3: s3 - s0 })
        }
    }

    componentDidMount() {
        setTimeout(() => {
            let options = {
                root: null,
                rootMargin: '0px',
                threshold: [0, 1]
            }

            const logo = document.querySelector(`.cui_hd_cont .ctriplogo a`)
            if (logo) {
                let observer = new IntersectionObserver((entries) => {
                    // logo外露出来就要上报埋点
                    const entry = entries[0]
                    if (entry && entry.intersectionRatio > 0) {
                        observer.disconnect()
                        UbtHandler.onUploadData('c_scroll_to_top', 1)
                    }
                }, options)

                observer.observe(logo)
            }
        }, 2000)

        regWindowEvent('beforeunload', this.uploadChannelPreSearchUbt)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.searchIsFinish && this.props.searchIsFinish !== prevProps.searchIsFinish) {
        performanceTrace.trace('renderFlights');
      }
    }

  render() {
        const { visibleFlights, isBuildUpMode, searchNoResult, onBook, sortContainsTax, currentSegmentSeq,
            onPopupFlightDetail, recommendVersion, highCabinRecommendMainTip,
            highCabinRecommendGroupTipList, highCabinHideOrShowFlightsHandlerIndex, highCabinExpandExtraRecFlights,
            isShowOwRecommendRt, isBestChoiceSort, filterV2, searchIsFinish, selectedFlightFixStatus, selectFlightItineryId,
            splitRoundFlightsSwitch, splitRound, containsTax, prevCond, fixSortBar, flightsMinHeight, updateTooFewFlights,
            ubtOriginFlight, splitRoundMaskFlightsSwitch, roundTabModeSwitch,
            activeRoundTabIndex, priceSortTypeGroupTitlesRef, visiblePriceSortTypeGroupsSize, adBarSwitch } = this.props


        LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS.value.process(this.bestChoiceFlightsUbtHandler, visibleFlights)
        return visibleFlights && visibleFlights.size ? (
            <Flights ubtOriginFlight={ubtOriginFlight} onPopupFlightDetail={onPopupFlightDetail} onBook={onBook}
                allVisibleFlights={visibleFlights}
                isBuildUpMode={isBuildUpMode} currentSegmentSeq={currentSegmentSeq}
                searchNoResult={searchNoResult} sortContainsTax={sortContainsTax}
                recommendVersion={recommendVersion}
                highCabinRecommendMainTip={highCabinRecommendMainTip}
                highCabinRecommendGroupTipList={highCabinRecommendGroupTipList}
                highCabinHideOrShowFlightsHandlerIndex={highCabinHideOrShowFlightsHandlerIndex}
                highCabinExpandExtraRecFlights={highCabinExpandExtraRecFlights}
                isShowOwRecommendRt={isShowOwRecommendRt}
                isBestChoiceSort={isBestChoiceSort} splitRound={splitRound}
                filterV2={filterV2} searchIsFinish={searchIsFinish} splitRoundFlightsSwitch={splitRoundFlightsSwitch}
                onToggleHighCabinExpandExtraRecFlights={this.onSetResumeIndex} containsTax={containsTax}
                prevCond={prevCond} selectedFlightFixStatus={selectedFlightFixStatus}
                selectFlightItineryId={selectFlightItineryId} fixSortBar={fixSortBar} splitRoundMaskFlightsSwitch={splitRoundMaskFlightsSwitch}
                flightsMinHeight={flightsMinHeight} updateTooFewFlights={updateTooFewFlights}
                roundTabModeSwitch={roundTabModeSwitch}
                activeRoundTabIndex={activeRoundTabIndex}
                priceSortTypeGroupTitlesRef={priceSortTypeGroupTitlesRef}
                visiblePriceSortTypeGroupsSize={visiblePriceSortTypeGroupsSize}
                adBarSwitch={adBarSwitch}
            />) : null
    }
}

const mapStateToProps = (state, props) => {
    const { splitRound } = props,
        isBestChoiceSort = false,
        highCabinRecommendMainTip = calcSplitRoundSelector(splitRound, getHighCabinRecommendMainTipSelector, state, true),
        highCabinRecommendGroupTipList = calcSplitRoundSelector(splitRound, getHighCabinRecommendGroupTipListSelector, state, true),
        highCabinHideOrShowFlightsHandlerIndex = calcSplitRoundSelector(splitRound, getHighCabinHideOrShowFlightsHandlerIndexSelector, state, true),
        highCabinExpandExtraRecFlights = calcSplitRoundSelector(splitRound, getHighCabinExpandExtraRecFlightsSelector, state, true)

    return {
        globalSwitch: globalSwitchSelector(state),
        splitRoundFlightsSwitch: false,
        recommendVersion: recommendVersionSelector(state),
        highCabinRecommendMainTip,
        highCabinRecommendGroupTipList,
        highCabinHideOrShowFlightsHandlerIndex,
        highCabinExpandExtraRecFlights,
        isShowOwRecommendRt: isShowOwRecommendRtSelector(state),
        isBestChoiceSort,
        containsTax: containsTaxSelector(state),
        prevCond: prevCondSelector(state),
        fixSplitFlights: getIfFixSplitFlightsSelector(state),
        selectedFlight: selectedFlightsSelector(state),
        splitRoundMaskFlightsSwitch: splitRoundMaskFlightsSwitchSelector(state),
        roundTabModeSwitch: getRoundTabModeSwitchSelector(state),
        activeRoundTabIndex: getActiveRoundTabIndexSelector(state),
        visiblePriceSortTypeGroupsSize: getVisiblePriceSortTypeGroupSiezeSelector(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    onToggleHighCabinExpandExtraRecFlights: () => dispatch(genToggleHighCabinExpandExtraRecFlights())
})

export default connect(mapStateToProps, mapDispatchToProps)(FlightsContainer)
