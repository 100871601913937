import React from 'react';

import { sendBlockTrace } from '@/src/utils/sendBlockTrace';
import { BlockTypeValue, InterruptTypeValue, MainTypeValue, SubTypeValue } from '@/src/utils/types';
import PopDialog from '../common/PopDialog';
import { UED } from './styleConst';
import POPUP_MSG from './popupMsg';
import { getIfScopeInternational } from '../../../sources/list/scope';
import styles from './superFlyerOrderPopup.module.scss';

/**
 * 超级飞人弹窗
 *
 */
const SuperFlyerOrderPopup = ({ ...props }) => {
	const isIntl = getIfScopeInternational();
	const popupConfig = isIntl ? POPUP_MSG.SUPER_FLYER_CONFIRM : POPUP_MSG.D_SUPER_FLYER_CONFIRM;

	const { isShow, back, continueOrder } = props;
	if (!isShow) {
		return null;
	}
	sendBlockTrace({
		blockType: BlockTypeValue.Pop,
		interruptType: InterruptTypeValue.Block,
		mainType:  MainTypeValue.Search,
		subType: SubTypeValue.DepartSoon,
	});
	const title = `<span class='bound'>距离起飞时间</span><span class='red'>不足2小时</span>`
	if (isIntl) {
		return (
			<PopDialog
				contents={
					<div className={`basic-alert ${styles.basicPop}`}>
						<div className={styles.title}>
							<div dangerouslySetInnerHTML={{
								__html: window.GlobalConfigs.superFlyPopTitleConfig ||  title
							}}></div>
						</div>
						<div className={styles.ulStyle}>
							<div className={styles.liStyle}>
								<div className={styles.roundStyle}></div>
								<span>{popupConfig.INFO}</span>
							</div>
							<div className={styles.liStyle}>
								<div className={styles.roundStyle}></div>
								<span>{popupConfig.HINT}</span>
							</div>
						</div>
						<div className="alert-footer">
							<div className="btn-group">
								<a href="javascript:;" data-testid={'cancel-button'} className={`${styles.btn}`} onClick={back}>
									{popupConfig.CANCEL_BUTTON}
								</a>
								<a href="javascript:;"
									data-testid={'confirm-button'}
									className={styles.ghotstBtn}
									onClick={continueOrder}
								>
									{popupConfig.CONFIRM_BUTTON}
								</a>
							</div>
						</div>
					</div>
				}
			/>
		);
	}
	return (
		<PopDialog
			contents={
				<div className="basic-alert" style={UED.popupMargin}>
					<i className="ico-warning-max" />
					<div className="alert-title warning">{popupConfig.TITLE || ''}</div>
					<div className="alert-body">
						{popupConfig.INFO && (
							<div className="alert-info" dangerouslySetInnerHTML={{ __html: popupConfig.INFO }}></div>
						)}
						{popupConfig.HINT && (
							<div className="alert-hint" dangerouslySetInnerHTML={{ __html: popupConfig.HINT }}></div>
						)}
					</div>
					<div className="alert-footer">
						<div className="btn-group">
							<a href="javascript:;" data-testid={'confirm-button'} className="btn" onClick={continueOrder}>
								{popupConfig.CONFIRM_BUTTON}
							</a>
							<a href="javascript:;" data-testid={'cancel-button'} className="btn btn-ghost" onClick={back}>
								{popupConfig.CANCEL_BUTTON}
							</a>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default SuperFlyerOrderPopup;
