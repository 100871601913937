export class DataRetriever {
    static getBasicDataFromPrevCond(prevCond, orderData) {

        let result = {
            FlightClass: "I",
            FlightWay: prevCond.flightWay,
            RealFlightWay: prevCond.flightWay,
            OrderId: orderData.orderId,
            PassengerType: {
                Adult: prevCond.adultCount,
                Children: prevCond.childCount,
                Infant: prevCond.infantCount
            },
            RealPassengerType: null,
            UID: orderData.userId
        }

        return result
    }
}