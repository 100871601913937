import React from 'react';

function DebugInfo({ info }) {
    let keys;
    let debugInfo;
    try {
        debugInfo = JSON.parse(info);
        keys = Object.keys(debugInfo); 
    } catch (error) {}
	if (!keys?.length) return null;
	return (
		<div className='debug-info-box'>
			{keys.map((key) => {
				return (
					<div key={key} className='debug-info'>
						<span>{key}: </span>
						<span>{decodeURIComponent(debugInfo[key])}</span>
					</div>
				);
			})}
		</div>
	);
}

export default DebugInfo;
