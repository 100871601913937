import React, { useRef } from 'react';
import Immutable from 'immutable';
import classnames from 'classnames';
import ReactToolTip from '@/src/components/Base/reactToolTip';
import { UbtBoundary, LIST_UBT_GROUP_TYPES } from '../../../../ubt.v2/list';
import DeferredContent from '../../common/deferredContent';
import { getPenaltyDetail } from '../../../../actions/list';
import { fetchInitXProductPreposeInfo } from '../../../../actions/list/xproduct';
import varStore from '../../../../constants/list/varStore';
import MembershipPopup from '../../../Base/membership';
import { tryExec } from '../../../../sources/common/tryExec';

export const genMinrefundfeeAndSign = (minRefundWrapper) => {
	let dataMinrefundfee = '',
		dataMinrefundfeesign = '',
		allGeneralCoupons = '';

	if (minRefundWrapper) {
		dataMinrefundfee = typeof minRefundWrapper.minRefundFee === 'undefined' ? '' : minRefundWrapper.minRefundFee;
		dataMinrefundfeesign =
			typeof minRefundWrapper.minRefundFeeSign === 'undefined' ? '' : minRefundWrapper.minRefundFeeSign;
		allGeneralCoupons =
			typeof minRefundWrapper.allGeneralCoupons === 'undefined' ? '' : minRefundWrapper.allGeneralCoupons;
	}

	return { dataMinrefundfee, dataMinrefundfeesign, allGeneralCoupons };
};

export const genPriceMiddleTags = ({
	price,
	getId,
	setMinRefundWrapper,
	allSegmentList,
	prevCond,
	mileagePointSwitch,
	splitRoundFlightsSwitch,
	splitRound,
	index,
}) => {
	const routeSearchToken = price.get('routeSearchToken');
	const airlineMemberToken = price.getIn(['extData', 'airlineMemberToken']);
	const flightWay = prevCond.get('flightWay');
	let penaltyCriteria = price.getIn(['penalty', 'penaltyCriteria']),
		priceTagEnums = price.get('priceTagEnums'),
		tagEnumsOfMiddle = priceTagEnums.filter((wrapper) => wrapper.tag.value.position.key === 'MIDDLE'),
		tagEnumsOfLeft = priceTagEnums.filter((wrapper) => wrapper.tag.value.position.key === 'LEFT') || Immutable.List(),
		theTodayFreeRefund =
			tagEnumsOfLeft && tagEnumsOfLeft.size && tagEnumsOfLeft.find((item) => item.tag.key === 'TodayFreeRefund'), // 是否有甄选服务
		hasTodayFreeRefund = theTodayFreeRefund ? theTodayFreeRefund.data : null,
		GVLimitationsEnums = price.get('GVLimitationsEnums'),
		getPenaltyDetailPromise = () =>
			getPenaltyDetail(
				allSegmentList,
				price,
				prevCond,
				penaltyCriteria,
				hasTodayFreeRefund,
				GVLimitationsEnums,
				mileagePointSwitch,
				splitRoundFlightsSwitch
			),
		generalCouponWrappers = tagEnumsOfMiddle.filter((theEnum) => theEnum.tag.value.showLabelByRefundFee), // 目前只有通用券需要根据退改签费用决定是否显示，所以要先发退改签查询接口
		nonGeneralCouponWrappers = tagEnumsOfMiddle.filter((theEnum) => !theEnum.tag.value.showLabelByRefundFee),
		genTagOfMiddleLabel = (wrapper, i, label, ubtTracker) => {
			if (label) {
				// 支持返回字符串，或者是 Element
				const labelIsString = typeof label === 'string';

				const key = wrapper.tag.key;
				const { getTooltip = () => {}, labelClassName = '', notHover } = wrapper.tag.value || {};

				const suspendDescribe = getTooltip && getTooltip(
					wrapper,
					prevCond,
					price,
					getId(key, i)
				);

				const labelElTemplate = (
					<span
						id={getId(key, i)}
						style={notHover || !suspendDescribe ? { cursor: 'default' } : null}
						className={classnames('tag', labelClassName)}
					/>
				);
				// 如果是字符串就直接渲染成html，如果是Element就作为children
				const labelEl = React.cloneElement(
					labelElTemplate,
					labelIsString ? { dangerouslySetInnerHTML: { __html: label } } : { children: label }
				);

				return (
					<React.Fragment key={getId(key, i)}>
						<UbtBoundary tracker={ubtTracker}>{labelEl}</UbtBoundary>
						<ReactToolTip
							splitRoundFlightsSwitch={splitRoundFlightsSwitch}
							splitRound={splitRound}
							parentId={getId(key, i)}
							key={getId(key, i)}
							toolTipContent={suspendDescribe}
						/>
					</React.Fragment>
				);
			} else {
				return null;
			}
		},
		getTagOfGeneralCoupon = (couponWrapper, theIndex) =>
			genTagOfMiddleLabel(couponWrapper, theIndex, couponWrapper.tag.value.getLabel(couponWrapper, prevCond));
	const nonGeneralTagsOfMiddle = nonGeneralCouponWrappers.map((wrapper) => {
		const lbName = wrapper.tag.value.getLabel(wrapper, prevCond),
			i = tagEnumsOfMiddle.indexOf(wrapper),
			lbKey = wrapper.tag.key,
			hideKeys = ['DiscountWrapper', 'RecommendTag', 'BookAndDiscount', 'BookAlreadyDiscounted']; // 在展开运价隐藏的tag

		if (hideKeys.includes(lbKey)) {
			return null;
		}

		let ubtTracker = null;
		if (wrapper.tag.value.getUbtTracker) {
			ubtTracker = wrapper.tag.value.getUbtTracker({
				name: lbName,
			});
		}

		return genTagOfMiddleLabel(wrapper, i, lbName, ubtTracker);
	});

	const generalTagsOfMiddle = generalCouponWrappers.size ? (
		<DeferredContent
			delayGetPromise={true}
			getPromise={getPenaltyDetailPromise}
			then={(_, ext) => {
				setMinRefundWrapper({
					minRefundFee: ext.minRefundFee,
					minRefundFeeSign: ext.minRefundFeeSign,
					allGeneralCoupons: tryExec(() => JSON.stringify(generalCouponWrappers.toJS()), ''),
				});

				const generalTagsData = generalCouponWrappers.filter(
					(item) =>
						(typeof ext.minRefundFee !== 'undefined' && item.data.refundFeeThreshold < ext.minRefundFee) ||
						item.data.refundFeeThreshold === 0
				); //阀值 小于 退票费 的通用券，没找到就看是否有阀值为0的

				return generalTagsData.map((tag) => getTagOfGeneralCoupon(tag, tagEnumsOfMiddle.indexOf(tag)));
			}}
		/>
	) : null;

	//亚航超级会员..
	const membershipTagsOfMiddle = (() => {
		if (!window.GlobalSwitches.airAsiaMembershipSwitch || window.GlobalSwitches.aggPreposeTokenSwitch) {
			return null;
		}

		const isBlackDiamondUser = window.GlobalConfigs.isBlackDiamondUser;
		const isDiamondUser = window.GlobalConfigs.isDiamondUser;
		const isSuperVipUser = window.GlobalConfigs.isSuperVipUser;
		if (!isBlackDiamondUser && !isDiamondUser && !isSuperVipUser) {
			return null;
		}

		if (price.get('priceUnitList').size >= 2) {
			return null;
		}

		if (flightWay != 'S' && flightWay != 'D') {
			return null;
		}
		if (!LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.AirVipTag) {
			LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.AirVipTag = {
				isshow: 0,
				tagtype: '',
				ischoose: 0,
				isshowlayer: 0,
			};
		}
		const ubtAirVipTag = LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.AirVipTag;
		const indexVal = useRef(index);
		if (index !== indexVal.current) {
			indexVal.current = index;
		}
		ubtAirVipTag.isshow = 1;
		ubtAirVipTag.tagtype = '超级会员标签';
		if (isDiamondUser) {
			ubtAirVipTag.tagtype = '钻石会员标签';
		}
		if (isBlackDiamondUser) {
			ubtAirVipTag.tagtype = '黑钻会员标签';
		}
		return (
			<DeferredContent
				delayGetPromise={true}
				getPromise={() => fetchInitXProductPreposeInfo(routeSearchToken, prevCond, airlineMemberToken)}
				then={(preposeResult) => {
					if (!preposeResult || !preposeResult.policyToken) {
						return null;
					}
					varStore.PriceXProductPreposeInfoMap[preposeResult.routeSearchToken].isShowFlag = true;
					varStore.PriceXProductPreposeInfoMap[preposeResult.routeSearchToken].policyToken =
						preposeResult.policyToken;
					return (
						<React.Fragment>
							<span className="tag" id={`divMembership_${indexVal.current}`}>
								<div>{preposeResult.tagName}</div>
							</span>
							<ReactToolTip
								key={`divMembership_${indexVal.current}`}
								parentId={`divMembership_${indexVal.current}`}
								toolTipContent={<MembershipPopup preposeResult={preposeResult} />}
								callbackFunc={() => {
									ubtAirVipTag.isshowlayer = 1;
								}}
							/>
						</React.Fragment>
					);
				}}
			/>
		);
	})();

	return {
		nonGeneralTagsOfMiddle, //非通用券
		generalTagsOfMiddle, //通用券
		membershipTagsOfMiddle,
	};
};
