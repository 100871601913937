import React from 'react';
import ErrorBoundary from '@/src/components/Base/ErrorBoundary';

import HeaderContainer from '../../../containers/list/result/header/async';
import FilterBarContainer from '../../../containers/list/sidebar/filterbar/async';

export const Sidebar = (props) => {
	const { roundTabModeSwitch } = props;

	return (
		<React.Fragment>
			<React.Fragment>
				<ErrorBoundary>{!roundTabModeSwitch && <HeaderContainer />}</ErrorBoundary>

				<ErrorBoundary>
					<FilterBarContainer {...props} />
				</ErrorBoundary>
			</React.Fragment>
		</React.Fragment>
	);
};
