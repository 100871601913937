import React from 'react'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../ubt.v2/list';
import { ExposeStat } from '../../ubt.v2/exposeStat'

export const getHighCabinRecMainTip = (key, tip) => {
    return (
        <ExposeStat ubtKey='c_data_FC_Rec_YS'>
            <div key={key} className="no-result">
                <i className="ico-nodirect"></i>
                <div className="title">{tip}</div>
            </div>
        </ExposeStat>
    )
}

export const getHighCabinRecGroupTip = (iconClassName, cabinTitle) => {
    return (
        <div key='high_cabin_rec_group' className="otherline-title">
            <i className={iconClassName}></i>
            <div className="title">{cabinTitle}</div>
        </div>
    )
}

export const getHighCabinShowHideExtraFlightsTip = (key, directFlight, expand, onClick) => {
    return (
        <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW, LIST_UBT_KEY_TYPES.HIGH_CABIN_REC_EXPAND, expand)}>
            <div key={key} className='more-result'>
                <a href="javascript:;" className="trigger" onClick={onClick}>
                    {expand ? '收起' : `查看更多${directFlight ? '直飞' : ''}航班`}
                    <i className={expand ? 'arrow-up-bold' : 'arrow-down-bold'}></i>
                </a>
            </div>
        </UbtBoundary>
    )
}
