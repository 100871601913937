import Enum from 'enum'
import { UBT_TRIGGER_TYPE } from '../../common'
import { getPrevCondFromGlobal, getOrderDataFromGlobal } from '../../../actions/common'
import { DataRetriever } from '../common/dataRetriver'

const COMMON_CLICK_GROUP = 'flt_oversea_input_click_v2',
    BASIC_DATA_FLOW_GROUP = 'flt_oversea_ordering_input_online_basic_v2',
    SEND_NOW_COMMON_CLICK = 'c_click',
    SEND_NOW_LOAD = 'c_load',
    SEND_NOW_HOVER = 'c_hover',
    CLASS_LEVEL_UP = '',
    PROMPT_MESSAGE_PAGE_TOP = 'c_prompt_message_notice_top',
    PROMPT_MESSAGE_USUAL_PASSENGERS_TOP = 'c_prompt_message_usual_passengers_top',
    PROMPT_MESSAGE_RAW_DATA = {
        __skip__: true,
        formatData: data => {
            return Object.keys(data).filter(key => key !== 'formatData')[0]
        }
    }

const RAW_COMMON_CLICK = {},
    prevCond = getPrevCondFromGlobal(),
    orderData = getOrderDataFromGlobal(),
    RAW_BASIC_DATA_FLOW = DataRetriever.getBasicDataFromPrevCond(prevCond, orderData) //离开页面上报

export const BOOKING_UBT_GROUP_TYPES = new Enum({
    COMMON_CLICK: {
        //基础点击埋点
        group: COMMON_CLICK_GROUP,
        trigger: UBT_TRIGGER_TYPE.UNLOAD,
        rawData: RAW_COMMON_CLICK
    },
    BASIC_DATA_FLOW: {
        //基础上报业务数据
        group: BASIC_DATA_FLOW_GROUP,
        trigger: UBT_TRIGGER_TYPE.UNLOAD,
        rawData: RAW_BASIC_DATA_FLOW
    },
    SEND_NOW_CLICK: {
        //即时上报点击
        group: SEND_NOW_COMMON_CLICK,
        trigger: UBT_TRIGGER_TYPE.NOW,
        rawData: {}
    },
    SEND_NOW_LOAD: {
        //即时上报load
        group: SEND_NOW_LOAD,
        trigger: UBT_TRIGGER_TYPE.NOW,
        rawData: {}
    },
    SEND_NOW_HOVER: {
        //即时上报hover
        group: SEND_NOW_HOVER,
        trigger: UBT_TRIGGER_TYPE.NOW,
        rawData: {}
    },
    CLASS_LEVEL_UP: {
        group: CLASS_LEVEL_UP,
        trigger: UBT_TRIGGER_TYPE.NOW,
        rawData: {}
    },
    PROMPT_MESSAGE_PAGE_TOP: {
        group: PROMPT_MESSAGE_PAGE_TOP,
        trigger: UBT_TRIGGER_TYPE.UNLOAD,
        rawData: Object.assign({}, PROMPT_MESSAGE_RAW_DATA)
    },
    PROMPT_MESSAGE_USUAL_PASSENGERS_TOP: {
        group: PROMPT_MESSAGE_USUAL_PASSENGERS_TOP,
        trigger: UBT_TRIGGER_TYPE.UNLOAD,
        rawData: Object.assign({}, PROMPT_MESSAGE_RAW_DATA)
    }
})
