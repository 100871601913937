import { createSelector } from 'reselect'
import moment from 'moment'
import { prevCondSelector } from '../flight/baseSelector'
import { getIsDepartCountryDomestic } from '../../../../utils/commonUtils'
import { formatWeek } from '../../../../utils/dateHelper'
import DateHelper from '../../../../sources/common/dateUtil'
import OwRecommendRtConfig from '../../../../constants/list/owRecommendRtConfig'
import { CALENDAR_WORK_STATUS } from '../../../../constants/channel/enum'


export const lowRPriceSelector = (state) => state.getIn(['list', 'search', 'lowRPrice'])
export const lowestRPriceSelector = (state) => state.getIn(['list', 'search', 'lowestRPrice'])
export const owRecommendRtSelector = (state) => {
    return state.getIn(['list', 'recommend', 'owRecommendRt'])
}
export const workingDaysSelector = (state) => state.getIn(['list', 'common', 'workingDays'])
export const owRecommendRtSwitchV2Selector = (state) => state.getIn(['list', 'recommend', 'owRecommendRt', 'recommendVersion'])
export const owRecommendRtUserAttrSelector = (state) => state.getIn(['list', 'recommend', 'owRecommendRt', 'userAttr'])


export const isShowOwRecommendRtSelector = createSelector([prevCondSelector],
    (prevCond) => {
        return prevCond.get("flightWay") === 'S'
    }
)

const generateLowPriceItem = (departDate, lowPriceData, aDays, workingDays) => {
    let priceItems = [],
        lowestPrice = '',
        // holidays = this.props.holidays,
        // workingDays = this.props.workingDays,
        lowPriceItemCount = 0
    for (let i = 0; i < 7; i++) {
        let depStr = moment(departDate),
            endStr = moment(departDate).add(i + aDays, 'day'),
            price = lowPriceData[`${depStr.format('YYYY-MM-DD')}-${endStr.format('YYYY-MM-DD')}`],
            holiday = DateHelper.getCalendarTextWithImmutable(moment.utc(endStr.format('YYYY-MM-DD'))),
            restOrWork = workingDays.get(endStr.format('YYYY-MM-DD')),
            restOrWorkStr = '',
            holidayStr = ''
        if (restOrWork && restOrWork.get("stat") === 3) {
            restOrWorkStr = '休'
        } else if (restOrWork && restOrWork.get("stat") === 4) {
            restOrWorkStr = '班'
        }
        if (i == 0 || !/\d+/.test(lowestPrice) || price < lowestPrice) {
            lowestPrice = price
        }
        if (holiday.get("workStatus") == CALENDAR_WORK_STATUS.NOT_WORK) {
            holidayStr = holiday.get("holidayText")
        }
        if (price) {
            lowPriceItemCount++
        }
        priceItems.push({
            departDate: depStr,
            endDate: endStr,
            lowPrice: price || '--',
            departWeek: formatWeek(depStr),
            endWeek: holidayStr || formatWeek(endStr),
            restOrWork: restOrWorkStr
        })
    }
    return { priceItems, lowestPrice, lowPriceItemCount }
}
const getConfigureAirline = (prevCond) => {
    let flight = prevCond.getIn(["flightSegments", 0]),
        dCountryId = flight.get("departureCountryId"),
        aCountryId = flight.get("arrivalCountryId"),
        dCountryName = flight.get("departureCountryName"),
        dProvinceId = flight.get("departureProvinceId"),
        aProvinceId = flight.get("arrivalProvinceId"),
        isConfigureAirline = false,
        aDays = OwRecommendRtConfig.defaultADays
    // 港澳台需要处理下

    if (dCountryName == "中国" && getIsDepartCountryDomestic(flight)) {
        isConfigureAirline = true
    }
    for (var i = 0; i < OwRecommendRtConfig.airlines.length; i++) {
        let airline = OwRecommendRtConfig.airlines[i]
        if (airline.dCountryId && airline.dProvinceId && airline.aCountryId && !airline.aProvinceId) {
            //省到国家
            if (airline.dCountryId == dCountryId && airline.dProvinceId == dProvinceId &&
                airline.aCountryId == aCountryId && (airline.aDays || airline.aDays == 0)) {
                aDays = airline.aDays
            }

        } else if (airline.dCountryId && airline.dProvinceId && airline.aCountryId && airline.aProvinceId) {
            //省到省
            if (airline.dCountryId == dCountryId && airline.dProvinceId == dProvinceId &&
                airline.aCountryId == aCountryId && airline.aProvinceId == aProvinceId && (airline.aDays || airline.aDays == 0)) {
                aDays = airline.aDays
            }
        } else if (airline.dCountryId && !airline.dProvinceId && airline.aCountryId && airline.aProvinceId) {
            // 国家到省
            if (airline.dCountryId == dCountryId &&
                airline.aCountryId == aCountryId && airline.aProvinceId == aProvinceId && (airline.aDays || airline.aDays == 0)) {
                aDays = airline.aDays
            }

        } else if (airline.dCountryId && !airline.dProvinceId && airline.aCountryId && !airline.aProvinceId) {
            //国家到国家
            if (airline.dCountryId == dCountryId &&
                airline.aCountryId == aCountryId && (airline.aDays || airline.aDays == 0)) {
                aDays = airline.aDays
            }

        }
    }
    return {
        aDays,
        isConfigureAirline
    }
}
export const priceItemsSelector = createSelector([owRecommendRtSelector, prevCondSelector, workingDaysSelector], (owRecommendRt, prevCond, workingDays) => {
    let flightSegments = prevCond.get('flightSegments'),
        departDate = flightSegments.getIn([0, 'departureDate']),
        lowPriceData = owRecommendRt.get('lowPriceData'),
        { aDays, isConfigureAirline } = getConfigureAirline(prevCond)
    let { priceItems, lowestPrice, lowPriceItemCount } = lowPriceData ? generateLowPriceItem(departDate, lowPriceData, aDays, workingDays) : { priceItems: [], lowestPrice: null, lowPriceItemCount: 0 }
    return { aDays, isConfigureAirline, priceItems, lowestPrice, lowPriceItemCount }
})

