import { splitString } from 'x-js-kit/lib/common/string';
import { sendTrace } from '@/src/sources/common/lightUbt';

import { UbtHandler } from '../../handler';
import { UBT_EVENT_TYPE } from '../../common';
import { BOOKING_UBT_GROUP_TYPES } from './groupTypes';
import { BOOKING_UBT_KEY_TYPES } from './keyTypes';

const customEvent = {}; // 自定义用户事件

const regCustomEvent = (eventName, callback) => {
	if (eventName && callback && typeof callback === 'function') {
		customEvent[eventName] = callback;
	}
};

export const invokeCustomEvent = (eventName, args) => {
	let callback = customEvent[eventName];
	if (callback && typeof callback === 'function') {
		callback(args);
	}
};

const groupList = [
	BOOKING_UBT_GROUP_TYPES.BASIC_DATA_FLOW,
	BOOKING_UBT_GROUP_TYPES.PROMPT_MESSAGE_PAGE_TOP,
	BOOKING_UBT_GROUP_TYPES.PROMPT_MESSAGE_USUAL_PASSENGERS_TOP,
]; // 已注册的 BOOKING_UBT_GROUP_TYPES 列表，靠自定义方式收集的group需要自己添加

Object.keys(BOOKING_UBT_KEY_TYPES.toJSON()).forEach((key) => {
	let typeEnum = BOOKING_UBT_KEY_TYPES.get(key);
	if (typeEnum) {
		let typeEnumValue = typeEnum.value;

		if (typeEnumValue.listen === UBT_EVENT_TYPE.CUSTOM) {
			let eventName = key;

			regCustomEvent(eventName, (ev) => {
				typeEnumValue.process(eventName, null, ev);
			});
		}
	}
});

const regGroup = (group) => {
	if (groupList.includes(group) === false) {
		groupList.push(group);
	}
};

/**
 * 注册页面离开事件、初始化自定义事件
 */
export const initUbt = () => {
	window.addEventListener('beforeunload', (_ev) => {
		groupList.forEach((group) => {
			group.value.trigger.value.beforeUnload(group);
		});
		splitString(document.getElementsByTagName('body')[0].innerText, 500).forEach((val, idx) => {
			sendTrace(`c_text_s1_${idx}`, val);
		});
	});
};

export class CommonUbtHandler extends UbtHandler {
	constructor(groupEnum, keyEnum, tag) {
		super();

		this.groupEnum = groupEnum;
		this.keyEnum = keyEnum;
		this.rawData = this.groupEnum.value.rawData;
		this.tag = tag;

		regGroup(groupEnum);
	}

	onEvent(eventType, source, ev) {
		this.onProcess(eventType, source, ev);
	}

	onProcess(eventType, source, ev) {
		let preventAfterProcess = this.keyEnum.value.process(this, source, ev);
		// 会有些奇葩场景，比如process中动态修改key直接上报，就不用再走常规 afterProcess 了
		if (preventAfterProcess !== false) {
			this.groupEnum.value.trigger.value.afterProcess(this.groupEnum);
		}
	}
}

export const BookingUbtGenerator = (groupEnum, keyEnum, ...tag) => {
	return new CommonUbtHandler(groupEnum, keyEnum, tag);
};
