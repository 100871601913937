import { scrollTo } from 'scroll-js'

// 列表页自动滚屏到搜索框
export const autoScrollToSearchBox = () => {
    // const LAST_BEFORE_UNLOAD_DATE = 'lastUnloadPageDate'
    // const datetime = sessionStorage.getItem(LAST_BEFORE_UNLOAD_DATE)
    const TARGET_SCROLL_Y = 194
    const doScroll = function () {
        let options = {
            root: null,
            rootMargin: '0px',
            threshold: [0, 1]
        }

        const navFlight = document.querySelector(`#ul_nav_flight`)
        if (navFlight) {
            let observer = new IntersectionObserver((entries) => {
                // 顶部机票子菜单外露出来就要往下滚屏
                const entry = entries[0]
                if (entry && entry.intersectionRatio > 0) {
                    document.body.style.overflowAnchor = 'none'
                    if ('scrollRestoration' in history) {
                        history.scrollRestoration = 'manual'
                    }

                    setTimeout(() => {
                        scrollTo(window, {
                            top: TARGET_SCROLL_Y,
                            duration: 50,
                            behavior: 'smooth'
                        })

                        observer.disconnect()
                    }, 0)
                }
            }, options)

            observer.observe(navFlight)
        }
    }

    // if (datetime) {
    //     const delta = (new Date()) - new Date(+datetime)

    //     if (delta >= 3000) {
    //         doScroll()
    //     }
    // } else {
    //     doScroll()
    // }

    doScroll()

    // window.addEventListener('beforeunload', function () {
    //     sessionStorage.setItem(LAST_BEFORE_UNLOAD_DATE, (+new Date()))
    // })
}
