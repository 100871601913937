import { createSelector } from 'reselect';
import Immutable from 'immutable';
import moment from 'moment';
import DateUtil from '../../../../sources/common/dateUtil';
import { CALENDAR_WORK_STATUS } from '../../../../constants/channel/enum';
import CONSTS from '../../../../constants/list/constConfig';
import { containsTaxSelector, prevCondFlightWaySelector } from '../../result/flight/baseSelector.js';
import { getTheMinPriceOfAllVisibleFlightsSelector } from '../../sidebar/filterbar/filterbarSelector';
import { addTimezone } from '../../../../utils/dateHelper';

const getSearchCriteria = (state) => state.getIn(['list', 'search', 'prevCond']); // 搜索条件
const getOriginLowPriceData = (state) => state.getIn(['list', 'calendar_lowprice', 'lowPriceDatas']);

// 低价日历上显示的最低价需要和列表页可见航班的最低价一致
// 由于通过筛选，或者低价数据不准确等原因，需要把列表页外露的最低价更新到日历上
const getLowPriceData = createSelector(
	[getOriginLowPriceData, getTheMinPriceOfAllVisibleFlightsSelector, getSearchCriteria, prevCondFlightWaySelector],
	(lowPriceDatas, theMinPrice, searchCriteria, flightWay) => {
		if (theMinPrice.get('totalPrice') != Infinity) {
			let firstSegmentDepartureDate = searchCriteria.getIn(['flightSegments', 0, 'departureDate']);

			if (flightWay == 'S') {
				// {2020-11-12:{price: 350, totalPrice: 350}}
				lowPriceDatas = lowPriceDatas.setIn([firstSegmentDepartureDate], theMinPrice);
			} else if (flightWay == 'D') {
				// {2020-11-12:{0:{price: 350, totalPrice: 350}, 1: {price: 350, totalPrice: 350}}}
				let secondSegmentDepartureDate = searchCriteria.getIn(['flightSegments', 1, 'departureDate']),
					diffDays = moment(secondSegmentDepartureDate).diff(firstSegmentDepartureDate, 'days');
				lowPriceDatas = lowPriceDatas.setIn([firstSegmentDepartureDate, diffDays - 1], theMinPrice);
			}
		}

		return lowPriceDatas;
	}
);
const getCurrentStart = (state) => state.getIn(['list', 'calendar_lowprice', 'currentStart']); // 展开前日历滑动起点（以tab个数计算）
const getWorkingDays = (state) => state.getIn(['list', 'common', 'workingDays']); // 工作日数据（从去哪儿节假日接口）
const getCurrentMonth = (state) => state.getIn(['list', 'calendar_lowprice', 'currentMonthIndex']); // 月视图翻页起点（以月份计算）
const getDiffDays = (state) => state.getIn(['list', 'calendar_lowprice', 'roundDiffDays']); // 往返时间差（只用于展开后下拉框切换，按天数计算）
const getCurrentWeek = (state) => state.getIn(['list', 'calendar_lowprice', 'currentWeekIndex']); // 周视图翻页起点（以天计算）

// 低价日历显示日期的数目：统一是显示7个
const DAY_COUNT = 7;

// 获取展开前滑动日历最低价数据（日期）
export const getNormalLowPrice = createSelector(
	[getLowPriceData, containsTaxSelector, getCurrentStart, getSearchCriteria, prevCondFlightWaySelector],
	(lowPriceDatas, taxIncluded, currentStart, searchCriteria, flightWay) => {
		const isRT = flightWay === 'D';

		// 计算当前展开七天最低价
		let lowestPriceDate = [];
		let lowestPrice = -1;
		if (lowPriceDatas && lowPriceDatas.size !== 0) {
			let diffDays = isRT
				? moment(searchCriteria.getIn(['flightSegments', 1, 'departureDate'])).diff(
						searchCriteria.getIn(['flightSegments', 0, 'departureDate']),
						'days'
				  )
				: 0;
			for (let i = 0; i < DAY_COUNT; i++) {
				let items = [],
					item = null;
				if (isRT) {
					items = lowPriceDatas.get(
						moment()
							.add(currentStart + i, 'days')
							.format('YYYY-MM-DD')
					);
					item = items && items.size > 0 ? items.get(diffDays - 1) : null;
				} else {
					item = lowPriceDatas.get(
						moment()
							.add(currentStart + i, 'days')
							.format('YYYY-MM-DD')
					);
				}
				// 计算最低价，并把日期保存在数组中
				if (item && item.size !== 0) {
					let priceNow = taxIncluded ? item.get('totalPrice') : item.get('price');
					if (priceNow > 0 && ((lowestPrice > 0 && priceNow < lowestPrice) || lowestPrice < 0)) {
						lowestPrice = priceNow;
						lowestPriceDate = [];
						lowestPriceDate.push(
							moment()
								.add(currentStart + i, 'days')
								.format('YYYY-MM-DD')
						);
					} else if (lowestPrice === priceNow) {
						lowestPriceDate.push(
							moment()
								.add(currentStart + i, 'days')
								.format('YYYY-MM-DD')
						);
					}
				}
			}
		}
		return lowestPriceDate;
	}
);

// 获取展开前滑动所用数据
export const getCalendarLPData = createSelector(
	[getLowPriceData, containsTaxSelector, getCurrentStart, getSearchCriteria, prevCondFlightWaySelector],
	(lowPriceDatas, taxIncluded, currentStart, searchCriteria, flightWay) => {
		if (lowPriceDatas) {
			let result = [];
			const isOW = flightWay === 'S';
			const isRT = flightWay === 'D';

			if (isOW || (isRT && searchCriteria.get('flightSegments').size == 2)) {
				let diffDays = isRT
					? moment(searchCriteria.getIn(['flightSegments', 1, 'departureDate'])).diff(
							searchCriteria.getIn(['flightSegments', 0, 'departureDate']),
							'days'
					  )
					: 0;
				const firstDepartDate = searchCriteria.getIn(['flightSegments', 0, 'departureDate']);
				const firstDepartDateTimeZone = searchCriteria.getIn(['flightSegments', 0, 'departureCityTimeZone']);
				const getUtcDayOfFirstDepartDay = () => addTimezone(moment(), firstDepartDateTimeZone);
				for (let i = 0; i < DAY_COUNT; i++) {
					let key = getUtcDayOfFirstDepartDay()
							.add(i + currentStart, 'days')
							.format('YYYY-MM-DD'),
						items = [],
						item = null;

					// 单程往返取数据存着差别
					if (isRT) {
						items = lowPriceDatas.get(key);
						item = items && items.size > 0 ? items.get(diffDays - 1) : null;
					} else {
						item = lowPriceDatas.get(key);
					}
					let tab = {};
					// 获取假日数据，有假期显示假期名，没有则显示周几
					let calendar1 = DateUtil.getCalendarTextWithImmutable(moment.utc(key));
					if (calendar1.get('workStatus') == CALENDAR_WORK_STATUS.NOT_WORK) {
						tab.week = calendar1.get('holidayText');
						tab.holiday = true;
					} else {
						tab.week = `周${'日一二三四五六'.charAt(moment(key).day())}`;
					}
					tab.date = key;
					if (isRT) {
						// 往返有返程日期
						let calendar2 = DateUtil.getCalendarTextWithImmutable(moment.utc(key).add(diffDays, 'days'));
						if (calendar2.get('workStatus') == CALENDAR_WORK_STATUS.NOT_WORK) {
							tab.week2 = calendar2.get('holidayText');
							tab.holiday2 = true;
						} else {
							tab.week2 = `周${'日一二三四五六'.charAt(moment(key).add(diffDays, 'days').day())}`;
						}
						tab.date2 = moment(key).add(diffDays, 'days').format('YYYY-MM-DD');
					}
					// 如果是今天则高亮
					if (key === firstDepartDate) {
						tab.active = true;
					}
					// 显示价格，如果没价格则显示“查看”，如果有价格字段但价格不在，则为列表页无结果回填
					if (item && item.size !== 0) {
						// 处理低价
						tab.price = taxIncluded ? item.get('totalPrice') : item.get('price');
						if (!tab.price) {
							tab.priceGone = true;
						}
					} else {
						tab.noPrice = true;
					}
					result.push(Immutable.fromJS(tab));
				}

				if (result.length) {
					const activeIndex = moment(firstDepartDate).diff(getUtcDayOfFirstDepartDay(), 'days');
					result[0] = result[0].set('activeItemIndex', activeIndex);
				}
			}
			return Immutable.fromJS(result);
		} else {
			return null;
		}
	}
);

// 获取低价日历数据
export const getCalendarLowPriceData = createSelector(
	[getLowPriceData, containsTaxSelector, prevCondFlightWaySelector],
	(lowPriceDatas, taxIncluded, flightWay) => {
		if (lowPriceDatas) {
			let result = [],
				isOW = flightWay === 'S',
				countDat = 113;

			if (isOW) {
				for (let i = 0; i < countDat; i++) {
					let key = moment().add(i, 'days').format('YYYY-MM-DD'),
						item = null;
					// 单程往返取数据存着差别
					item = lowPriceDatas.get(key);
					let tab = {};
					// 获取假日数据，有假期显示假期名，没有则显示周几
					tab.week = `周${'日一二三四五六'.charAt(moment(key).day())}`;
					tab.date = key;
					// 显示价格，如果没价格则显示“查看”，如果有价格字段但价格不在，则为列表页无结果回填
					if (item && item.size !== 0) {
						tab.price = taxIncluded ? item.get('totalPrice') : item.get('price');
						if (tab.price) {
							result.push(Immutable.fromJS(tab));
						}
					}
				}
			}
			return Immutable.fromJS(result);
		} else {
			return null;
		}
	}
);

const getLowestMonthPrice = (
	lowPriceDatas,
	searchCriteria,
	currentMonthIndex,
	firstStartDay,
	taxIncluded,
	diffDays,
	addMonths
) => {
	// firstStartDay是考虑第一页时，本月不一定是从第一天开始。 addMonths是第一天第二个月计算时要比当前月份增加一个月
	let lowestPriceDate = [],
		lowestPrice = -1,
		thisMonth = moment().add(currentMonthIndex, 'months').month() + 1,
		thisYear = moment().add(currentMonthIndex, 'months').year();
	for (let i = 0; ; i++) {
		if (
			moment([thisYear, thisMonth - 1, firstStartDay])
				.add(addMonths, 'months')
				.add(i, 'days')
				.diff(moment([thisYear, thisMonth - 1, 1]).add(1 + addMonths, 'months'), 'days') >= 0
		) {
			break;
		}
		let items = [],
			item = null;
		if (searchCriteria.get('flightWay') === 'D') {
			// 往返时每个日期对应的低价数据是一个以间隔时间为key的对象（0代表间隔1天）
			items = lowPriceDatas.get(
				moment([thisYear, thisMonth - 1, firstStartDay])
					.add(addMonths, 'months')
					.add(i, 'days')
					.format('YYYY-MM-DD')
			);
			item = items && items.size > 0 ? items.get(diffDays - 1) : null;
		} else {
			item = lowPriceDatas.get(
				moment([thisYear, thisMonth - 1, firstStartDay])
					.add(addMonths, 'months')
					.add(i, 'days')
					.format('YYYY-MM-DD')
			);
		}
		if (item && item.size !== 0) {
			let priceNow = taxIncluded ? item.get('totalPrice') : item.get('price');
			if (priceNow > 0 && ((lowestPrice > 0 && priceNow < lowestPrice) || lowestPrice < 0)) {
				lowestPrice = priceNow;
				lowestPriceDate = [];
				lowestPriceDate.push(
					moment([thisYear, thisMonth - 1, firstStartDay])
						.add(addMonths, 'months')
						.add(i, 'days')
						.format('YYYY-MM-DD')
				);
			} else if (lowestPrice === priceNow) {
				lowestPriceDate.push(
					moment([thisYear, thisMonth - 1, firstStartDay])
						.add(addMonths, 'months')
						.add(i, 'days')
						.format('YYYY-MM-DD')
				);
			}
		}
	}
	return lowestPriceDate;
};

// 往返月视图正常日期展示
const getMonthNormalButton = (
	button,
	taxIncluded,
	diffDays,
	key,
	searchCriteria,
	lowPriceDatas,
	workingDays,
	lowestPriceDate
) => {
	let items = [],
		item = null,
		workingInfo = workingDays.get(key);
	if (searchCriteria.get('flightWay') === 'D') {
		items = lowPriceDatas.get(key);
		item = items && items.size > 0 ? items.get(diffDays - 1) : null;
	} else {
		item = lowPriceDatas.get(key);
	}
	if (workingInfo && workingInfo.get('stat') === 3) {
		button.holiday = true;
		button.rest = true;
		button.holidayName = '休';
	} else if (workingInfo && workingInfo.get('stat') === 4) {
		button.holiday = true;
		button.rest = false;
		button.holidayName = '班';
	}
	let calendar = DateUtil.getCalendarTextWithImmutable(moment.utc(key));
	if (calendar.get('workStatus') == CALENDAR_WORK_STATUS.NOT_WORK) {
		button.holidayName = calendar.get('holidayText');
		button.rest = true;
		button.holiday = true;
	}
	if (key === searchCriteria.getIn(['flightSegments', 0, 'departureDate'])) {
		button.active = true;
	}
	if (item && item.size !== 0) {
		button.price = taxIncluded ? item.get('totalPrice') : item.get('price');
		if (!button.price) {
			button.priceGone = true;
		}
		if (lowestPriceDate.indexOf(key) >= 0 && !button.priceGone) {
			button.lowPrice = true;
		}
	} else {
		button.noPrice = true;
	}
	return button;
};
// 往返月视图正常日期展示
const getLowestAndHighest = (taxIncluded, diffDays, searchCriteria, lowPriceDatas) => {
	let items = [],
		item = null,
		lowestPrice = -1,
		hightestPrice = -1;
	for (let i = 0; moment().add(i, 'days').diff(moment().add(1, 'years'), 'days') < 0; i++) {
		let key = moment().add(i, 'days').format('YYYY-MM-DD');
		if (searchCriteria.get('flightWay') === 'D') {
			items = lowPriceDatas.get(key);
			item = items && items.size > 0 ? items.get(diffDays - 1) : null;
		} else {
			item = lowPriceDatas.get(key);
		}
		if (item && item.size !== 0) {
			let price = taxIncluded ? item.get('totalPrice') : item.get('price');
			if (lowestPrice > price || lowestPrice == -1) {
				lowestPrice = price;
			}
			if (hightestPrice < price) {
				hightestPrice = price;
			}
		}
	}
	return {
		lowestPrice,
		hightestPrice,
	};
};

// 单程往返月视图通用selector
export const getMonthsCalendar = createSelector(
	// 其实就是按行列遍历
	[getLowPriceData, containsTaxSelector, getSearchCriteria, getWorkingDays, getCurrentMonth, getDiffDays],
	(lowPriceDatas, taxIncluded, searchCriteria, workingDays, currentMonthIndex, diffDays) => {
		if (lowPriceDatas && lowPriceDatas.size !== 0) {
			let result = {},
				lowestPriceDate = [],
				thisMonth = moment().add(currentMonthIndex, 'months').month() + 1,
				thisYear = moment().add(currentMonthIndex, 'months').year(),
				firstStartDay = 1,
				firstMonthRows = 6;
			// 第一个月要特殊判断，把这个月的一半和下个月的一半露出来，所以两个月要分开计算
			if (currentMonthIndex === 0) {
				// 该显示的第一天从当月第几天开始（这个月可能已经过去一些日子了）
				// firstStartDay = moment().date()
				// 该显示的第一个月有几行
				// firstMonthRows = Math.ceil((moment().day() + moment([moment().year(), moment().month(), 1]).add(1, "months").diff(moment(moment().format("YYYY-MM-DD")), "days")) / dayCount)
			}
			// 计算当月最低价
			lowestPriceDate = getLowestMonthPrice(
				lowPriceDatas,
				searchCriteria,
				currentMonthIndex,
				firstStartDay,
				taxIncluded,
				diffDays,
				0
			);
			// 这里计算当月第一天前面周几个空格
			let headEmptyButtons = moment([thisYear, thisMonth - 1, firstStartDay]).day();
			// 循环到应当显示的第一个月结束
			for (let i = 0; i < firstMonthRows; i++) {
				let priceTr = {};
				for (let j = 0; j < DAY_COUNT; j++) {
					// 一行七个
					let button = {};

					if (i === 0 && j < headEmptyButtons) {
						// button.empty = true //月份第一天前面的空格 今天以前的日子不可以点击
						let key = moment([thisYear, thisMonth - 1, firstStartDay])
							.subtract(headEmptyButtons - (i * DAY_COUNT + j), 'days')
							.format('YYYY-MM-DD');
						button.date = moment([thisYear, thisMonth - 1, firstStartDay]).subtract(
							headEmptyButtons - (i * DAY_COUNT + j),
							'days'
						);
						button.date2 = moment([thisYear, thisMonth - 1, firstStartDay])
							.subtract(headEmptyButtons - (i * DAY_COUNT + j), 'days')
							.add(diffDays, 'days');
						let forbiCodition =
							(moment([thisYear, thisMonth - 1, firstStartDay])
								.subtract(headEmptyButtons - (i * DAY_COUNT + j), 'days')
								.diff(moment(moment().format('YYYY-MM-DD')), 'days') < 0 &&
								(button.previous = true)) ||
							moment([thisYear, thisMonth - 1, firstStartDay])
								.subtract(headEmptyButtons - (i * DAY_COUNT + j), 'days')
								.diff(
									moment(moment().format('YYYY-MM-DD')).add(1, 'years').subtract(diffDays, 'days'),
									'days'
								) > 0;
						if (forbiCodition) {
							button.forbid = true;
						} else {
							button = getMonthNormalButton(
								button,
								taxIncluded,
								diffDays,
								key,
								searchCriteria,
								lowPriceDatas,
								workingDays,
								lowestPriceDate
							);
						}
						button.notCurMonth = true;
					} else {
						let key = moment([thisYear, thisMonth - 1, firstStartDay])
							.add(i * DAY_COUNT + j - headEmptyButtons, 'days')
							.format('YYYY-MM-DD');
						button.date = moment([thisYear, thisMonth - 1, firstStartDay]).add(
							i * DAY_COUNT + j - headEmptyButtons,
							'days'
						);
						button.date2 = moment([thisYear, thisMonth - 1, firstStartDay]).add(
							i * DAY_COUNT + j - headEmptyButtons + diffDays,
							'days'
						);
						let forbidCondition =
							searchCriteria.get('flightWay') === 'D'
								? moment([thisYear, thisMonth - 1, firstStartDay])
										.add(i * DAY_COUNT + j - headEmptyButtons, 'days')
										.diff(moment(moment().format('YYYY-MM-DD')), 'days') < 0 ||
								  moment([thisYear, thisMonth - 1, firstStartDay])
										.add(i * DAY_COUNT + j - headEmptyButtons, 'days')
										.diff(
											moment(moment().format('YYYY-MM-DD'))
												.add(1, 'years')
												.subtract(diffDays, 'days'),
											'days'
										) > 0
								: moment([thisYear, thisMonth - 1, firstStartDay])
										.add(i * DAY_COUNT + j - headEmptyButtons, 'days')
										.diff(moment(moment().format('YYYY-MM-DD')), 'days') < 0 ||
								  moment([thisYear, thisMonth - 1, firstStartDay])
										.add(i * DAY_COUNT + j - headEmptyButtons, 'days')
										.diff(moment(moment().format('YYYY-MM-DD')).add(1, 'years'), 'days') > 0;
						if (forbidCondition) {
							// 今天以前的日子，明年今日以后的日子，不能点
							button.forbid = true;
						} else {
							// 正常显示和点击的日子
							button = getMonthNormalButton(
								button,
								taxIncluded,
								diffDays,
								key,
								searchCriteria,
								lowPriceDatas,
								workingDays,
								lowestPriceDate
							);
						}
						if (
							moment([thisYear, thisMonth - 1, firstStartDay])
								.add(i * DAY_COUNT + j - headEmptyButtons, 'days')
								.diff(moment([thisYear, thisMonth - 1, 1]).add(1, 'months'), 'days') >= 0
						) {
							// 这个月最后一天后面的空格
							button.notCurMonth = true;
						}
					}
					priceTr[j] = button;
				}
				result[i] = priceTr;
			}
			return Immutable.fromJS(result);
		} else {
			return null;
		}
	}
);

// 获取往返月视图往返间隔下拉显示低价数据
export const getDiffDaysPriceMonth = createSelector(
	[getLowPriceData, containsTaxSelector, getSearchCriteria, getCurrentMonth],
	(lowPriceDatas, taxIncluded, searchCriteria, currentMonthIndex) => {
		if (lowPriceDatas && lowPriceDatas.size !== 0) {
			let result = {},
				thisMonth = moment().add(currentMonthIndex, 'months').month() + 1,
				thisYear = moment().add(currentMonthIndex, 'months').year();
			if (searchCriteria.get('flightWay') === 'D') {
				for (let diffDay = 1; diffDay <= DAY_COUNT; diffDay++) {
					let lowestPrice = -1;
					for (let i = 0; ; i++) {
						if (
							moment([thisYear, thisMonth - 1, 1])
								.add(i, 'days')
								.diff(moment([thisYear, thisMonth - 1, 1]).add(1, 'months'), 'days') >= 0
						) {
							break;
						}
						let items = lowPriceDatas.get(
							moment([thisYear, thisMonth - 1, 1])
								.add(i, 'days')
								.format('YYYY-MM-DD')
						);
						let item = items && items.size > 0 ? items.get(diffDay - 1) : null;
						if (item && item.size !== 0) {
							let priceNow = taxIncluded ? item.get('totalPrice') : item.get('price');
							if (priceNow > 0 && ((lowestPrice > 0 && priceNow < lowestPrice) || lowestPrice < 0)) {
								lowestPrice = priceNow;
							}
						}
					}
					if (lowestPrice > 0) {
						result[diffDay] = lowestPrice;
					}
				}
				// 如果搜索的间隔天数大于7，这一天也要处理
				let searchedDay = moment(searchCriteria.getIn(['flightSegments', 0, 'departureDate'])), // 搜索当天日期
					searchedMonthFirst = moment([searchedDay.year(), searchedDay.month(), 1]), // 搜索月份的第一天
					todayMonthFirst = moment([moment().year(), moment().month(), 1]), // 当前月份第一天
					diffMonths = searchedMonthFirst.diff(todayMonthFirst, 'months'); // 搜索月份差（用于初始化月视图位置）
				let diffDaySearched = moment(searchCriteria.getIn(['flightSegments', 1, 'departureDate'])).diff(
					searchCriteria.getIn(['flightSegments', 0, 'departureDate']),
					'days'
				);
				if (diffDaySearched > DAY_COUNT && diffMonths === currentMonthIndex) {
					let todayItemSearched = lowPriceDatas.getIn([
						searchCriteria.getIn(['flightSegments', 0, 'departureDate']),
						diffDaySearched - 1,
					]);
					if (todayItemSearched && todayItemSearched.size > 0) {
						let todayPriceSearched = taxIncluded
							? todayItemSearched.get('totalPrice')
							: todayItemSearched.get('price');
						if (todayPriceSearched > 0) {
							result[diffDaySearched] = todayPriceSearched;
						}
					}
				}
			}
			return Immutable.fromJS(result);
		} else {
			return null;
		}
	}
);

// 获取往返周视图往返间隔下拉显示低价数据
export const getDiffDaysPriceWeek = createSelector(
	[getLowPriceData, containsTaxSelector, getSearchCriteria],
	(lowPriceDatas, taxIncluded, searchCriteria) => {
		if (lowPriceDatas && lowPriceDatas.size !== 0) {
			let result = {};

			if (searchCriteria.get('flightWay') === 'D') {
				// 先判断1-7天有数据的内容，再判断列表页当前回填内容
				for (let diffDay = 1; diffDay <= DAY_COUNT; diffDay++) {
					let lowestPrice = -1;
					// 这里是判断能搜索的所有日期的最低价，所以先遍历45天。再与当前搜索这天回填的价格做比较
					for (let i = 0; ; i++) {
						if (
							moment()
								.startOf('day')
								.add(i, 'days')
								.diff(
									moment().startOf('day').add(CONSTS.RECOMMEND.CALENDAR_LP_ROUNG_DAYSLIMIT, 'days'),
									'days'
								) >= 0
						) {
							break;
						}
						let items = lowPriceDatas.get(moment().startOf('day').add(i, 'days').format('YYYY-MM-DD'));
						let item = items && items.size > 0 ? items.get(diffDay - 1) : null;
						if (item && item.size !== 0) {
							let priceNow = taxIncluded ? item.get('totalPrice') : item.get('price');
							if (priceNow > 0 && ((lowestPrice > 0 && priceNow < lowestPrice) || lowestPrice < 0)) {
								lowestPrice = priceNow;
							}
						}
					}
					// 搜索当天回填的价格与45天最低价比较
					let todayItem = lowPriceDatas.getIn([
						searchCriteria.getIn(['flightSegments', 0, 'departureDate']),
						diffDay - 1,
					]);
					if (todayItem && todayItem.size > 0) {
						let todayPrice = taxIncluded ? todayItem.get('totalPrice') : todayItem.get('price');
						if (todayPrice > 0 && ((lowestPrice > 0 && todayPrice < lowestPrice) || lowestPrice < 0)) {
							lowestPrice = todayPrice;
						}
					}
					if (lowestPrice > 0) {
						result[diffDay] = lowestPrice;
					}
				}
				// 如果搜索的间隔天数大于7，这一天也要处理
				let diffDaySearched = moment(searchCriteria.getIn(['flightSegments', 1, 'departureDate'])).diff(
					searchCriteria.getIn(['flightSegments', 0, 'departureDate']),
					'days'
				);
				if (diffDaySearched > DAY_COUNT) {
					let todayItemSearched = lowPriceDatas.getIn([
						searchCriteria.getIn(['flightSegments', 0, 'departureDate']),
						diffDaySearched - 1,
					]);
					if (todayItemSearched && todayItemSearched.size > 0) {
						let todayPriceSearched = taxIncluded
							? todayItemSearched.get('totalPrice')
							: todayItemSearched.get('price');
						if (todayPriceSearched > 0) {
							result[diffDaySearched] = todayPriceSearched;
						}
					}
				}
			}
			return Immutable.fromJS(result);
		} else {
			return null;
		}
	}
);

// 获取往返周视图低价数据
export const getRoundCalendarWeek = createSelector(
	[getLowPriceData, containsTaxSelector, getSearchCriteria, getWorkingDays, getCurrentWeek, getDiffDays],
	(lowPriceDatas, taxIncluded, searchCriteria, workingDays, currentWeek, diffDays) => {
		let result = {},
			lowestPrice = -1;
		result.depDates = []; // 上方表头——出发时间
		result.retDates = []; // 左侧表头——到达时间
		result.prices = {};
		if (lowPriceDatas && lowPriceDatas.size !== 0) {
			// 其实只是计算出一个7*7的价格矩阵
			if (searchCriteria.get('flightWay') === 'D') {
				for (let i = 0; i < DAY_COUNT; i++) {
					// 循环时同时填充表头
					let thisDay = moment().add(i + currentWeek, 'days'),
						retDay = moment().add(i + currentWeek + diffDays, 'days'),
						depDate = {},
						retDate = {},
						depDayString = thisDay.format('YYYY-MM-DD'),
						retDayString = retDay.format('YYYY-MM-DD');
					depDate.date = thisDay;
					let workingInfoDept = workingDays.get(depDayString); // 填充班休数据
					if (workingInfoDept && workingInfoDept.get('stat') === 3) {
						depDate.holidayTag = true;
						depDate.tagName = '休';
					} else if (workingInfoDept && workingInfoDept.get('stat') === 4) {
						depDate.holidayTag = true;
						depDate.tagName = '班';
					}
					let calendarDept = DateUtil.getCalendarTextWithImmutable(moment.utc(depDayString)); // 填充假期数据
					if (calendarDept.get('workStatus') == CALENDAR_WORK_STATUS.NOT_WORK) {
						depDate.week = calendarDept.get('holidayText');
						depDate.holiday = true;
					} else {
						// 没有假期数据填充周几数据（和展开前相同）
						depDate.week = `周${'日一二三四五六'.charAt(thisDay.day())}`;
					}
					result.depDates.push(depDate);
					retDate.date = retDay;
					// 往返有去程返程两套日期
					let workingInfoRet = workingDays.get(retDayString);
					if (workingInfoRet && workingInfoRet.get('stat') === 3) {
						retDate.holidayTag = true;
						retDate.tagName = '休';
					} else if (workingInfoRet && workingInfoRet.get('stat') === 4) {
						retDate.holidayTag = true;
						retDate.tagName = '班';
					}
					let calendarRet = DateUtil.getCalendarTextWithImmutable(moment.utc(retDayString));
					if (calendarRet.get('workStatus') == CALENDAR_WORK_STATUS.NOT_WORK) {
						retDate.week = calendarRet.get('holidayText');
						retDate.holiday = true;
					} else {
						retDate.week = `周${'日一二三四五六'.charAt(retDay.day())}`;
					}
					result.retDates.push(retDate);
					let items = lowPriceDatas.get(depDayString);
					let priceTr = {};
					// 遍历价格信息，顺便记录最低价
					for (let j = 0; j < DAY_COUNT; j++) {
						let item =
							items && items.size > 0
								? j + diffDays - 1 - i >= 0
									? items.get(j + diffDays - 1 - i)
									: null
								: null;
						let priceItem = {};
						if (
							depDayString === searchCriteria.getIn(['flightSegments', 0, 'departureDate']) &&
							searchCriteria.getIn(['flightSegments', 1, 'departureDate']) ===
								moment()
									.add(currentWeek + diffDays + j, 'days')
									.format('YYYY-MM-DD')
						) {
							priceItem.active = true;
						}
						if (item && item.size !== 0) {
							priceItem.price = taxIncluded ? item.get('totalPrice') : item.get('price');
							if (!priceItem.price) {
								priceItem.priceGone = true;
							} else {
								if (
									priceItem.price > 0 &&
									((lowestPrice > 0 && priceItem.price < lowestPrice) || lowestPrice < 0)
								) {
									lowestPrice = priceItem.price;
								}
							}
						} else {
							if (j + diffDays - 1 - i < 0) {
								priceItem.illegal = true;
							} else {
								priceItem.noPrice = true;
							}
						}
						priceTr[j] = priceItem;
					}
					result.prices[i] = priceTr;
				}
				// 遍历所有，找到和最低价一样的打标签
				for (let i = 0; i < DAY_COUNT; i++) {
					for (let j = 0; j < DAY_COUNT; j++) {
						if (result.prices[i][j].price && result.prices[i][j].price === lowestPrice) {
							result.prices[i][j].lowPrice = true;
						}
					}
				}
			}
		}
		return Immutable.fromJS(result);
	}
);

// 其实就是按行列遍历
export const getRoundCalendarTrend = createSelector(
	[getLowPriceData, containsTaxSelector, getSearchCriteria, getWorkingDays, getDiffDays],
	(lowPriceDatas, taxIncluded, searchCriteria, workingDays, diffDays) => {
		if (lowPriceDatas && lowPriceDatas.size !== 0) {
			let lhPrice = getLowestAndHighest(taxIncluded, diffDays, searchCriteria, lowPriceDatas),
				result = { ...lhPrice, months: [] },
				count = 0,
				activeIndex = 0,
				minHeight = 110,
				maxHeight = 200,
				monthsChar = [
					'一月',
					'二月',
					'三月',
					'四月',
					'五月',
					'六月',
					'七月',
					'八月',
					'九月',
					'十月',
					'十一月',
					'十二月',
				];
			// 拼凑12个月份数据
			for (let i = 0; i <= 12; i++) {
				let thisTime = moment().add(i, 'months'),
					thisYear = thisTime.year(),
					thisMonth = thisTime.month(),
					firstStartDay = i == 0 ? thisTime.date() : 1,
					lowestPriceDate = getLowestMonthPrice(
						lowPriceDatas,
						searchCriteria,
						i,
						firstStartDay,
						taxIncluded,
						diffDays,
						0
					),
					thisMonthLowestHeight = -1,
					thisMonthLowestPrice = -1,
					thisMonthLowestIndex = -1,
					thisMonthFirstIndex = -1;
				result['months'][i] = { month: monthsChar[thisMonth], monthIndex: thisMonth, data: [] };
				for (
					let j = 0;
					moment([thisYear, thisMonth, firstStartDay])
						.add(j, 'days')
						.diff(moment([thisYear, thisMonth, 1]).add(1, 'months'), 'days') < 0;
					j++
				) {
					if (
						moment()
							.add(count + diffDays - 1, 'days')
							.diff(moment().add(1, 'years'), 'days') >= 0
					) {
						break;
					}
					let button = {};
					getMonthNormalButton(
						button,
						taxIncluded,
						diffDays,
						moment([thisYear, thisMonth, j + firstStartDay]).format('YYYY-MM-DD'),
						searchCriteria,
						lowPriceDatas,
						workingDays,
						lowestPriceDate
					);
					if (button.price && lhPrice.hightestPrice != lhPrice.lowestPrice) {
						button.height = Math.ceil(
							minHeight +
								((button.price - lhPrice.lowestPrice) / (lhPrice.hightestPrice - lhPrice.lowestPrice)) *
									(maxHeight - minHeight)
						);
					} else if (button.price && lhPrice.hightestPrice == lhPrice.lowestPrice) {
						button.height = maxHeight;
					} else {
						button.height = minHeight;
					}

					if (moment([thisYear, thisMonth, j + firstStartDay]).day() == 0) {
						button.week = '周日';
					} else if (moment([thisYear, thisMonth, j + firstStartDay]).day() == 6) {
						button.week = '周六';
					} else {
						button.week = '';
					}
					let curTime = moment([thisYear, thisMonth, firstStartDay + j]),
						date = curTime.format('MM-DD'),
						depdate = curTime.format('YYYY-MM-DD'),
						dateR = moment([thisYear, thisMonth, firstStartDay + j])
							.add(diffDays, 'days')
							.format('MM-DD'),
						rtdate = moment([thisYear, thisMonth, firstStartDay + j])
							.add(diffDays, 'days')
							.format('YYYY-MM-DD');
					button.date = date;
					button.dateR = dateR;
					button.depdate = depdate;
					button.rtdate = rtdate;
					button.dayIndex = count + 1;
					result['months'][i]['data'].push(button);
					count++;
					if (j == 0) {
						thisMonthFirstIndex = count;
					}
					if (button.active) {
						activeIndex = count;
					}
					if (button.lowPrice) {
						thisMonthLowestHeight = button.height;
						thisMonthLowestPrice = button.price;
						if (thisMonthLowestIndex === -1) {
							thisMonthLowestIndex = count;
						}
					}
				}
				if (thisMonthLowestIndex === -1) {
					thisMonthLowestIndex = thisMonthFirstIndex;
				}
				result['months'][i] = {
					...result['months'][i],
					thisMonthLowestHeight: thisMonthLowestHeight,
					thisMonthLowestPrice: thisMonthLowestPrice,
					thisMonthLowestIndex: thisMonthLowestIndex,
				};
			}
			result = { ...result, activeIndex, count };
			return Immutable.fromJS(result);
		} else {
			return null;
		}
	}
);
