/**
 * 抽取价格信息，埋点上报用
 */
export const extractPriceSummaryAsString = (price, flight) => {
    let priceInfoForUbt = {}

    if (price) {
        try {
            const itineraryId = flight ? (flight.get('itineraryId') || '') : ''

            priceInfoForUbt = {
                adultprice: price.get('adultPrice') || undefined,
                adulttax: price.get('adultTax') || undefined,
                childprice: price.get('childPrice') || undefined,
                childtax: price.get('childTax') || undefined,
                infantprice: price.get('infantPrice') || undefined,
                infanttax: price.get('infantTax') || undefined,
                eligibility: price.get('eligibility'),
                cabin: price.get('cabin'),
                channel: price.get('channel'),
                producttype: price.getIn(['productType', 'category'], '-') + '|' + price.getIn(['productType', 'name'], ''),
                routesearchtoken: price.get('routeSearchToken'),
                ticketCount: price.get('ticketCount') || undefined,
                flightNum: itineraryId || undefined,
                matchedKey: price.get('matchedKey') || undefined,
                key: price.get('key') || undefined
            }
        } catch (error) {
            console.error(`extractPriceSummaryAsString error: ${error}`)
        }
    }

    return JSON.stringify(priceInfoForUbt)
}