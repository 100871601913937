import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import Immutable from 'immutable';
import { CSSTransitionGroup } from 'react-transition-group';

import { ExposeStat } from '@/src/ubt.v2/exposeStat';
import { lightUpload, sendTrace } from '@/src/sources/common/lightUbt';

import ReactToolTip from '@/src/components/Base/reactToolTip';
import Converter from '@/src/sources/list/converter';
import getCabinListTitle from '@/src/sources/list/converter/getCabinListTitle';
import getCabinHoverContent from '@/src/sources/list/converter/getCabinHoverContent';

import Tooltip from '../../common/tooltip';
import { PRICE_TAG_POSITION } from '../../../../constants/list/enums/priceTagPosition';
import { getPenaltyDetail, getIfPenaltyDetailInProgress } from '../../../../actions/list/penalty';
import { PRICE_TAG_TYPE } from '../../../../constants/list/enums/priceTagType';
import PRICE_MESSAGE from '../../../../constants/list/enums/priceMessage';
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list';
import { UbtHandler } from '../../../../ubt.v2/handler';
import { PriceFragment } from '../../common/priceFragment';
import { extractPriceSummaryAsString } from '../../../../sources/common/extractPriceSummary';
import { BrandPrice } from './brandPrice';
import { ChooseXPrice } from './chooseXPrice';
import { ProductCategoryWrapper } from '../../../../constants/list/enums';
import { genPriceMiddleTags, genMinrefundfeeAndSign } from './genPriceMiddleTags';
import PriceChangePopover from './PriceChangePopover';
import { tryExec } from '../../../../sources/common/tryExec';
import CONST_CONFIG from '../../../../constants/list/constConfig';
import { scopeFunctionAndExec } from '../../../../sources/list/scopeFunction.js';
import { getIfScopeDomestic } from '../../../../sources/list/scope';
import { getMousePositionByTriggerElement } from '../../../../sources/common/getMousePositionByTriggerElement';
import LotteryDialog from '../../popups/LotteryDialog';
import PriceBox, { removeYen, removeYenTostring } from './PriceBox';
import { getChildInfantText } from '../../common/utils';
import { RightsInfo } from './rightsInfo';
import { CommonRestrictionComp } from './commonRestrictionComp';

import DebugInfo from './debugInfo';

const brandOrChooseInfoMap = {
	brand: {
		remark: '品牌运价',
		buttonText: '选购',
		group: LIST_UBT_GROUP_TYPES.BRAND_PRICE,
		key: LIST_UBT_KEY_TYPES.BRAND_PRICE_EXPAND_CLICK,
		renderEl: BrandPrice,
	},
	chooseX: {
		remark: '三选X',
		buttonText: '选购',
		group: LIST_UBT_GROUP_TYPES.CHOOSE_X_PRICE,
		key: LIST_UBT_KEY_TYPES.CHOOSE_X_PRICE_EXPAND_CLICK,
		renderEl: ChooseXPrice,
	},
};

const MAX_EXPANDED_PRICE_COUNT = 4; // 展开模式的品牌运价/三选X，最多显示4个运价：显示2个或3个则为正常模式，4个则为紧凑模式，超出4个则过滤掉

const parseYen = (str) => {
	let newStr = str || '';
	return newStr.replace('&yen;', '¥');
};

export const getOptmizedTag = (optimizedDiscountWrapper, travelPackageWrapper, splitRound, index) => {
	if (optimizedDiscountWrapper) {

		let optimizedDiscountTag = optimizedDiscountWrapper?.data?.tag || '',
			optimizedDiscountPreTag = optimizedDiscountWrapper?.data?.preTag || '',
			hasHoverList =
				(optimizedDiscountWrapper?.data?.hoverDataList &&
					optimizedDiscountWrapper?.data?.hoverDataList.length > 0) ||
				false,
			optDesc = optimizedDiscountWrapper?.data?.desc || '',
			originPriceItem = {},
			salesItem = {},
			sales_already = {},
			sales_newCustomer = {},
			flightPriceItem = [],
			travelPackageItem = {},
			travelPackageDescribeItem = {},
			discountAfter = {},
			limitTime = {};

		if (hasHoverList) {
			originPriceItem = optimizedDiscountWrapper?.data?.hoverDataList?.find((item) => item.type == '1') || {};
			sales_already = optimizedDiscountWrapper?.data?.hoverDataList?.find((item) => item.type == '7');
			sales_newCustomer = optimizedDiscountWrapper?.data?.hoverDataList?.find((item) => item.type == '8');
			salesItem = optimizedDiscountWrapper?.data?.hoverDataList?.find((item) => item.type == '9');
			flightPriceItem = optimizedDiscountWrapper?.data?.hoverDataList?.filter((item) => item.type == '10');
			limitTime = optimizedDiscountWrapper?.data?.hoverDataList?.find((item) => item.type === '13');
			travelPackageItem = optimizedDiscountWrapper?.data?.hoverDataList?.find((item) => item.type == '11');
			discountAfter = optimizedDiscountWrapper?.data?.hoverDataList?.find((item) => item.type == '14');
			travelPackageDescribeItem = {
				title: optimizedDiscountWrapper?.data?.hoverDataList
					?.find((item) => item.type == '12')
					?.title?.split('|')?.[0],
				item: optimizedDiscountWrapper?.data?.hoverDataList
					?.find((item) => item.type == '12')
					?.title?.split('|')?.[1],
			};
		}

		const promoteInfoList = [];
		const priceItem =
			flightPriceItem.length && travelPackageItem?.title && window.GlobalSwitches.newTravelPackageLayer
				? [...flightPriceItem, travelPackageItem]
				: [originPriceItem];
		[...priceItem, sales_already || {}, sales_newCustomer || {}, salesItem || {}].forEach((item) => {
			item?.title &&
				promoteInfoList.push({
					title: item.title,
					item: removeYen(item.item),
				});
		});

		const traceData = {
			isTravelPackage: travelPackageWrapper ? 1 : 0,
			source: 0,
			promoteInfoList,
		};

		const travelPackageDescribeItemBox =
			travelPackageDescribeItem?.title &&
			travelPackageDescribeItem?.item &&
			window.GlobalSwitches.newTravelPackageLayer ? (
				<>
					<div className="describe-info-line" />
					<div className="describe-info">
						<span>{travelPackageDescribeItem.title}：</span>
					</div>
					<div className="describe-info">
						<span>{travelPackageDescribeItem.item}</span>
					</div>
				</>
			) : null;

		return (
			<div className="optimized-tag-container">
				<div className="post-prefix" id={`optimizedTag_${index}`}>
					<span>
						{optimizedDiscountPreTag ? `${optimizedDiscountPreTag} |` : null}{' '}
						{parseYen(optimizedDiscountTag)}
					</span>
				</div>
				{hasHoverList ? (
					<ReactToolTip
						splitRound={splitRound}
						traceKey="S_FLT_N_Online_Middle_Below_Price_Discount_info"
						traceData={traceData}
						parentId={`optimizedTag_${index}`}
						key={`optimizedTooltip_${index}`}
						toolTipContent={
							<Tooltip extClass="tooltip-bookAndDiscount">
								<div className="content">
									<div className="travelPack-main-title">价格说明</div>
									<div className="travelPack-content-body">
										<PriceBox
											flightPriceItem={flightPriceItem}
											travelPackageItem={travelPackageItem}
											originPriceItem={originPriceItem}
											limitTime={limitTime}
										></PriceBox>
										<div className="price-info">
											<span>
												{sales_already
													? sales_already.title
													: sales_newCustomer
													? sales_newCustomer.title
													: salesItem
													? salesItem?.title
													: ''}
											</span>
											<span>
												-¥
												<span className="main-price">
													{removeYen(
														sales_already
															? sales_already.item
															: sales_newCustomer
															? sales_newCustomer.item
															: salesItem
															? salesItem.item
															: ''
													) || ''}
												</span>
											</span>
										</div>
										{discountAfter && (
											<div className="after-price-style">
												<span className="after-price-title">{discountAfter?.title}</span>
												<span className="after-main-price">
													¥<span>{removeYen(discountAfter?.item) || ''}</span>
												</span>
											</div>
										)}
										{travelPackageDescribeItemBox}
									</div>
								</div>
							</Tooltip>
						}
					/>
				) : (
					optDesc && (
						<ReactToolTip
							splitRound={splitRound}
							parentId={`optimizedTag_${index}`}
							key={`optimizedTooltip_${index}`}
							toolTipContent={<Tooltip extClass="tooltip-bookAndDiscount" content={optDesc} />}
						/>
					)
				)}
			</div>
		);
	}
};


const Price = (props) => {
	const {
		price,
		index,
		onBook,
		prevCond,
		allSegmentList,
		penaltyTag,
		currentSegmentSeq,
		isFCRecommendYS,
		containsVirtualFlight,
		isSuperFlyer,
		globalSwitch,
		minPrice,
		onPopupFlightDetail,
		tongChengFlightEnum,
		flight,
		splitRoundFlightsSwitch,
		splitRound,
		searchFlightsIsFinished,
		flightIndex,
		priceIndex,
		selected,
		cabinNew,
		virtualFlightData,
	} = props;

	const brandWrapper = price.get('brandWrapper'),
		chooseXWrapper = price.get('chooseXWrapper'),
		brandOrChooseWrapper = brandWrapper || chooseXWrapper,
		anyRelatedPrices = brandOrChooseWrapper ? brandOrChooseWrapper.hasRelatedPrices : false,
		brandOrChooseInfo = brandOrChooseWrapper
			? brandWrapper
				? brandOrChooseInfoMap.brand
				: brandOrChooseInfoMap.chooseX
			: null,
		relatedPrices = price.get('relatedPrices');

	const [extraTag, setExtraTag] = useState();
	const [extraPenaltyTag, setExtraPenaltyTag] = useState('');

	const [expandRelatedPrices, setExpandRelatedPrices] = useState(false),
		[minRefundWrapper, setMinRefundWrapper] = useState({
			minRefundFee: undefined,
			minRefundFeeSign: undefined,
			allGeneralCoupons: undefined,
		}),
		{ dataMinrefundfee, dataMinrefundfeesign, allGeneralCoupons } = genMinrefundfeeAndSign(minRefundWrapper);

	const onToggleExpandRelatedPrices = (ev) => {
			const priceSummary = extractPriceSummaryAsString(price, flight);
			const onToggleExpandRelatedPricesUbtHandler = ListUbtGenerator(
				brandOrChooseInfo.group,
				brandOrChooseInfo.key,
				!expandRelatedPrices,
				priceSummary
			);

			brandOrChooseInfo.key.value.process(onToggleExpandRelatedPricesUbtHandler);

			setExpandRelatedPrices(!expandRelatedPrices);
			ev.stopPropagation();
		},
		getId = (group, seq) => `p_${index}_${group}_${seq}`;

		const priceFragment = useMemo(() => {
			return new PriceFragment({
				...props,
				setExtraTag,
				extraPenaltyTag,
				setExtraPenaltyTag
			});
		}, [props, extraPenaltyTag]),
		mileagePointSwitch = globalSwitch.get('mileagePointSwitch'),
		//进行严格反查是否要等到退改签接口拿到之后才进行，主要是传最小退票费给严格反查接口，增值页依赖最小退票费
		strictSearchWaitforPenaltyDetailSwitch = globalSwitch.get('strictSearchWaitforPenaltyDetailSwitch'),
		hideAllPriceTags = brandWrapper && expandRelatedPrices, //品牌运价展开时候隐藏所有运价上面的各种标签，三选X的不需要隐藏
		lccServiceWrapper = price.get('lccServiceWrapper'),
		priceTags = price.get('priceTags'),
		{ nonGeneralTagsOfMiddle, generalTagsOfMiddle, membershipTagsOfMiddle } = genPriceMiddleTags({
			price,
			getId,
			setMinRefundWrapper,
			allSegmentList,
			prevCond,
			mileagePointSwitch,
			splitRoundFlightsSwitch,
			splitRound,
			index,
		});
		const rightsInfo = price.get('rightsInfo');
		
	let rapidBook = !hideAllPriceTags && price.get('rapidBook'), // rapidBook 标记是否满足显示抢订：在 initTheFlights 中根据余票数目和价格是否在所属航班最低价来判断
		latestSegment = allSegmentList[currentSegmentSeq],
		theMinPriceItem = latestSegment.get('theMinPriceItem'),
		priceList = latestSegment.get('priceList'),
		Airline = latestSegment.get('airlineCode'),
		flightList = latestSegment.get('flightList'),
		AirlineNumber = flightList.map((flight) => flight.get('flightNo')).join('|'),
		isLastSegment = latestSegment.get('isLastSegment'), //往返组合的 currentSegmentSeq === 1
		relatedSegmentList = allSegmentList.filter((segment, index) =>
			isLastSegment ? true : index === currentSegmentSeq
		), //往返组合以及其他航程类型的最后一程时候，显示所有航程航班信息
		ticketCount = hideAllPriceTags ? 0 : price.get('ticketCount'),
		productTypeWrapper = extraTag ? new ProductCategoryWrapper({category: extraTag, policyFlag: 0}) : price.get('productTypeEnum'),
		productIsNone = productTypeWrapper.tag.value.isNone,
		cabinEnumList = price.get('cabinEnumList'),
		sortContainsTax = price.get('sortContainsTax'),
		priceToShow = sortContainsTax ? price.get('avgPriceWithTax') : price.get('avgPriceWithoutTax'),
		servicePackage = price.get('servicePackage') ? price.get('servicePackage').get('price') : null,
		theMinPriceToCompare = minPrice.get('totalPrice'),
		isTheMinPrice = scopeFunctionAndExec({
			i: () => {
				return price.get('avgPriceWithTax') === theMinPriceToCompare;
			},
			d: () => {
				return price.get('adultPrice') == minPrice.get('price');
			},
		}),
		isMultiplePassengerType = prevCond.get('isMultiplePassengerType'),
		flightWayEnum = prevCond.get('flightWayEnum').value,
		isDifferentCabin = cabinEnumList.some((cabin) => prevCond.get('cabin').includes(cabin.key) == false),
		manyCabins = cabinEnumList.toSet().size > 1,
		penaltyCriteria = price.getIn(['penalty', 'penaltyCriteria']),
		tooltipContent = getCabinHoverContent(relatedSegmentList, cabinEnumList, price, manyCabins),
		priceTagEnums = hideAllPriceTags ? Immutable.List() : price.get('priceTagEnums'),
		GVLimitationsEnums = hideAllPriceTags ? Immutable.List() : price.get('GVLimitationsEnums'),
		referenceSalePrice = price.get('referenceSalePrice'),
		hasReferenceSalePrice = !!referenceSalePrice,
		getMultiGVLimitationsTooltip = (brandCabinName, badBrandPriceLabels, gVLimitationsEnums) => (
			<div className="multi-tooltip">
				{gVLimitationsEnums.map((limitationsEnum) =>
					brandCabinName ? (
						<div className="brand-hover-root">
							<div className="brand-hover">
								{brandCabinName}
								{badBrandPriceLabels}
							</div>
							{typeof limitationsEnum.restrict.value.getTooltip === 'function' &&
								limitationsEnum.restrict.value.getTooltip(limitationsEnum)}
						</div>
					) : (
						typeof limitationsEnum.restrict.value.getTooltip === 'function' &&
						limitationsEnum.restrict.value.getTooltip(limitationsEnum)
					)
				)}
			</div>
		),
		getMultiGVLimitationsTooltipContent = (brandCabinName, badBrandPriceLabels, gVLimitationsEnums) => (
			<div className="tooltip">
				<div className="tooltip-content">
					{badBrandPriceLabels ? (
						<div style={{ marginBottom: 10 }}>
							{brandCabinName}
							{badBrandPriceLabels}
						</div>
					) : null}
					<h3>{PRICE_MESSAGE.GV_TAG_HOVER.Title}</h3>
					{gVLimitationsEnums.map((limitationsEnum, index) => (
						<p key={index}>
							{index + 1}、
							{(typeof limitationsEnum.restrict.value.getMultipleTooltip === 'function' &&
								limitationsEnum.restrict.value.getMultipleTooltip(limitationsEnum)) ||
								PRICE_MESSAGE.GV_TAG_HOVER[`Multi_${limitationsEnum.label}`] ||
								PRICE_MESSAGE.GV_TAG_HOVER.MultiPerson.replace(
									/\[VALUE\]/g,
									limitationsEnum.label
								).replace(/\[LIMIT_TYPE]/g, limitationsEnum.childBookAdultTicket ? '婴儿' : '儿童婴儿')}
							{limitationsEnum.restrict.value.extRemark}
						</p>
					))}
				</div>
			</div>
		),
		tagEnumsOfLeft = priceTagEnums.filter((wrapper) => wrapper.tag.value.position === PRICE_TAG_POSITION.LEFT),
		theTodayFreeRefund =
			tagEnumsOfLeft && tagEnumsOfLeft.size && tagEnumsOfLeft.find((item) => item.tag.key === 'TodayFreeRefund'), //是否有甄选服务
		discountWrapper = priceTagEnums.find((wrapper) => wrapper.tag.key === 'DiscountWrapper'), //是否有列表页抽奖
		optimizedDiscountWrapper = priceTagEnums.find(
			(wrapper) => wrapper.tag.key === 'BookAlreadyDiscounted' || wrapper.tag.key === 'BookAndDiscount'
		), //是否有已减或订立减
		travelPackageWrapper = priceTagEnums.find((wrapper) => wrapper.tag.key === 'TravelPackage'), // 是否有旅套
		hasTodayFreeRefund = theTodayFreeRefund ? theTodayFreeRefund.data : null,
		getPenaltyDetailPromise = () =>
			getPenaltyDetail(
				allSegmentList,
				price,
				prevCond,
				penaltyCriteria,
				hasTodayFreeRefund,
				GVLimitationsEnums,
				mileagePointSwitch,
				splitRoundFlightsSwitch
			),
		getPriceTagTooltip = (wrapper, i) => (
			<ReactToolTip
				splitRound={splitRound}
				parentId={getId(wrapper.tag.key, i)}
				key={getId(wrapper.tag.key, i)}
				toolTipContent={wrapper.tag.value.getTooltip(wrapper, prevCond, price, getId(wrapper.tag.key, i))}
			/>
		),
		restrictionList = hideAllPriceTags ? Immutable.List() : price.get('restrictionList'),
		// 机场让利限制
		airportDiscountRestriction = restrictionList.toJS().find((item) => item.type === 'Constraint'),
		// 过滤掉机场让利限制类型
		newRestrictionList = restrictionList
			.toJS()
			.reverse()
			.filter((item) => item.type !== 'Constraint'),
		allBrandPriceLabels = (
			!hideAllPriceTags && brandWrapper && brandWrapper.data && brandWrapper.data.attrs
				? brandWrapper.data.attrs.filter((attr) => attr.tag && attr.showTag)
				: []
		).map((attr) => ({ tag: attr.tag, badness: attr.badness })), //品牌运价外露标签
		//不好的品牌运价权益放在左边，其他权益放右边
		badBrandTagName = brandWrapper && brandWrapper.data && brandWrapper.data.badTagName,
		goodBrandTagName = brandWrapper && brandWrapper.data && brandWrapper.data.goodTagName,
		brandCabinName =
			brandWrapper && brandWrapper.data && brandWrapper.data.cabinName ? brandWrapper.data.cabinName + '：' : '',
		joinedBadBrandPriceLabels = badBrandTagName
			? allBrandPriceLabels
					.filter((item) => item.badness < 0)
					.map((item) => item.tag)
					.join('；')
			: '', // -1： 不好的权益， 0：中性的权益，1：好的权益
		joinedGoodBrandPriceLabels = goodBrandTagName
			? allBrandPriceLabels
					.filter((item) => item.badness >= 0)
					.map((item) => item.tag)
					.join('；')
			: '',
		tagRenderOfLeft =
			tagEnumsOfLeft.size > 0 ? (
				<React.Fragment>
					{tagEnumsOfLeft.map((wrapper, i) => wrapper.tag.value.getLabel(getId(wrapper.tag.key, i)))}
					{tagEnumsOfLeft.map((wrapper, i) => getPriceTagTooltip(wrapper, i))}
				</React.Fragment>
			) : null,
		goodBrandPriceTag = joinedGoodBrandPriceLabels ? (
			<React.Fragment>
				<span className="tag" id={`goodBrandTag_${index}`}>
					<div>{goodBrandTagName}</div>
				</span>
				<ReactToolTip
					splitRound={splitRound}
					key={`goodBrandTag_${index}`}
					parentId={`goodBrandTag_${index}`}
					toolTipContent={<Tooltip content={brandCabinName + joinedGoodBrandPriceLabels} />}
				/>
			</React.Fragment>
		) : null,
		getRestrictLabel = () => {
			let eligibility = '',
				restrictionListTxt;
			let isNationality = newRestrictionList.some((item) => {
					return item.type === 'Nationality';
				}),
				isAge = newRestrictionList.some((item) => {
					return item.type === 'Age';
				});
			for (var i = 0; i < newRestrictionList.length; i++) {
				if ((isAge || isNationality) && newRestrictionList[i].type === 'Eligibility') {
					eligibility = newRestrictionList[i].label;
					newRestrictionList.splice(i, 1);
				}
			}
			let extraText = '';
			restrictionListTxt = newRestrictionList.map((restrict) => {
				restrict.label =
					restrict.type == 'Nationality' && eligibility
						? restrict.label.replace('乘客', '') + eligibility
						: restrict.label;
				restrict.label =
					restrict.type == 'Age' && eligibility && !isNationality
						? restrict.label.replace('乘客', '') + eligibility
						: restrict.label;
				if (restrict.type == 'MultiPerson') { 
					extraText = getChildInfantText(restrict, false);
					restrict.label = newRestrictionList?.length > 1 ? (restrict.label + extraText) : restrict.label;
				}
				return eligibility ? restrict.label.replace('的乘客', '') : restrict.label;
			});
			if (newRestrictionList.length === 1 && newRestrictionList[0].type === 'MultiPerson') {
				return `限${restrictionListTxt.join('，')}预订${extraText}`;
			} else {
				return `限${restrictionListTxt.join('，')}预订`;
			}
		},
		joinedRestrictionList = newRestrictionList.length ? getRestrictLabel() : null,
		passengerRestrictionTag =
			newRestrictionList.length || joinedBadBrandPriceLabels ? (
				<React.Fragment>
					<span className="limit" id={`tagLimit_${index}`}>
						<div>
							{[joinedBadBrandPriceLabels ? badBrandTagName : '', joinedRestrictionList]
								.filter(Boolean)
								.join('，')}
						</div>
					</span>
					{newRestrictionList.length ? (
						<ReactToolTip
							splitRound={splitRound}
							parentId={`tagLimit_${index}`}
							toolTipContent={
								newRestrictionList.length === 1
									? getMultiGVLimitationsTooltip(
											brandCabinName,
											joinedBadBrandPriceLabels,
											GVLimitationsEnums
									  )
									: getMultiGVLimitationsTooltipContent(
											brandCabinName,
											joinedBadBrandPriceLabels,
											GVLimitationsEnums
									  )
							}
						/>
					) : (
						<ReactToolTip
							splitRound={splitRound}
							parentId={`tagLimit_${index}`}
							toolTipContent={<Tooltip content={brandCabinName + joinedBadBrandPriceLabels} />}
						/>
					)}
				</React.Fragment>
			) : null,
		// 该航班下的最低价
		isMinPriceInTheFlight = scopeFunctionAndExec({
			i: () => false,
			d: () => {
				// 国内外露的价格是成人价
				return flight.getIn(['minPriceWithoutTax', 'ADULT']) == price.get('adultPrice');
			},
		});

	priceTagEnums.forEach((item) => {
		if (item.tag === PRICE_TAG_TYPE.CaSpecialDiscount) priceToShow += item.data;
	});

	const onClickBookBtn = (thePrice, ev) => {
		const priceEl = ev.target;

		const doBook = () => {
			let minRefundFee = tryExec(() => priceEl.getAttribute('data-minrefundfee'), '');
			let minRefundFeeSign = tryExec(() => priceEl.getAttribute('data-minrefundfeesign'), '');
			let allGeneralCoupons = tryExec(() => priceEl.getAttribute('data-allgeneralcoupons'), '');

			if (minRefundFee === '') {
				minRefundFee = undefined;
			} else {
				minRefundFee = +minRefundFee;
			}

			if (minRefundFeeSign === '') {
				minRefundFeeSign = undefined;
			}

			let priceText = tryExec(() => priceEl.closest('.seat-row').innerText, '');
			UbtHandler.onUploadData('c_flight_online_list_click_book_btn', {
				remark: '点击预订按钮',
				innerText: priceText,
				allGeneralCoupons: allGeneralCoupons && allGeneralCoupons.length ? allGeneralCoupons : undefined,
			});
			if (virtualFlightData && Object.keys(virtualFlightData).length > 0) {
				sendTrace('online_list_virtureflights_click', {
					...virtualFlightData,
					action: 'book',
					price: priceToShow,
					priceText,
					pricePosition: priceIndex + 1,
				});
			}
			LIST_UBT_KEY_TYPES.FIRST_SEG_FLIGHT_CARD_CLICK.value.process({
				tag: [{ clickBookingBeforeSearchIsFinished: !searchFlightsIsFinished }],
			});
			onBook.call(null, flight, thePrice, {
				containsVirtualFlight,
				isSuperFlyer,
				needSendUbt: true,
				isFCRecommendYS,
				minRefundFee,
				minRefundFeeSign,
				flightIndex,
				priceIndex,
			});
		};

		let checkPenaltyDetailTimeoutInMs = CONST_CONFIG.SEARCH.STRICT_SEARCH_WAIT_FOR_PENALTY_DETAIL_TIMEOUT_IN_MS; //默认2000ms
		let start = +new Date();
		let uploadWaitForPenaltyDetailUbt = (data) =>
			UbtHandler.onUploadData('c_strict_wait_penalty', {
				...{ start, now: +new Date(), timeout: checkPenaltyDetailTimeoutInMs, ...data },
			});
		let alreadySendStartWaitUbt = false;

		let waitforPenaltyDetail = () => {
			const penaltyDetailInProgress = getIfPenaltyDetailInProgress(thePrice); //该运价请求退改签接口是否尚未返回
			const now = +new Date();
			if (penaltyDetailInProgress) {
				if (now - start < checkPenaltyDetailTimeoutInMs) {
					if (!alreadySendStartWaitUbt) {
						alreadySendStartWaitUbt = true;
						uploadWaitForPenaltyDetailUbt({
							type: 'start_wait',
							remark: '执行严格反查之前退改签接口尚未返回，等待返回',
						});
					}

					setTimeout(() => {
						waitforPenaltyDetail();
					}, CONST_CONFIG.SEARCH.STRICT_SEARCH_WAIT_FOR_PENALTY_DETAIL_INTERVAL_IN_MS);
				} else {
					uploadWaitForPenaltyDetailUbt({ type: 'wait_timeout', remark: '严格反查之前等待退改签接口超时' });
					doBook();
				}
			} else {
				if (alreadySendStartWaitUbt) {
					uploadWaitForPenaltyDetailUbt({ type: 'end_wait', remark: '退改签接口返回' });
				}

				doBook();
			}
		};

		// 加个开关，控制是否要等待拿到退改签接口信息，才进行严格反查
		if (strictSearchWaitforPenaltyDetailSwitch) {
			waitforPenaltyDetail();
		} else {
			doBook();
		}
		if (ev && ev.nativeEvent) {
			let { position, isCenter } = getMousePositionByTriggerElement(ev.nativeEvent);
			UbtHandler.onUploadData('c_book_btn_click', { position, isCenter });
		}
	};
	const onBookBtnMouseEvent = (type) => {
		UbtHandler.onUploadData('c_book_btn_mouse_event', { type });
	};

	const [lotteryVisible, setLotteryVisible] = useState(false);
	const [showSalePrice, setShowSalePrice] = useState(false);
	const [isFromOrderBtn, setIsFromOrderBtn] = useState(false);
	const [brandPrice, setBrandPrice] = useState(new Map());
	const [isFromBrandPrice, setIsFromBrandPrice] = useState(false);

	const showLotteryDialog = () => {
		setLotteryVisible(true);
	};

	const closeLotteryDialog = () => {
		setLotteryVisible(false);
		setShowSalePrice(true);
	};

	const debugInfo = price.get('extData')?.get('DebugTip');

	const getLotteryInfo = () => {
		if (discountWrapper) {
			let preTag = discountWrapper.data.preTag || '',
				postTag = discountWrapper.data.postTag || '',
				originPriceItem = discountWrapper.data.hoverList.find((item) => item.type == '1') || {},
				flightPriceItem = discountWrapper.data.hoverList.filter((item) => item.type == '10'),
				travelPackageItem = discountWrapper.data.hoverList.find((item) => item.type == '11'),
				travelPackageDescribeItem = {
					title: discountWrapper.data.hoverList.find((item) => item.type == '12')?.title?.split('|')?.[0],
					item: discountWrapper.data.hoverList.find((item) => item.type == '12')?.title?.split('|')?.[1],
				},
				limitTime = discountWrapper.data.hoverList?.find((item) => item.type === '13'),
				couponDescItem = discountWrapper.data.hoverList.find((item) => item.type == '2') || {},
				salePriceItem = discountWrapper.data.hoverList.find((item) => item.type == '3') || {},
				couponItem = discountWrapper.data.hoverList.find((item) => item.type == '4') || {},
				preTag_pre = preTag.match(/(\S*)\|/)[1] || '',
				preTag_sur = parseYen(preTag.match(/\|(\S*)/)[1]) || '',
				postTag_pre = parseYen(postTag.match(/(\S*)&yen;/)[0]) || '优惠后¥',
				postTag_sur = postTag.match(/&yen;(\S*)/)[1] || '',
				ubtKeys = {
					show: 'flight_online_list_discount_wrapper_show',
					click: 'flight_online_list_discount_wrapper_click',
				};

			const promoteInfoList = [];
			const priceItem =
				flightPriceItem?.length && travelPackageItem?.title && window.GlobalSwitches.newTravelPackageLayer
					? [
							...(flightPriceItem || []).map(flightPrice=>{
								return {
									title: flightPrice.title,
									item: removeYen(flightPrice.item),
								}
							}),
							{
								title: travelPackageItem.title,
								item: removeYen(travelPackageItem.item),
							},
					  ]
					: [
							{
								title: originPriceItem.title,
								item: removeYen(originPriceItem.item),
							},
					  ];
			promoteInfoList.push(...priceItem);
			promoteInfoList.push({
				title: couponDescItem.title,
				item: couponDescItem.item.match(/&yen;(\S*)/)[1] || '',
			});
			promoteInfoList.push({
				title: salePriceItem.title,
				item: removeYen(salePriceItem.item),
			});

			const traceData = {
				isTravelPackage: travelPackageWrapper ? 1 : 0,
				source: 0,
				promoteInfoList,
			};

			const travelPackageDescribeItemBox =
			travelPackageDescribeItem?.title &&
			travelPackageDescribeItem?.item &&
			window.GlobalSwitches.newTravelPackageLayer ? (
				<>
					<div className="describe-info-line" />
					<div className="describe-info">
						<span>{travelPackageDescribeItem.title}：</span>
					</div>
					<div className="describe-info">
						<span>{travelPackageDescribeItem.item}</span>
					</div>
				</>
			) : null;

			return (
				// <div className={'flight-price domestic-cabin-price sub-price-detail sub-price-item lottery'}>
				<div className={'sub-price-item lottery'}>
					{showSalePrice ? (
						<div>
							<div className="post-prefix" id={`lotteryTag_${index}`}>
								<span>{postTag_pre}</span>
								<span className="post-suffix">{postTag_sur}</span>
								<i className="flightOnlineListIcon-ico_question"></i>
							</div>
							<ReactToolTip
								splitRound={splitRound}
								traceKey="S_FLT_N_Online_Middle_Below_Price_Discount_info"
								traceData={traceData}
								parentId={`lotteryTag_${index}`}
								key={`lotteryTooltip_${index}`}
								toolTipContent={
									<Tooltip extClass="tooltip-bookAndDiscount">
										<div className="content">
											<div className="travelPack-main-title">价格说明</div>
											<div className="travelPack-content-body">
												<PriceBox
													flightPriceItem={flightPriceItem}
													travelPackageItem={travelPackageItem}
													originPriceItem={originPriceItem}
													limitTime={limitTime}
												></PriceBox>
												<div className="price-info">
													<span>{couponDescItem.title}</span>
													<span>{removeYenTostring(couponDescItem.item)}</span>
												</div>
												<div className="main">
													<p>{removeYenTostring(couponDescItem.desc)}</p>
												</div>
												<div className="after-price-style">
													<span className="after-price-title">{salePriceItem.title}</span>
													<span className="after-main-price">
														¥<span>{removeYen(salePriceItem.item)}</span>
													</span>
												</div>
												{travelPackageDescribeItemBox}
											</div>
										</div>
									</Tooltip>
								}
							/>
						</div>
					) : (
						<ExposeStat ubtKey={ubtKeys.show}>
							<a
								target="_blank"
								onClick={() => {
									lightUpload(ubtKeys.click, {});
									showLotteryDialog();
								}}
							>
								<i className="icon-redpack icon"></i>
								<span className="prefix">{preTag_pre}</span>
								<span className="suffix">{preTag_sur}</span>
								<i className="flightOnlineListIcon-WebForward"></i>
							</a>
						</ExposeStat>
					)}
					{lotteryVisible && (
						<LotteryDialog
							visible={lotteryVisible}
							onClose={closeLotteryDialog}
							price={price}
							brandPrice={brandPrice}
							isFromBrandPrice={isFromBrandPrice}
							onClickBookBtn={onClickBookBtn}
							dataMinrefundfee={dataMinrefundfee}
							dataMinrefundfeesign={dataMinrefundfeesign}
							allGeneralCoupons={allGeneralCoupons}
							index={index}
							isFromOrderBtn={isFromOrderBtn}
							couponItem={parseYen(couponItem.item)}
						/>
					)}
				</div>
			);
		}
		return null;
	};

	LIST_UBT_KEY_TYPES.SNATCH_BOOKING_SHOW.value.process(rapidBook);

	let isSpringMember = priceTags.some((item) => {
		return item.get('type') == 'SpringMember';
	});
	const priceSummary = extractPriceSummaryAsString(price, flight),
		brandOrChooseRemark = brandOrChooseInfo ? brandOrChooseInfo.remark : '',
		flightWay = prevCond.get('flightWayEnum').key,
		bookButtonRemark = `${
			anyRelatedPrices ? `展开/折叠${brandOrChooseRemark}运价` : `预订${brandOrChooseRemark}运价航班`
		}[priceSummary:${priceSummary}]${
			anyRelatedPrices
				? expandRelatedPrices
					? `展开->收起${brandOrChooseRemark}运价`
					: `收起->展开${brandOrChooseRemark}运价`
				: ''
		}`,
		brandOrChooseExpandButtonText = brandOrChooseInfo ? brandOrChooseInfo.buttonText : '',
		toggleRelatedPricesButton = brandOrChooseInfo ? (
			<button
				u_key="book"
				u_remark={bookButtonRemark}
				id={index}
				className={`btn btn-book ${ticketCount ? ' less-ticket' : ''} ${
					anyRelatedPrices && expandRelatedPrices ? 'ghost' : ''
				} ${rapidBook ? 'snatch' : ''}`}
				data-minrefundfee={dataMinrefundfee}
				data-minrefundfeesign={dataMinrefundfeesign}
				data-allgeneralcoupons={allGeneralCoupons}
				onClick={
					anyRelatedPrices
						? (ev) => {
								getIfScopeDomestic() &&
									UbtHandler.onUploadData('C_FLT_Online_BookTicket', {
										Airline,
										AirlineNumber,
										ClassTab: cabinNew,
										BookTicketType: 0,
										Type: anyRelatedPrices && expandRelatedPrices ? '收起' : '展开',
									});
								onToggleExpandRelatedPrices(ev);
						  }
						: (ev) => {
								getIfScopeDomestic() &&
									UbtHandler.onUploadData('C_FLT_Online_BookTicket', {
										Airline,
										AirlineNumber,
										ClassTab: cabinNew,
										BookTicketType: 1,
									});
								onClickBookBtn(price, ev);
								ev.stopPropagation();
						  }
				}
			>
				{anyRelatedPrices ? (
					expandRelatedPrices ? (
						<React.Fragment>
							{'收起'}
							{splitRoundFlightsSwitch ? <i className="icon-arrow">&#xe78e;</i> : null}
						</React.Fragment>
					) : (
						brandOrChooseExpandButtonText
					)
				) : splitRoundFlightsSwitch ? (
					'订'
				) : (
					'预订'
				)}
			</button>
		) : null,
		toggleRelatedPriceButtonWrapper =
			!toggleRelatedPricesButton && !anyRelatedPrices ? (
				<UbtBoundary
					skip={anyRelatedPrices}
					tracker={ListUbtGenerator(
						LIST_UBT_GROUP_TYPES.BOOK_BUTTON_CLICK,
						LIST_UBT_KEY_TYPES.BOOK_BUTTON_CLICK
					)}
				>
					{toggleRelatedPricesButton}
				</UbtBoundary>
			) : (
				toggleRelatedPricesButton
			),
		productTagId = `${productTypeWrapper.tag.key}_tag_${index}`,
		productTypeTagTooltip = productIsNone ? null : (
			<ReactToolTip
				splitRound={splitRound}
				parentId={productTagId}
				toolTipContent={
					productTypeWrapper.description ? (
						<Tooltip content={productTypeWrapper.description} />
					) : typeof productTypeWrapper.tag.value.getDeferredLabelTooltip === 'function' ? (
						productTypeWrapper.tag.value.getDeferredLabelTooltip(productTagId, getPenaltyDetailPromise)
					) : (
						productTypeWrapper.tag.value.getLabelTooltip(productTypeWrapper, price, currentSegmentSeq)
					)
				}
			/>
		),
		eligibility = price.get('eligibility'),
		studentType = price.get('stuType'),
		eligibilityIsStu = eligibility === 'STU',
		getOverseasStudentAge = () => {
			let age = '';
			restrictionList.map((restrict) => {
				if (restrict.get('type') === 'Age') {
					age = restrict.get('label').replace('的乘客', '');
				}
			});
			return age;
		},
		age = `适用年龄${getOverseasStudentAge()}，`,
		overseasStudentConfig =
			window.GlobalConfigs.overseasStudentConfig &&
			window.GlobalConfigs.overseasStudentConfig.replace('[AGE]', age),
		studentHoverConfig =
			window.GlobalConfigs.studentHoverConfig && window.GlobalConfigs.studentHoverConfig.replace('[AGE]', age),
		extDataProductTypeWrapper = price.get('extDataProductTypeEnum'),
		extDataProductTagId = `${extDataProductTypeWrapper && extDataProductTypeWrapper.tag.key}_tag_${index}`,
		extDataProductTypeTagTooltip = productIsNone ? null : extDataProductTypeWrapper ? (
			<ReactToolTip
				splitRound={splitRound}
				parentId={extDataProductTagId}
				toolTipContent={
					extDataProductTypeWrapper.description ? (
						<Tooltip content={extDataProductTypeWrapper.description} />
					) : typeof extDataProductTypeWrapper.tag.value.getDeferredLabelTooltip === 'function' ? (
						extDataProductTypeWrapper.tag.value.getDeferredLabelTooltip(
							extDataProductTagId,
							getPenaltyDetailPromise
						)
					) : (
						extDataProductTypeWrapper.tag.value.getLabelTooltip(
							extDataProductTypeWrapper,
							price,
							currentSegmentSeq
						)
					)
				}
			/>
		) : null,
		getInternationalTaxText = () => {
			return (sortContainsTax && !splitRoundFlightsSwitch) || hideAllPriceTags ? null : (
				<div className="taxation">
					{splitRoundFlightsSwitch && sortContainsTax ? (
						flightWayEnum.getPriceText(isMultiplePassengerType, sortContainsTax)
					) : (
						<React.Fragment>
							{flightWayEnum.getTaxText(isMultiplePassengerType, splitRoundFlightsSwitch)}
							<dfn>&yen;</dfn>
							{price.get('avgTax')}
						</React.Fragment>
					)}
				</div>
			);
		},
		taxText = scopeFunctionAndExec({ i: getInternationalTaxText, d: () => null }),
		//原价渲染
		ReferenceSalePriceComponent = () => {
			if (hasReferenceSalePrice) {
				const finalPrice = sortContainsTax ? referenceSalePrice + price.get('avgTax') : referenceSalePrice;
				return <span className="reference-Sale-Price">¥{finalPrice}</span>;
			} else {
				return null;
			}
		},
		internationalFlightPrice = () => {
			return (
				<PriceChangePopover
					isTheMinPrice={isTheMinPrice}
					price={priceToShow}
					currentSegmentSeq={currentSegmentSeq}
					flightWay={flightWayEnum.key}
				>
					<div className="price-box">
						<div
							id={`price_${index}`}
							className={classnames({
								price: true,
								'price-b': sortContainsTax,
								abbr: isMultiplePassengerType,
								'low-price': isTheMinPrice,
							})}
						>
							{hideAllPriceTags ? null : (
								<React.Fragment>
									{hasReferenceSalePrice ? ReferenceSalePriceComponent() : null}
									<dfn>&yen;</dfn>
									{priceToShow}
									{hasReferenceSalePrice ? <span className="tag-unit">/人</span> : null}
								</React.Fragment>
							)}
						</div>
						{servicePackage && (
							<div className="package-price">
								<React.Fragment>
									+<dfn>&yen;</dfn>
									{servicePackage}出行礼包
								</React.Fragment>
							</div>
						)}

						{isMultiplePassengerType ? (
							<ReactToolTip
								splitRound={splitRound}
								parentId={`price_${index}`}
								toolTipContent={flightWayEnum.getPriceTooltip(
									prevCond.get('adultCount'),
									prevCond.get('childCount'),
									prevCond.get('infantCount'),
									price,
									sortContainsTax
								)}
							/>
						) : null}
						{taxText}

						{productIsNone ? null : (
							<div className="special">
								{productTypeWrapper.tag.value.getBookNotice(
									productTypeWrapper,
									`${productTypeWrapper.tag.key}_notice_${index}`
								)}
								<ReactToolTip
									splitRound={splitRound}
									parentId={`${productTypeWrapper.tag.key}_notice_${index}`}
									toolTipContent={productTypeWrapper.tag.value.getBookNoticeTooltip(price)}
								/>
							</div>
						)}
					</div>
				</PriceChangePopover>
			);
		},
		domesticFlightPrice = () => {
			const containsLowCabin = flight.get('containsLowCabin');
			return flightWayEnum.getDomesticPrice(
				{
					adultCount: prevCond.get('adultCount'),
					childCount: prevCond.get('childCount'),
					infantCount: prevCond.get('infantCount'),
					priceItem: price,
					cabinEnum: prevCond.get('cabinEnum'),
					allSegmentList: allSegmentList,
					containsLowCabin: containsLowCabin,
					isMinPrice: isTheMinPrice,
					isMinPriceInTheFlight: isMinPriceInTheFlight,
					flightWay: flightWay,
					servicePackagItem: priceFragment.ServicePackagItem,
					showCabin: false,
					index,
					travelPackageWrapper,
				},
				getLotteryInfo(),
				getOptmizedTag(optimizedDiscountWrapper, travelPackageWrapper, splitRound, index)
			);
		},
		priceDetail = scopeFunctionAndExec({ i: internationalFlightPrice, d: domesticFlightPrice }),
		internationalCabinDetail = scopeFunctionAndExec({
			i: () => (
				<React.Fragment>
					<span
						id={!containsVirtualFlight ? `seatType_${index}` : ''}
						className={classnames({
							type: true,
							highlight: !containsVirtualFlight && (isDifferentCabin || manyCabins),
							abbr: manyCabins,
							'lcc-service-tag': lccServiceWrapper,
						})}
					>
						{brandWrapper && brandWrapper.data && brandWrapper.data.cabinName
							? brandWrapper.data.cabinName
							: getCabinListTitle(cabinEnumList, allSegmentList)}
					</span>
					{manyCabins || lccServiceWrapper ? (
						<ReactToolTip
							splitRound={splitRound}
							parentId={`seatType_${index}`}
							toolTipContent={tooltipContent}
						/>
					) : null}
				</React.Fragment>
			),
			d: () => null,
		}),
		domesticFlightCabinDetail = scopeFunctionAndExec({
			i: () => null,
			d: () => {
				let allSegmentCabin = Converter.getDomesticCabinContent({
					cabinEnumList,
					flightSeatList: price.getIn(['priceUnitList', 0, 'flightSeatList']),
					relatedSegmentList,
					flightWayEnum,
					selected,
				});

				return (
					<div className="domestic-seat-type">
						{Object.keys(allSegmentCabin).map((segmentNo, i) => {
							let { cabins, prefix } = allSegmentCabin[segmentNo],
								cabinSize = cabins.length;
							return (
								<div className="domestic-cabin-item" key={i}>
									{prefix ? <span className="seatType_prefix">{prefix}</span> : null}
									{cabins.map((cabin, index) => {
										let { title, tooltipContent } = cabin,
											id = `seatType_${flightIndex}_${priceIndex}_${i}_${index}`,
											showPuls = !(index + 1 == cabinSize);

										return (
											<React.Fragment key={index}>
												<span
													id={id}
													className={classnames({ 'domestic-special-cabin': tooltipContent })}
												>
													{title}
												</span>
												{showPuls ? ' + ' : ''}
												{tooltipContent && (
													<ReactToolTip
														splitRound={splitRound}
														parentId={id}
														toolTipContent={
															<div className="tooltip-content">
																<div className="tooltip ">{tooltipContent}</div>
															</div>
														}
													/>
												)}
											</React.Fragment>
										);
									})}{' '}
								</div>
							);
						})}
					</div>
				);
			},
		});

	return (
		<React.Fragment>
			<div
				onClick={(ev) => {
					ev.stopPropagation();
				}}
				channel={price.get('channel')}
				invoicetype={price.get('invoiceType')}
				className={classnames('seat-row', 'seat-row-v3', {
					'brand-price-show':
						splitRoundFlightsSwitch && brandOrChooseInfo && anyRelatedPrices && expandRelatedPrices,
					'more-padding': tagRenderOfLeft,
					'seat-row-domestic': getIfScopeDomestic(),
					'has-ticket-count-label': ticketCount > 0,
				})}
			>
				{tagRenderOfLeft}
				<div className="seat-type">
					{/* 仓位信息 */}
					{internationalCabinDetail}
					{!productIsNone ? (
						<div className={'special' + (extDataProductTypeWrapper ? ' extData-special' : '')}>
							{extDataProductTypeWrapper
								? extDataProductTypeWrapper.tag.value.getDeferredLabel
									? extDataProductTypeWrapper.tag.value.getDeferredLabel(
											extDataProductTypeWrapper,
											extDataProductTagId,
											extDataProductTypeTagTooltip
									  )
									: extDataProductTypeWrapper.tag.value.getLabel(
											extDataProductTypeWrapper,
											`${extDataProductTypeWrapper.tag.key}_tag_${index}`
									  )
								: null}
							{extDataProductTypeWrapper
								? extDataProductTypeWrapper.tag.value.wrapLabelTooltip
									? null
									: extDataProductTypeTagTooltip
								: null}
							{productTypeWrapper.tag.value.getDeferredLabel
								? productTypeWrapper.tag.value.getDeferredLabel(
										productTypeWrapper,
										productTagId,
										productTypeTagTooltip
								  )
								: productTypeWrapper.tag.value.getLabel(
										productTypeWrapper,
										`${productTypeWrapper.tag.key}_tag_${index}`
								  )}
							{productTypeWrapper.tag.value.wrapLabelTooltip ? null : productTypeTagTooltip}
						</div>
					) : null}
					{productIsNone && eligibilityIsStu ? (
						<React.Fragment>
							<span id={`STU_${index}`} className="overseas-stu-tag">
								{studentType === '2' ? '学生' : '留学生'}
								<em>专享</em>
							</span>
							<ReactToolTip
								splitRound={splitRound}
								parentId={`STU_${index}`}
								toolTipContent={
									<Tooltip
										content={studentType === '2' ? studentHoverConfig : overseasStudentConfig}
									/>
								}
							/>
						</React.Fragment>
					) : null}
				</div>

				<div className={`seat-info`}>
					<React.Fragment>
						{penaltyTag && !hideAllPriceTags ? (
							<UbtBoundary
								tracker={ListUbtGenerator(
									LIST_UBT_GROUP_TYPES.UPLOAD_NOW_MOUSE_ENTER,
									LIST_UBT_KEY_TYPES.PENALTY_HOVER
								)}
							>
								{priceFragment.PenaltyFragment}
							</UbtBoundary>
						) : null}

						{!hideAllPriceTags ? (
							<UbtBoundary
								tracker={ListUbtGenerator(
									LIST_UBT_GROUP_TYPES.UPLOAD_NOW_MOUSE_ENTER,
									LIST_UBT_KEY_TYPES.NGS_SCORE_HOVER
								)}
							>
								{priceFragment.NgsFragment}
							</UbtBoundary>
						) : null}

						{hideAllPriceTags ? null : priceFragment.InvoiceFragment}
						{passengerRestrictionTag ? (
							<div className={`limit-box ${flightWay !== 'OW' ? 'limit-box-block' : ''}`}>
								{passengerRestrictionTag}
							</div>
						) : null}
						{airportDiscountRestriction ? (
							<div className={`limit-box ${flightWay !== 'OW' ? 'limit-box-block' : ''}`}>
								<span className="ap-discount">{airportDiscountRestriction.label}</span>
							</div>
						) : null}
						{servicePackage ? (
							<UbtBoundary
								tracker={ListUbtGenerator(
									LIST_UBT_GROUP_TYPES.UPLOAD_NOW_MOUSE_ENTER,
									LIST_UBT_KEY_TYPES.SERVICE_PACKAGE_HOVER
								)}
							>
								{priceFragment.ServicePackageFragment}
							</UbtBoundary>
						) : null}
						{
							<CommonRestrictionComp rightsInfo={rightsInfo}></CommonRestrictionComp>
						}
					</React.Fragment>
				</div>
				{/* 国内仓等显示区域 */}
				{domesticFlightCabinDetail}

				{hideAllPriceTags ? (
					<div className="seat-tags" />
				) : (
					<div className="seat-tags">
						{goodBrandPriceTag}
						{nonGeneralTagsOfMiddle}
						{generalTagsOfMiddle}
						{membershipTagsOfMiddle}
						<RightsInfo rightsInfo={rightsInfo} getId={getId} ></RightsInfo>
					</div>
				)}

				<div className={`seat-operate  ${ticketCount ? 'remain-tickets' : ''}`}>
					<div className="operate">
						{!!debugInfo && (
							<>
								<div id={`debug_${index}`} className="debug-tag">
									<img src="https://pages.c-ctrip.com/flight_h5/swift/debug.png" alt=""></img>
								</div>
								<ReactToolTip
									splitRound={splitRound}
									parentId={`debug_${index}`}
									toolTipContent={<DebugInfo info={debugInfo} />}
								/>
							</>
						)}
						<div className="seat-price">{priceDetail}</div>
						<div className={`seat-action`}>
							<UbtBoundary
								tracker={ListUbtGenerator(
									LIST_UBT_GROUP_TYPES.SNATCH_BOOKING_SHOW,
									LIST_UBT_KEY_TYPES.SNATCH_BOOKING_SHOW
								)}
							>
								{brandOrChooseInfo ? (
									anyRelatedPrices ? (
										<UbtBoundary
											tracker={ListUbtGenerator(
												LIST_UBT_GROUP_TYPES.BRAND_PRICE,
												LIST_UBT_KEY_TYPES.BRAND_PRICE_LOAD,
												priceSummary
											)}
										>
											{toggleRelatedPriceButtonWrapper}
										</UbtBoundary>
									) : (
										toggleRelatedPriceButtonWrapper
									) //brandWrapper 不为空即表示当前运价是东航品牌运价，另外还要考虑 relatedPreices
								) : (
									<UbtBoundary
										skip={anyRelatedPrices}
										tracker={ListUbtGenerator(
											LIST_UBT_GROUP_TYPES.BOOK_BUTTON_CLICK,
											LIST_UBT_KEY_TYPES.BOOK_BUTTON_CLICK,
											{ rapidBook },
											{ isSpringMember: isSpringMember }
										)}
									>
										<button
											u_key="book"
											u_remark={`预订航班[priceSummary:${priceSummary}]`}
											id={index}
											className={`btn btn-book ${ticketCount ? ' less-ticket' : ''} ${
												rapidBook ? 'snatch' : ''
											}`}
											data-minrefundfee={dataMinrefundfee}
											data-minrefundfeesign={dataMinrefundfeesign}
											data-allgeneralcoupons={allGeneralCoupons}
											onClick={(ev) => {
												if (discountWrapper && !showSalePrice) {
													getIfScopeDomestic() &&
														UbtHandler.onUploadData('C_FLT_Online_BookTicket', {
															Airline,
															AirlineNumber,
															ClassTab: cabinNew,
															BookTicketType: 3,
														});
													setIsFromOrderBtn(true);
													showLotteryDialog();
												} else {
													getIfScopeDomestic() &&
														UbtHandler.onUploadData('C_FLT_Online_BookTicket', {
															Airline,
															AirlineNumber,
															ClassTab: cabinNew,
															BookTicketType: rapidBook ? 2 : 1,
														});
													onClickBookBtn(price, ev);
													ev.stopPropagation();
												}
											}}
											onMouseEnter={() => onBookBtnMouseEvent('mouseEnter')}
											onMouseLeave={() => onBookBtnMouseEvent('mouseLeave')}
										>
											{rapidBook ? '抢订' : '预订'}
										</button>
									</UbtBoundary>
								)}
							</UbtBoundary>
							{ticketCount && ticketCount < 5 ? (
								<div className="book-tooltip">
									<span className="tooltip-tail"></span>
									<span className="tooltip-tail-bottom"></span>
									<div className="tooltip-content">剩{ticketCount}张</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
				{priceTagEnums.map(
					(priceTag, index) =>
						priceTag.tag.key == 'CheaperPlusAnotherTrip' &&
						priceTag.tag.value.getExtFlightInfo &&
						priceTag.tag.value.getExtFlightInfo(
							priceTag,
							sortContainsTax,
							theMinPriceItem,
							priceList,
							getId(priceTag.tag.key + '_ext', index),
							onPopupFlightDetail,
							tongChengFlightEnum
						)
				)}
			</div>

			{anyRelatedPrices && (
				<CSSTransitionGroup
					transitionName="brand-price"
					transitionEnterTimeout={300}
					transitionLeaveTimeout={400}
				>
					{anyRelatedPrices && expandRelatedPrices ? (
						<div
							onClick={(ev) => {
								ev.stopPropagation();
							}}
							className="seat-row seat-row-v3 has-related-price"
						>
							{/* 包括原始运价自身，最多支持显示4个 */}
							{relatedPrices
								.insert(0, price)
								.sort((prev, next) => prev.get('avgPriceWithTax') - next.get('avgPriceWithTax'))
								.filter((_, index) => index <= MAX_EXPANDED_PRICE_COUNT - 1) // 最多显示4个，索引号最大是3，超出的运价则隐藏
								.map((innerPrice, theSeq) => (
									<brandOrChooseInfo.renderEl
										{...props}
										price={innerPrice}
										compactMode={relatedPrices.size >= MAX_EXPANDED_PRICE_COUNT - 1}
										seq={theSeq}
										index={`${index}_${theSeq}`}
										relatedPrices={relatedPrices.insert(0, price)}
										key={innerPrice.get('routeSearchToken')}
										onClickBookBtn={onClickBookBtn}
										mileagePointSwitch={mileagePointSwitch}
										discountWrapper={discountWrapper}
										showSalePrice={showSalePrice}
										setIsFromOrderBtn={setIsFromOrderBtn}
										showLotteryDialog={showLotteryDialog}
										setBrandPrice={setBrandPrice}
										setIsFromBrandPrice={setIsFromBrandPrice}
									/>
								))}
						</div>
					) : null}
				</CSSTransitionGroup>
			)}
		</React.Fragment>
	);
};

export default Price;
