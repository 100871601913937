import React, { useCallback, useState, useEffect, useRef } from 'react';
import PopDialog from '../list/common/PopDialog';
import { UED } from '../list/popups/styleConst';
import { UbtHandler } from '../../ubt.v2/handler';
import { ExposeStat } from '../../ubt.v2/exposeStat';

import { RUNTIME_SERVER, PAGE_LIST_URL } from '../../constants/common';

const LotteryAlertContent = ({
  rootRef,
  source,
  popupExposure,
  bookingClick,
  detailClick,
  closeClick,
  toLottery,
  closePopDialog,
  lotteryStatus,
}) => {
  return (
    <ExposeStat ubtKey={popupExposure}>
      <div className={'lottery-popup'} style={UED.popupMargin} ref={rootRef}>
        <i
          className="ico-close-b"
          onClick={() => {
            closePopDialog(), UbtHandler.onUploadData(closeClick);
          }}
        ></i>
        <dl>
          <dd className="prize-img"></dd>
          <dt className="prize-txt">
            <div className="prize-tit"></div>
            {lotteryStatus == 'WARM_UP' ? (
              <div className="warm-up">活动预热中...</div>
            ) : (
              <div
                className={
                  source && source === 'list' ? 'continue-btn' : 'start-btn'
                }
                onClick={() => {
                  closePopDialog(), UbtHandler.onUploadData(bookingClick);
                }}
              ></div>
            )}
            <div className="prize-detail">
              <a
                onClick={() => {
                  toLottery(), UbtHandler.onUploadData(detailClick);
                }}
              >
                了解详情
              </a>
            </div>
          </dt>
        </dl>
      </div>
    </ExposeStat>
  );
};

const LotteryAlertPopup = ({
  source,
  popupExposure,
  bookingClick,
  detailClick,
  closeClick,
  lotteryAlertPopupShow,
  onLotteryAlertPopupClose,
  lotteryStatus,
  floatLotteryEl,
}) => {
  const toLottery = useCallback(() => {
      const url = `${RUNTIME_SERVER}/lottery?from=${source}&originpathname=${encodeURIComponent(
        window.location.href,
      )}`;

      window.open(url);
    }),
    rootRef = useRef(),
    [movePath, setMovePath] = useState({ translateX: 0, translateY: 0 }),
    closePopDialog = useCallback(() => {
      let poppupOffset =
        rootRef && rootRef.current
          ? rootRef.current.getBoundingClientRect()
          : { top: 0, left: 0 }; // 此处也会触发 reflow
      const { top, left } = floatLotteryEl
        ? floatLotteryEl.getBoundingClientRect()
        : { top: 452, left: 1450 };

      setMovePath({
        translateY: top - poppupOffset.top,
        translateX: left - poppupOffset.left,
      });
    }, [floatLotteryEl, rootRef]);
  useEffect(() => {
    let closed = document.querySelector('.lottery-popup') || null;
    if (!closed) {
      return;
    }
    closed.classList.add('lottery-popup-close-1');
    setTimeout(() => {
      onLotteryAlertPopupClose();
    }, 300);
  }, [movePath]);

  return lotteryAlertPopupShow ? (
    <React.Fragment>
      <style>
        {`
            @keyframes close_com1{
                0%{
                    transform: translate(0,0) scale(1);
                }
                100%{
                    transform-origin: 0 0;
                    transform: translate(${movePath.translateX}px ,${movePath.translateY}px) scale(0.2, 0.4);
                }
            }

            .lottery-popup.lottery-popup-close-1 {
            animation:close_com1 .3s linear 1;
            -webkit-animation:close_com1 .3s linear 1;
    }`}
      </style>
      <PopDialog
        lotteryPopup="lotteryPopup"
        style={{ zIndex: 2001 }}
        contents={LotteryAlertContent({
          rootRef,
          source,
          popupExposure,
          bookingClick,
          detailClick,
          closeClick,
          toLottery,
          closePopDialog,
          lotteryStatus,
        })}
      />
    </React.Fragment>
  ) : null;
};
export default LotteryAlertPopup;
