import React from 'react'
import ReactTooltip from  'react-tooltip'

const FlightTag = ({...props}) => {
    const {
        name,
        description,
        tagkey,
        isolate,
        updateToolTip,
        className,
    } = props
    // getContent={[() => {return new Date().toISOString()}, 1000]}
    let tooltipElem = !isolate ?
    <ReactTooltip className="c-react-tip" id={tagkey} aria-haspopup='true'
        effect="solid"  place="bottom" type="light"
        html={true} data-multiline={true}>{description}
    </ReactTooltip> : null

    return (
        <span>
            <span onMouseOver={isolate && updateToolTip}
                style={{display: 'inline-block'}} data-tip="React-tooltip" data-for={tagkey}
                className={className || 'icon-tag'}>{name}
            </span>
            {tooltipElem}
        </span>
    )
}

export default FlightTag
