import React from 'react';
import ToolTip from 'rc-tooltip';

export default class PriceChangePopover extends React.Component {
  static selectedPrice;
  static updateSelectedPrice(price) {
    PriceChangePopover.selectedPrice = price;
  }
  state = {
    show: true,
  };
  componentDidMount() {
    this.closeTimeout = setTimeout(() => {
      this.setState({
        show: false,
      });
    }, 5000); // 5秒后消失
  }
  compDom;
  getPopover() {
    const { price } = this.props;
    const selectedPrice = PriceChangePopover.selectedPrice;
    if (!selectedPrice || price === selectedPrice) return false;
    if (!this.compDom) return false;
    const clientRect = this.compDom.getBoundingClientRect();
    const top = 20 + (this.props.top || 0);
    const right = document.body.clientWidth - clientRect.right;
    const popover = (
      <React.Fragment>
        <div
          className="PriceChangePopoverContent"
          style={Object.assign({
            top,
            right,
          })}
        >
          机票价格实时变化，您所选价格有小幅
          {price - selectedPrice > 0 ? '上涨' : '下降'}
        </div>
        <div
          className="arrow-top"
          style={{ top: top - 37, right: right + 10 }}
        ></div>
      </React.Fragment>
    );

    return popover;
  }

  showPopover() {
    const { show, isTheMinPrice, currentSegmentSeq, flightWay } = this.props;
    if (!isTheMinPrice || currentSegmentSeq === 0 || flightWay !== 'M' || !show)
      return false;

    return true;
  }

  render() {
    const { children } = this.props;
    const { show } = this.showPopover();
    if (!show) {
      return children;
    }
    const popover = this.getPopover();
    return (
      <ToolTip
        visible={!!popover}
        overlayClassName="PriceChangePopover"
        overlay={popover}
      >
        <div ref={e => (this.compDom = e)} style={{ display: 'inline-block' }}>
          {children}
        </div>
      </ToolTip>
    );
  }
  componentWillUnmount() {
    clearTimeout(this.closeTimeout);
  }
}
