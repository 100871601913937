import React from 'react';
import ReactToolTip from '@/src/components/Base/reactToolTip';

// 华夏通程航班描述文案
export const TongChengRemark = ({ cleanItineraryId, tongChengFlightEnum, currentSegmentSeq }) => {
  const detailId = `tc_detail_${cleanItineraryId}`,
    anyTag = !!(tongChengFlightEnum.data.tags && tongChengFlightEnum.data.tags.length),
    title = (tongChengFlightEnum?.label || '华夏联程') + '专享：',
    contactNotice = tongChengFlightEnum.data.segments && tongChengFlightEnum.data.segments[currentSegmentSeq] && tongChengFlightEnum.data.segments[currentSegmentSeq].contactNotice;

  return (
		<React.Fragment>
			{!!(anyTag || tongChengFlightEnum.data.bookingNotice) && (
				<>
					<div className="flight-exclusive">
						{anyTag && (
							<React.Fragment>
								<p>
									{title}
									<span className="highlight">
										{tongChengFlightEnum.data.tags.map((tag) => tag.key).join('、')}
									</span>
									<a id={detailId} href="javascript:;" className="link">
										详情
									</a>
								</p>
							</React.Fragment>
						)}
						{!!tongChengFlightEnum.data.bookingNotice && (
							<p>预订须知：{tongChengFlightEnum.data.bookingNotice}</p>
						)}
					</div>
					{anyTag && (
						<ReactToolTip
							parentId={detailId}
							toolTipContent={
								<TongChengHover
									contactNotice={contactNotice}
									tags={tongChengFlightEnum.data.tags}
									title={title}
								/>
							}
						/>
					)}
				</>
			)}
		</React.Fragment>
  );
};

const tooltipMargin = {
  verticalAlign: 'top',
  textAlign: 'left',
};

//通程航班hover详情
const TongChengHover = ({ tags, contactNotice, title }) => {
  return (
    <div className="tooltip tooltip-exclusive" style={tooltipMargin}>
      <div className="tooltip-content">
        <h3>{title}</h3>
        {contactNotice && <p>{contactNotice}</p>}
        {tags.map(tag => (
          <div key={tag.key} className="exclusive-list">
            <h4>{tag.key}</h4>
            <p>{tag.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
