import React from 'react'
import classnames from 'classnames'
import { extractPriceSummaryAsString } from '../../../../sources/common/extractPriceSummary'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list'

const getMaxAttrsCount = relatedPrices => {
    let max = 0
    if (relatedPrices && relatedPrices.size) {
        relatedPrices.valueSeq().forEach(price => {
            const remarks = price.getIn(['xDetail', 'remarks'])

            if (remarks && remarks.size > max) {
                max = remarks.size
            }
        })
    }

    return max
}

const getHeightOfCard = (relatedPrices, hasRestrict) => {
    const attrsCount = getMaxAttrsCount(relatedPrices) + (hasRestrict ? 1 : 0)
    const lineHeight = 22

    return attrsCount * lineHeight + 40
}

export const ChooseXPrice = (props) => {
    const { price, onClickBookBtn, flight, relatedPrices, compactMode, discountWrapper, showSalePrice, setIsFromOrderBtn, showLotteryDialog, setBrandPrice, setIsFromBrandPrice } = props

    const containsX = !!price.get('xDetail')
    const xTitle = containsX ? price.getIn(['xDetail', 'title']) : '放弃优惠，直接预订'
    const xPrice = containsX ? price.getIn(['xDetail', 'price']) : 0
    const xRemarkList = price.getIn(['xDetail', 'remarks'], [])
    const xVersion =  containsX ? price.getIn(['xDetail', 'insuranceVersion']) : ''
    const chooseXWrapper = price.get('chooseXWrapper')

    if (!chooseXWrapper) {
        console.error(`X选购信息为空，extractPriceSummaryAsString：${extractPriceSummaryAsString(price, flight)}`)
        return null
    }

    const ticketCount = price.get('ticketCount')

    const cardHeight = getHeightOfCard(relatedPrices),
        cardStyle = { style: { height: cardHeight } }

    const priceSummary = extractPriceSummaryAsString(price, flight)

    return (
        <div className={classnames('brand-price-card no-min-height', { 'compact-mode': compactMode })}>
            <div className='brand-price-card-title'>
                <div className='main-card-title'>
                    <span className='high-light'>
                        +&yen;{xPrice}
                    </span>
                    &nbsp;{xTitle}<span className='ins-version'>{xVersion}</span></div>
            </div>

            <div className='brand-price-card-content' {...cardStyle}>
                <ul>
                    {xRemarkList.map(remark => (
                        <li key={remark} className={`brand-price-entry brand-entry-medium`}>
                            <i className='brand-price-icon'></i>
                            <span className='brand-entry-text'>{remark}</span>
                        </li>
                    ))}
                </ul>

                <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BOOK_BUTTON_CLICK, LIST_UBT_KEY_TYPES.BOOK_BUTTON_CLICK)}>
                    <button u_key='book' u_remark={`预订三选X运价航班[priceSummary:${priceSummary}]`} className='btn btn-book book-operate'
                        data-minrefundfee='' data-minrefundfeesign='' onClick={ev => { if(discountWrapper && !showSalePrice) {
                          setIsFromOrderBtn(true);
                          setBrandPrice(price);
                          setIsFromBrandPrice(true);
                          showLotteryDialog();
                        } else { onClickBookBtn(price, ev); ev.stopPropagation() } }} >预订</button></UbtBoundary>
                {ticketCount ? <div className='rest-ticket'>仅剩{ticketCount}张</div> : null}
            </div>
        </div>
    )
}
