import React, { useMemo } from 'react';
import classnames from 'classnames';
import styles from './Progress.module.scss';

let prevRouterKey;
let switchLoadingIndex = false;

type ProgressV2Props = {
	finished: boolean;
	fixLowPriceCalendar: boolean;
	fixSortBar: boolean;
	routerKey: string;
	type: 'fix' | 'normal';
	className: string;
};

const ProgressV2 = ({ finished, fixLowPriceCalendar, fixSortBar, routerKey, type, className }: ProgressV2Props) => {
	if (!prevRouterKey) prevRouterKey = routerKey;
	if (prevRouterKey !== routerKey) {
		switchLoadingIndex = !switchLoadingIndex;
	}
	prevRouterKey = routerKey;

	const animationClassName = useMemo(() => {
		if (finished) {
			return styles['hide-loading'];
		}
		if (switchLoadingIndex) {
			if (type === 'fix') {
				return styles['reset-fly-loading-fix'];
			}
			return styles['reset-fly-loading'];
		}
		if (type === 'fix') {
			return styles['fly-loading-fix'];
		}
		return styles['fly-loading'];
	}, [finished, type]);

	const hiddenClass = useMemo(() => {
		if (type === 'fix') {
			if (!(fixSortBar || fixLowPriceCalendar)) {
				return styles['fix-loading-hidden'];
			}
		}
		if (type === 'normal') {
			if (fixSortBar || fixLowPriceCalendar) {
				return styles['fix-loading-hidden'];
			}
		}
		return '';
	}, [fixLowPriceCalendar, fixSortBar, type]);

	return (
		<div
			className={classnames(
				styles.loading,
				{
					[styles.fixed]: fixSortBar || fixLowPriceCalendar,
					[styles.lower]: fixLowPriceCalendar,
					[styles.process]: !finished,
					[styles[className]]: true,
				},
				hiddenClass,
				animationClassName
			)}
		/>
	);
};

export default ProgressV2;
