import React, { useEffect } from 'react';
import { sendTraceExpose } from '@/src/sources/common/lightUbt';
import ReactToolTip from '@/src/components/Base/reactToolTip';

const TimeUpdateTips = [
	{
		content:
			'机票价格受季节性需求、航线热度、预订时间及航班上座率等多种因素的影响，航空公司会进行实时调控，导致价格可能出现波动。',
	},
	{
		content: '建议您尽快下单以锁定当前价格。我们承诺将持续为您监控并努力获取最新票价。最终价格请以支付页面为准。',
	},
];

export const TimeUpdateTip = () => {
	useEffect(() => {
		sendTraceExpose('all_flt_middle_update_time_show', {
			channel_ct: 'ctrip_ol',
		});
	}, []);

	const updateTime = new Date().toLocaleTimeString('zh-CN', {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: false,
	});
	return (
		<>
			<div className={'time-update-tip'}>
				<span>*最近更新时间: {updateTime}</span>
				<img
					src="https://pages.ctrip.com/flight_h5/online/warning_gray.png"
					className="warningStyle"
					alt="time-update-tip"
					id="time-update-tip"
					data-testid="time-update-tip"
				></img>
			</div>
			<ReactToolTip
				key={'time-update-tip-layer'}
				parentId={'time-update-tip'}
				toolTipContent={
					<div className={'tipLayerContent'}>
						{TimeUpdateTips.map((item, index) => (
							<div className={`tipLayerItem ${index > 0 ? 'marginTop' : ''}`} key={index}>
								<span className={'tipIcon'} />
								<span>{item.content}</span>
							</div>
						))}
					</div>
				}
				traceKey={'all_flt_middle_update_time_popup_show'}
				traceData={{ channel_ct: 'ctrip_h5' }}
			/>
		</>
	);
};
