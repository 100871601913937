import React, { useContext } from 'react';
import classnames from 'classnames';
import ErrorBoundary from '@/src/components/Base/ErrorBoundary';

import FlightsContainer from '../../../containers/list/result/flights/flightsContainer';

import { FakeFlightsIndicator } from '../common/fakeFlightsIndicator';

import { SELECTED_FLIGHT_FIX_STATUS } from '../../../constants/list/enums/selectedFlightFixStatus.js';
import { ScopeInternationalContext } from '../../../sources/list/scopeInternationalContext';

import RecommendContainer from '../../../containers/list/result/recommend/recommendContainerAsync';
import NearByRecommend from './recommend/nearByRecommend/async';
import ThemeRecommend from './recommend/themeRecommendAsync';
import Recommend from './recommend/AdBarAsync';

export const Result = React.forwardRef(
	(
		{
			flights,
			isBuildUpMode,
			visibleFlights,
			searchNoResult,
			onBook,
			sortContainsTax,
			currentSegmentSeq,
			searchIsFinish,
			isResearching,
			onPopupFlightDetail,
			filterV2,
			splitRound,
			selectFlightItineryId,
			fixSortBar,
			ubtOriginFlight,
			prevCond,
			showRecommend,
			setShowRecommend,
			showNoresultRecommendHeader,
			setShowNearByRecommend,
			showNearByRecommend,
			nearByDateRecommendData,
			priceSortTypeGroupTitlesRef,
			adBarSwitch,
		},
		resultWrapperRef
	) => {
		// 往返分屏返程时候，visibleFlightsCount表示去程的航班数目
		// 分屏时候是否需要将航班各自拆分滚动条：只有在反查拿到航班并且筛选排序是吸顶，才左右拆分滚动条
		const anyFlights = !!flights.get('list').size,
			isBusy = (!anyFlights && !searchIsFinish) || isResearching,
			scopeInternational = useContext(ScopeInternationalContext);

		return (
			<React.Fragment>
				<div
					ref={resultWrapperRef}
					data-fix-status=""
					className={classnames({ 'result-wrapper': true })}
					style={{ position: 'relative' }}
				>
					<ErrorBoundary>
						<FakeFlightsIndicator isBusy={isBusy} splitRoundFlightsSwitch={false} />
					</ErrorBoundary>
					<ErrorBoundary>
						{
							<RecommendContainer
								ubtOriginFlight={ubtOriginFlight}
								filterV2={filterV2}
								onBook={onBook}
								prevCond={prevCond}
								onPopupFlightDetail={onPopupFlightDetail}
								showRecommend={showRecommend}
								showNearByRecommend={showNearByRecommend}
							/>
						}
					</ErrorBoundary>
					<ErrorBoundary>
						<FlightsContainer
							ubtOriginFlight={ubtOriginFlight}
							isBuildUpMode={isBuildUpMode}
							visibleFlights={visibleFlights}
							sortContainsTax={sortContainsTax}
							filterV2={filterV2}
							splitRound={splitRound}
							searchNoResult={searchNoResult}
							onBook={onBook}
							searchIsFinish={searchIsFinish}
							currentSegmentSeq={currentSegmentSeq}
							onPopupFlightDetail={onPopupFlightDetail}
							selectedFlightFixStatus={SELECTED_FLIGHT_FIX_STATUS.NORMAL}
							selectFlightItineryId={selectFlightItineryId}
							fixSortBar={fixSortBar}
							flightsMinHeight={null}
							updateTooFewFlights={null}
							priceSortTypeGroupTitlesRef={priceSortTypeGroupTitlesRef}
							adBarSwitch={adBarSwitch}
						/>
					</ErrorBoundary>
					<ErrorBoundary>
						<NearByRecommend
							prevCond={prevCond}
							setShowNearByRecommend={setShowNearByRecommend}
							showNoresultRecommendHeader={showNoresultRecommendHeader}
							nearByDateRecommendData={nearByDateRecommendData}
						/>
					</ErrorBoundary>
					<ErrorBoundary>
						{scopeInternational && (
							<ThemeRecommend
								prevCond={prevCond}
								setShowRecommend={setShowRecommend}
								showNoresultRecommendHeader={showNoresultRecommendHeader}
								showNearByRecommend={showNearByRecommend}
							/>
						)}
					</ErrorBoundary>
					<ErrorBoundary>
						{adBarSwitch && <Recommend classNames="bottom" adConfigKey="BOTTOM" prevCond={prevCond} />}
					</ErrorBoundary>
				</div>
			</React.Fragment>
		);
	}
);
