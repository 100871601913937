/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Dialog from 'rc-dialog';

import pageShortNameMap from '@ctrip/flight-online-style/constants/pageShortNameMap';
import parseBEM from '@ctrip/flight-online-style/utils/parseBEM';
import { lightUpload } from '@/src/sources/common/lightUbt';

import 'rc-dialog/assets/index.css';
import './lottery-dialog.scss';

const LotteryDialog = ({
	visible,
	onClose,
	price,
	brandPrice,
	onClickBookBtn,
	dataMinrefundfee,
	dataMinrefundfeesign,
	allGeneralCoupons,
	index,
	isFromOrderBtn,
	isFromBrandPrice,
	couponItem,
}) => {
	const c = parseBEM(pageShortNameMap.list)('lottery-dialog');

	const [btnText, setBtnText] = useState('正在抽奖');
	const {
		lottery_redpack_back = 'https://pages.c-ctrip.com/flight_h5/online/list_lottery/img_back_online@1x.svg',
		lottery_redpack_front = 'https://pages.c-ctrip.com/flight_h5/online/list_lottery/img_front_online@1x.png',
		lottery_redpack_content = 'https://pages.c-ctrip.com/flight_h5/online/list_lottery/coupon_online@1x.svg',
		lottery_redpack_close_btn = 'https://pages.c-ctrip.com/flight_h5/online/list_lottery/icon_float_close_online@1x.png',
		lottery_redpack_btn = 'https://pages.c-ctrip.com/flight_h5/online/list_lottery/img_button_online@1x.svg',
	} = window?.GlobalConfigs?.listPageConfig || {};

	useEffect(() => {
		setTimeout(updateBtnText, 250, '正在抽奖.');
		setTimeout(updateBtnText, 500, '正在抽奖..');
		setTimeout(updateBtnText, 750, '正在抽奖...');
		setTimeout(updateBtnText, 1000, '以优惠价订票');
		lightUpload('flight_online_list_discount_wrapper_trigger', {});
	}, [visible]);

	const updateBtnText = (text) => {
		setBtnText(text);
	};
	return (
		<Dialog className={c('$')} visible={visible} onClose={onClose} closable={false}>
			<div className={c('__redpack')} style={{ backgroundImage: `url(${lottery_redpack_back})` }}>
				<div className={c('__redpack--front')} style={{ backgroundImage: `url(${lottery_redpack_front})` }}>
					<div
						u_key="book"
						u_remark={`抽奖页跳转预订`}
						id={index}
						data-minrefundfee={dataMinrefundfee}
						data-minrefundfeesign={dataMinrefundfeesign}
						data-allgeneralcoupons={allGeneralCoupons}
						className={c('__redpack-item', '__btn')}
						style={{ backgroundImage: `url(${lottery_redpack_btn})` }}
						onClick={(ev) => {
							onClose();
							isFromBrandPrice ? onClickBookBtn(brandPrice, ev) : onClickBookBtn(price, ev);
							ev.stopPropagation();
						}}
					>
						{btnText}
					</div>
				</div>
				{visible ? (
					<div
						className={c('__redpack-item', '__content', '__content--active')}
						style={{ backgroundImage: `url(${lottery_redpack_content})` }}
					>
						<div className={c('__coupon')}>{couponItem}</div>
						<div className={c('__tips')}>仅本次订票可用</div>
					</div>
				) : (
					''
				)}

				<a
					className={c('__close-btn')}
					style={{ backgroundImage: `url(${lottery_redpack_close_btn})` }}
					onClick={
						isFromOrderBtn
							? (ev) => {
									onClose();
									isFromBrandPrice ? onClickBookBtn(brandPrice, ev) : onClickBookBtn(price, ev);
									ev.stopPropagation();
							  }
							: onClose
					}
				></a>
			</div>
		</Dialog>
	);
};

export default LotteryDialog;
