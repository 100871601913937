import React from 'react';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import { fetchLPDatas } from '../../../../actions/list/calendarLowprice';
import { searchNoResultRecommendSelector } from '../../result/recommend/searchNoResultRecommendSelector.js';
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list';
import { getRouterKeySelector, prevCondFlightWaySelector } from '../../result/flight/baseSelector';
import { flightWaySelector, searchBoxOnlyDomesticSelector } from '../../selector/search';
import { IntersectionNotifier } from '../../../../components/Base/intersetionNotifier';
import { FakeLowPriceCalendar } from '../../../../components/list/common/fakeLowPriceCalendar';
import { getIfScopeDomestic } from '../../../../sources/list/scope';

const GRADE_MAPING = {
	Y_S: 1 | 3,
	Y: 1,
	S: 2,
	C_F: 4 | 8,
	C: 4,
	F: 8,
	Y_S_C_F: 15,
};
class CalenderLowPrice extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		asyncCalendarHeader: () => (
			<IntersectionNotifier
				onScrollIntoView={() => {
					import(/* webpackChunkName: "normalCalendarHeader" */ './NormalCalendarContainer').then(
						(component) => this.setState({ asyncCalendarHeader: component.default })
					);
				}}
				loading={<FakeLowPriceCalendar />}
			/>
		),
		asyncSingleCalendarBody: () => <div />,
		asyncRoundCalendarBody: () => <div />,
	};

	onFetchLPDatas = () => {
		//初始化各种数据
		let searchCriteria = this.props.searchCriteria, //搜索条件
			//国际只有成人需要显示
			correctPassager = getIfScopeDomestic()
				? true
				: searchCriteria.get('adultCount') > 0 &&
				  searchCriteria.get('childCount') === 0 &&
				  searchCriteria.get('infantCount') === 0;

		let isShow = correctPassager;

		//请求低价接口，获取低价数据
		if ((searchCriteria.get('flightWay') === 'S' || searchCriteria.get('flightWay') === 'D') && isShow) {
			let params = {};
			const isSingle = searchCriteria.get('flightWay') === 'S';
			if (!isSingle) {
				params.returnDate = [...searchCriteria.get('flightSegments')]?.[1]?.get('departureDate');
			}
			params.departNewCityCode = [...searchCriteria.get('flightSegments')]?.[0]?.get('departureCityCode');
			params.arriveNewCityCode = [...searchCriteria.get('flightSegments')]?.[0]?.get('arrivalCityCode');
			params.startDate = [...searchCriteria.get('flightSegments')]?.[0]?.get('departureDate');
			params.grade = GRADE_MAPING[searchCriteria.get('cabin')];
			if (!this.props.domestic) {
				params.flag = isSingle ? 1 | 0 : 1 | 4;
				params.channelName = 'FlightIntlOnline';
				params.searchType = 2;
			} else {
				params.flag = isSingle ? 0 : 4;
				params.channelName = 'FlightOnline';
				params.searchType = 1;
			}
			params.passengerList = [
				searchCriteria.get('adultCount') > 0 && {
					passengercount: searchCriteria.get('adultCount'),
					passengertype: 'Adult',
				},
				searchCriteria.get('childCount') > 0 && {
					passengercount: searchCriteria.get('childCount'),
					passengertype: 'Child',
				},
				searchCriteria.get('infantCount') > 0 && {
					passengercount: searchCriteria.get('infantCount'),
					passengertype: 'Baby',
				},
			].filter(Boolean);
			params.calendarSelections = [
				{
					selectionType: 8,
					selectionContent: [`${GRADE_MAPING[searchCriteria.get('cabin')]}`],
				},
			];
			this.props.fetchLPDatas(params, searchCriteria);
		}
	};

	componentDidMount() {
		setTimeout(() => {
			this.onFetchLPDatas();
			const loadSingleCalendarBody = () => {
				import(/* webpackChunkName: "singleCalendarBody" */ './SingleCalendarContainer').then((component) =>
					this.setState({ asyncSingleCalendarBody: component.default })
				);
			};

			const loadRoundCalendarBody = () => {
				import(/* webpackChunkName: "roundCalendarBody" */ './RoundCalendarContainer').then((component) =>
					this.setState({ asyncRoundCalendarBody: component.default })
				);
			};

			const flightWay = this.props.searchCriteria.get('flightWay');
			if (flightWay === 'S' || flightWay === 'D') {
				loadSingleCalendarBody();
				loadRoundCalendarBody();
			}
		}, 2000);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.routerKey !== this.props.routerKey) {
			this.onFetchLPDatas();
		}
	}

	render() {
		let {
				showCalendarBody,
				searchCriteria,
				isSearchNoResult,
				showMonth,
				isTrend,
				prevCondFlightWay,
				searchBoxFlightWay,
				splitRound,
			} = this.props,
			head_show = !isSearchNoResult && prevCondFlightWay != 'M' && searchBoxFlightWay !== 'MT' ? 1 : 0,
			month_type =
				head_show && showCalendarBody && (searchCriteria.get('flightWay') === 'S' || showMonth) ? 1 : 0,
			week_type = head_show && showCalendarBody && !month_type ? 1 : 0,
			is_calender_show = head_show && showCalendarBody && !isTrend ? 1 : 0,
			is_trend_show = head_show && showCalendarBody && isTrend ? 1 : 0;

		return (
			<UbtBoundary
				tracker={ListUbtGenerator(
					LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW,
					LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR,
					head_show,
					month_type,
					week_type,
					is_calender_show,
					is_trend_show
				)}
			>
				{head_show == 1 ? (
					<div>
						<CSSTransitionGroup
							transitionName="calendar"
							transitionEnterTimeout={250}
							transitionLeaveTimeout={250}
						>
							{showCalendarBody ? (
								searchCriteria.get('flightWay') === 'S' ? (
									<this.state.asyncSingleCalendarBody />
								) : (
									<this.state.asyncRoundCalendarBody />
								)
							) : null}
						</CSSTransitionGroup>
						{!showCalendarBody ? <this.state.asyncCalendarHeader splitRound={splitRound} /> : null}
					</div>
				) : null}
			</UbtBoundary>
		);
	}
}

const mapStateToProps = (state) => ({
	showCalendarBody: state.getIn(['list', 'calendar_lowprice', 'showCalendarBody']),
	searchCriteria: state.getIn(['list', 'search', 'prevCond']),
	domestic: searchBoxOnlyDomesticSelector(state),
	isSearchNoResult: searchNoResultRecommendSelector(state),
	showMonth: state.getIn(['list', 'calendar_lowprice', 'showMonth']),
	isTrend: state.getIn(['list', 'calendar_lowprice', 'isTrend']),
	routerKey: getRouterKeySelector(state),
	prevCondFlightWay: prevCondFlightWaySelector(state),
	searchBoxFlightWay: flightWaySelector(state),
});

const mapDispatchToProps = (dispatch) => ({
	fetchLPDatas: (params, searchCriteria) => {
		dispatch(fetchLPDatas(params, searchCriteria));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(CalenderLowPrice);
