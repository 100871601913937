import React, { useState } from 'react';
import Immutable from 'immutable';
import classnames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';

import ErrorBoundary from '@/src/components/Base/ErrorBoundary';

import FlightContainer from '../../../../containers/list/result/flight/flightContainer';
import { MID_RECOMMEND_POSITION, RECOMMEND_LOWPRICE_POSITION } from '../../../../constants/list/enums/recommend';

import {
	getHighCabinRecGroupTip,
	getHighCabinRecMainTip,
	getHighCabinShowHideExtraFlightsTip,
} from '../../../../sources/list/highCabinRecTip';

import OwRecommendRtConfig from '../../../../constants/list/owRecommendRtConfig';
import { LIST_TIP_MSG } from '../../../../constants/list/msgs/listTipConfigMsg';

import { SmartScroll } from '../../common/smartScroll';
import { getIfScopeDomestic } from '../../../../sources/list/scope';
import FlightsGroupTitle from './flightsGroupTitle';

import MidRecommendContainer from '../../../../containers/list/result/recommend/midRecommendContainerAsync';
import OwRecommendRtContainer from '../../../../containers/list/result/recommend/owRecommendRtContainerAsync';
import AdBar from '../recommend/AdBarAsync';

const Flights = ({
	onBook,
	isBuildUpMode,
	allVisibleFlights,
	actions,
	sortContainsTax,
	currentSegmentSeq,
	recommendVersion,
	onPopupFlightDetail,
	highCabinRecommendMainTip,
	splitRoundFlightsSwitch,
	highCabinRecommendGroupTipList,
	highCabinHideOrShowFlightsHandlerIndex,
	highCabinExpandExtraRecFlights,
	splitRound,
	onToggleHighCabinExpandExtraRecFlights,
	isShowOwRecommendRt,
	filterV2,
	searchIsFinish,
	selectedFlightFixStatus,
	selectFlightItineryId,
	fixSortBar,
	flightsMinHeight,
	updateTooFewFlights,
	ubtOriginFlight,
	roundTabModeSwitch,
	activeRoundTabIndex,
	prevCond,
	priceSortTypeGroupTitlesRef,
	adBarSwitch,
}) => {
	const roundTabModeSelectedFlightIndex =
		roundTabModeSwitch && activeRoundTabIndex === 0 && selectFlightItineryId && allVisibleFlights
			? allVisibleFlights.findIndex((flight) => selectFlightItineryId === flight.get('itineraryId'))
			: -1;
	// 此处考虑到往返tab模式下，已选去程再切到去程时，需要置顶已选去程
	const selectRoundTabFlight = roundTabModeSelectedFlightIndex >= 0;
	let visibleFlights = allVisibleFlights && selectRoundTabFlight ? allVisibleFlights.slice() : allVisibleFlights;

	if (selectRoundTabFlight) {
		const selectedFlightAsList = Immutable.List([allVisibleFlights.get(roundTabModeSelectedFlightIndex)]);
		visibleFlights = selectedFlightAsList.concat(
			allVisibleFlights.filter((_item, index) => index !== roundTabModeSelectedFlightIndex)
		);
	}
	const visibleFlightsSize = visibleFlights.valueSeq().size;
	// 单程推荐往返位置在第二个行卡后面，如果结果只有小于3个 则显示在最后一条
	const owRecommendRtPosition =
			visibleFlightsSize < OwRecommendRtConfig.ow_recommend_rt_index
				? visibleFlightsSize - 1
				: OwRecommendRtConfig.ow_recommend_rt_index - 1,
		showHignCabinRecMainTipIndex = visibleFlights.findIndex((flight, index) => {
			return (
				(index === 0 || !visibleFlights.getIn([index - 1, 'containsRecommendCabin'])) &&
				flight.get('containsRecommendCabin')
			);
		}),
		flightsStyle = flightsMinHeight ? { minHeight: flightsMinHeight } : {};
	const [expandFlights, setExpandFlights] = useState([]),
		setExpandFlightItem = ({ itineraryId, expand }) => {
			if (expand) {
				setExpandFlights([...expandFlights, itineraryId]);
			} else {
				setExpandFlights(expandFlights.filter((expandItineraryId) => expandItineraryId != itineraryId));
			}
		};

	const flightSegmentTypeGroupIndex = visibleFlights.findIndex((flight, i) => {
		if (getIfScopeDomestic()) {
			const prevSegmentGroupTypeKey =
				i > 0 ? visibleFlights.get(i - 1).getIn(['theMinPriceItem', 'sortTypeGroupEnum']).key : '';
			const nextSegmentGroupTypeKey = flight.getIn(['theMinPriceItem', 'sortTypeGroupEnum']).key;

			if (
				(prevSegmentGroupTypeKey === 'TRANSFER' || nextSegmentGroupTypeKey === 'TRANSFER') &&
				prevSegmentGroupTypeKey != nextSegmentGroupTypeKey &&
				// 已选置顶不显示分类标题
				!(selectRoundTabFlight && i == 0)
			) {
				return true;
			}
		}
		return false;
	});

	const renderFlight = (flight, i) => {
		let itineraryId = flight.get('itineraryId'),
			isSelected = itineraryId === selectFlightItineryId,
			highCabinRecommendGroupTip = highCabinRecommendGroupTipList.find((tip) => tip.index === i),
			prevHighCabinRecommendGroupTip = highCabinRecommendGroupTipList.find((tip) => tip.index < i),
			showFlightSegmentTypeGoupName = false;
		if (getIfScopeDomestic()) {
			const prevSegmentGroupTypeKey =
				i > 0 ? visibleFlights.get(i - 1).getIn(['theMinPriceItem', 'sortTypeGroupEnum']).key : '';
			const nextSegmentGroupTypeKey = flight.getIn(['theMinPriceItem', 'sortTypeGroupEnum']).key;
			if (
				prevSegmentGroupTypeKey != nextSegmentGroupTypeKey &&
				// 已选置顶不显示分类标题
				!(selectRoundTabFlight && i == 0)
			) {
				showFlightSegmentTypeGoupName = true;
			}
		}

		// 取显示优先级最高的
		return (
			<ErrorBoundary key={`flight_${itineraryId}`}>
				{isBuildUpMode && flight.get('flightSegments').size < 2
					? null
					: [
							highCabinHideOrShowFlightsHandlerIndex === i &&
								getHighCabinShowHideExtraFlightsTip(
									`extra_${itineraryId}`,
									prevHighCabinRecommendGroupTip
										? prevHighCabinRecommendGroupTip.directFlight
										: false,
									highCabinExpandExtraRecFlights,
									() => onToggleHighCabinExpandExtraRecFlights()
								),
							// 搜索高舱，前面一个是非推荐航班、后面一个是推荐航班，或第一个就是非推荐航班，就显示提示
							highCabinRecommendMainTip && showHignCabinRecMainTipIndex === i
								? getHighCabinRecMainTip(`hcmt_${itineraryId}`, highCabinRecommendMainTip)
								: null,
							highCabinRecommendGroupTip
								? getHighCabinRecGroupTip(
										highCabinRecommendGroupTip.iconClassName,
										highCabinRecommendGroupTip.content
								  )
								: null,
							showFlightSegmentTypeGoupName ? (
								<FlightsGroupTitle
									key={`fgt_${itineraryId}`}
									theSegmentTypeGroupEnum={flight.getIn(['theMinPriceItem', 'sortTypeGroupEnum'])}
									ref={
										priceSortTypeGroupTitlesRef[
											flight.getIn(['theMinPriceItem', 'sortTypeGroupEnum']).key
										]
									}
								/>
							) : null,
							<FlightContainer
								onPopupFlightDetail={onPopupFlightDetail}
								key={`f_${itineraryId}`}
								onBook={onBook}
								flight={flight}
								actions={actions}
								index={i}
								currentSegmentSeq={currentSegmentSeq}
								splitRound={splitRound}
								sortContainsTax={sortContainsTax}
								isSelected={isSelected}
								selectedFlightFixStatus={isSelected ? selectedFlightFixStatus : null}
								fixSortBar={isSelected ? fixSortBar : null}
								updateTooFewFlights={updateTooFewFlights}
								searchIsFinish={searchIsFinish}
								isExpand={expandFlights.indexOf(itineraryId) >= 0}
								setExpandFlightItem={setExpandFlightItem}
							/>,
							!getIfScopeDomestic() && owRecommendRtPosition == i && isShowOwRecommendRt ? (
								<OwRecommendRtContainer
									ubtOriginFlight={ubtOriginFlight}
									key={`owrrtc_${itineraryId}`}
								/>
							) : null,
							<MidRecommendContainer
								ubtOriginFlight={ubtOriginFlight}
								onPopupFlightDetail={onPopupFlightDetail}
								key={`rec_${itineraryId}`}
								position={RECOMMEND_LOWPRICE_POSITION.MIDDLE}
								count={i + 1}
								onBook={onBook}
								splitRound={splitRound}
								filterV2={filterV2}
								selectedFlightFixStatus={selectedFlightFixStatus}
								selectFlightItineryId={selectFlightItineryId}
								fixSortBar={fixSortBar}
							/>,
							adBarSwitch && (
								<AdBar
									adConfigKey="MIDDLE"
									classNames="middle"
									visibleFlightsSize={visibleFlightsSize}
									position={i}
									prevCond={prevCond}
									key={`${i}_ad`}
								/>
							),
					  ]}
			</ErrorBoundary>
		);
	};
	// getItineraryId = useCallback(flight => flight.get('itineraryId'), [])

	// 列表滚动加载。去掉renderAllFlights条件
	return (
		<div
			className={classnames({ 'flight-list root-flights': true, 'select-flight': selectFlightItineryId })}
			style={flightsStyle}
		>
			<CSSTransitionGroup
				transitionName="flights-slide-down"
				transitionAppear={true}
				transitionLeave={false}
				transitionEnterTimeout={200}
				transitionAppearTimeout={200}
			>
				<SmartScroll
					dataSource={visibleFlights}
					initRenderSize={6}
					renderItem={renderFlight}
					flightSegmentTypeGroupIndex={flightSegmentTypeGroupIndex}
				/>
			</CSSTransitionGroup>
			{MID_RECOMMEND_POSITION.getVersion(recommendVersion).enums.map((item, index) => {
				if (item.value.count > visibleFlights.valueSeq().size) {
					return (
						<MidRecommendContainer
							ubtOriginFlight={ubtOriginFlight}
							filterV2={filterV2}
							splitRound={splitRound}
							onPopupFlightDetail={onPopupFlightDetail}
							key={index}
							position={RECOMMEND_LOWPRICE_POSITION.MIDDLE}
							count={item.value.count}
							onBook={onBook}
							selectFlightItineryId={selectFlightItineryId}
						/>
					);
				}
			})}
			{searchIsFinish && splitRoundFlightsSwitch ? (
				<div className="no-more-flights">{LIST_TIP_MSG.SPLIT_FLIGHTS.NO_MORE_FLIGHTS}</div>
			) : null}
		</div>
	);
};

export default Flights;
