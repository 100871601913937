import { createSelector } from 'reselect'
import moment from 'moment'
import { prevCondSelector } from '../flight/baseSelector'
import { getCalendarLowPriceData } from '../../top/calendarLowprice/calendarLPSelector'

export const nearByDateRecommendDataSelector = createSelector([prevCondSelector, getCalendarLowPriceData],
    (prevCond, calendarLPData) => {
        const  flightSegments = prevCond.get('flightSegments'),
        segment = flightSegments.getIn([0]),
        dDate = segment.getIn(['departureDate']),
        calendarLPD = calendarLPData,
        currentDate = moment(new Date()).format("YYYY-MM-DD"),
        days = moment(dDate).diff(currentDate, "days"),//与当前日期相差几天
        newCalendarLPData = [],
        forwardDate = moment(dDate).add(-7, "days").format("YYYY-MM-DD"),//搜索日期往前7天
        backwardDate = moment(dDate).add(31, "days").format("YYYY-MM-DD"),//往后31天内
        countDay = 7

        if (days > countDay) { //用户搜索的日期-当前日期>7天
            calendarLPD.map((item) => {
            if (((moment(forwardDate).isBefore(item.get('date')) && moment(item.get('date')).isBefore(backwardDate)) || moment(forwardDate).isSame(item.get('date'))) && newCalendarLPData.length < countDay) {
                newCalendarLPData.push(item)
            }
        })
    } else {
            calendarLPD.map((item) => {
                if (((moment(item.get('date')).isAfter(currentDate) || moment(currentDate).isSame(item.get('date'))) && moment(item.get('date')).isBefore(backwardDate)) && newCalendarLPData.length < countDay) {
                    newCalendarLPData.push(item)
                }
            })
        }

        return newCalendarLPData
    }
)
