import React from 'react';

import ReactToolTip from '@/src/components/Base/reactToolTip';
import store from '@/src/stores/list';
import Converter from '@/src/sources/list/converter';
import { VIRTUAL_FLIGHT_TEXT } from '@/src/constants/list/enums/virtualFlightInfo';
import AsyncPopupFlightComfortContainer from '@/src/containers/list/result/PopupFlightComfortContainer/async';
import { getIfScopeDomestic } from '@/src/sources/list/scope';
import Tooltip from '../../common/tooltip';

const scopDomestic = getIfScopeDomestic();

const getCrossdayContent = ({ arrivalDateTime, crossDays }) => {
	const date = Converter.removeSecond(arrivalDateTime);
	return scopDomestic ? `到达时间为第${crossDays + 1}天, ${date}` : `抵达时间为当地时间：${date}`;
};

const TooltipList = ({
	cleanItineraryId,
	changeComfortPopupIndex,
	segment,
	priceList,
	isBuildUpMode,
	rowIndex,
	currentSegmentSeq,
	comfortPopupIndex,
	isTFUDepartureAirline,
	isTFUArrivalAirline,
	newAirportTFUSwitch,
	departureAirportEnName,
	arrivalAirportEnName,
	isVirtualFlight,
	trafficType,
	travelRemark,
	departureAirportName,
	arrivalAirportName,
}) => {
	return (
		<React.Fragment>
			<ReactToolTip
				group="flightComfort"
				key={`comfort-${cleanItineraryId}`}
				parentId={`comfort-${cleanItineraryId}`}
				toolTipContent={
					<AsyncPopupFlightComfortContainer
						store={store}
						changeComfortPopupIndex={changeComfortPopupIndex}
						key={`comfort${cleanItineraryId}`}
						segment={segment}
						priceList={priceList}
						currentSegmentSeq={isBuildUpMode ? rowIndex : currentSegmentSeq}
						comfortPopupIndex={comfortPopupIndex}
						cacheKey={`comfort${cleanItineraryId}`}
					/>
				}
			/>
			<ReactToolTip
				key={`departureTerminal-${cleanItineraryId}`}
				parentId={`departureTerminal${cleanItineraryId}`}
				toolTipContent={
					<Tooltip
						content={
							segment.get('departureTerminal') == 'I'
								? '国际航站楼'
								: `${segment.get('departureTerminal')}号航站楼`
						}
					/>
				}
			/>
			<ReactToolTip
				key={`arrivalTerminal-${cleanItineraryId}`}
				parentId={`arrivalTerminal${cleanItineraryId}`}
				toolTipContent={
					<Tooltip
						content={
							segment.get('arrivalTerminal') == 'I'
								? '国际航站楼'
								: `${segment.get('arrivalTerminal')}号航站楼`
						}
					/>
				}
			/>
			<ReactToolTip
				key={`airlineName-${cleanItineraryId}`}
				parentId={`airlineName${cleanItineraryId}`}
				toolTipContent={
					<Tooltip
						content={`${segment.get('airlineName')} ${
							scopDomestic ? '' : '公司官方产品，提供官网特惠价格'
						}`}
					/>
				}
			/>
			<ReactToolTip
				key={`durationDayHourMin-${cleanItineraryId}`}
				parentId={`durationDayHourMin${cleanItineraryId}`}
				toolTipContent={<Tooltip content={`行程总时长:${segment.get('durationDayHourMin')}`} />}
			/>
			<ReactToolTip
				key={`crossDays-${cleanItineraryId}`}
				parentId={`crossDays${cleanItineraryId}`}
				toolTipContent={
					<Tooltip
						content={getCrossdayContent({
							arrivalDateTime: segment.get('arrivalDateTime'),
							crossDays: segment.get('crossDays'),
						})}
					/>
				}
			/>
			<ReactToolTip
				key={`departureFlightTrain${cleanItineraryId}`}
				parentId={`departureFlightTrain${cleanItineraryId}`}
				toolTipContent={
					isTFUDepartureAirline && newAirportTFUSwitch ? (
						<Tooltip
							content={`<h3 class="new-airport-tit">成都天府国际机场交通信息</h3><p class="new-airport-con">该航班起飞机场为天府国际机场。距市区约50KM，从天府国际机场搭乘地铁18号线到火车南站最快需约35分钟。</p>`}
						/>
					) : (
						<Tooltip
							content={
								departureAirportEnName && isVirtualFlight
									? departureAirportEnName
									: departureAirportName
							}
						/>
					)
				}
			/>
			<ReactToolTip
				key={`arrivalFlightTrain${cleanItineraryId}`}
				parentId={`arrivalFlightTrain${cleanItineraryId}`}
				toolTipContent={
					isTFUArrivalAirline && newAirportTFUSwitch ? (
						<Tooltip
							content={`<h3 class="new-airport-tit">成都天府国际机场交通信息</h3><p class="new-airport-con">该航班到达机场为天府国际机场。距市区约50KM，从天府国际机场搭乘地铁18号线到火车南站最快需约35分钟。</p>`}
						/>
					) : (
						<Tooltip
							content={
								arrivalAirportEnName && isVirtualFlight ? arrivalAirportEnName : arrivalAirportName
							}
						/>
					)
				}
			/>
			<ReactToolTip
				key={`departureFlightIcon${cleanItineraryId}`}
				parentId={`departureFlightIcon${cleanItineraryId}`}
				toolTipContent={
					<Tooltip
						content={`<h3 class="new-airport-tit">北京大兴机场交通信息</h3><p class="new-airport-con">距市中心约 46 公里，从大兴机场搭乘地铁到市区(草桥站)需约 30 分钟</p>`}
					/>
				}
			/>
			<ReactToolTip
				key={`arrivalFlightIcon${cleanItineraryId}`}
				parentId={`arrivalFlightIcon${cleanItineraryId}`}
				toolTipContent={
					<Tooltip
						content={`<h3 class="new-airport-tit">北京大兴机场交通信息</h3><p class="new-airport-con">距市中心约 46 公里，从大兴机场搭乘地铁到市区(草桥站)需约 30 分钟</p>`}
					/>
				}
			/>
			{trafficType && travelRemark && (
				<ReactToolTip
					key={`virtualflight${cleanItineraryId}`}
					parentId={`virtualflight${cleanItineraryId}`}
					toolTipContent={
						<Tooltip
							content={`<h3 class="new-airport-tit">乘${VIRTUAL_FLIGHT_TEXT[trafficType]?.value}提醒</h3><p class="new-airport-con">${travelRemark}</p>`}
						/>
					}
				/>
			)}
		</React.Fragment>
	);
};

export default TooltipList;
