import React from 'react';
import classnames from 'classnames';
import { Map } from 'immutable';
import ReactToolTip from '@/src/components/Base/reactToolTip';
import { FILTER_CLASS_GRADE_TYPE } from '@/src/constants/common/enum/filter';
import styles from './rightsInfo.module.scss';

type CitySpecificRightsType = Map<string, any>;

type RightsInfoType = Map<string, any>;

export const ToolTipContentComp = ({ citySpecificRights }: { citySpecificRights: CitySpecificRightsType }) => {
	if (!citySpecificRights) return null;

	return (
		<>
			<div className={styles.container}>
				<div className={styles.title}>舱位权益说明</div>
				{(citySpecificRights || []).map((item, index) => {
					const arrivalCityName = item.get('arrivalCityName');
					const departureCityName = item.get('departureCityName');
					const cabinClass = item.get('cabinClass');
					const cityBenefits = (item.get('cityBenefits') || []).map((item) => item.get('Tag')).join('、');
					const cityRestrictions = (item.get('cityRestrictions') || [])
						.map((item) => item.get('Tag'))
						.join('、');

					const cabinName = FILTER_CLASS_GRADE_TYPE[cabinClass]?.value?.title;
					return (
						<div className={styles.cityContainer} key={index}>
							<div className={styles.cityBox}>
								<span className={styles.cityTitle}>
									{departureCityName}
								</span>
								<img
									src="https://pages.ctrip.com/flight_h5/tinyapp/online-list/iconArrow.png"
									className={styles.imgStyle}
								></img>
								<span className={classnames(styles.cityTitle, styles.marginRight)}>{arrivalCityName}</span>
								<span className={styles.commonContent}>{cabinName}</span>
							</div>
							{cityBenefits && (
								<div className={classnames(styles.commonContent, styles.margin)}>
									<span>{cabinName}提供以下权益：</span>
									<span className={styles.greenColor}>{cityBenefits}</span>
								</div>
							)}
							{cityRestrictions && (
								<div className={styles.commonContent}>
									<span>{cabinName}不提供以下权益：</span>
									<span className={styles.yellowColor}>{cityRestrictions}</span>
								</div>
							)}
						</div>
					);
				})}
				<div className={styles.footStyle}>*该舱位权益由航司提供，详情请咨询航司</div>
			</div>
		</>
	);
};

export const RightsInfo = ({
	rightsInfo,
	getId,
}: {
	rightsInfo: RightsInfoType;
	getId: (item: string, index: number) => string;
}) => {
	if (!rightsInfo) return null;

	const commonBenefits = rightsInfo.get('commonBenefits') || [];
	const citySpecificRights = rightsInfo.get('citySpecificRights');
	return (
		<div className={styles.rightsInfo}>
			{commonBenefits.map((item, index) => {
				const key = getId(item, index).replace(/\s/g, '、');
				return (
					<React.Fragment key={index}>
						<span className={classnames('tag', styles.wrapFont)} id={key}>
							{item}
						</span>
						<ReactToolTip
							parentId={key}
							key={key}
							toolTipContent={
								<ToolTipContentComp citySpecificRights={citySpecificRights}></ToolTipContentComp>
							}
						></ReactToolTip>
					</React.Fragment>
				);
			})}
		</div>
	);
};
